import { Button, Form, Modal, Nav, ProgressBar } from 'react-bootstrap';

import React, { useState } from 'react';

import bigImg from '../../assets/images/car.png';
import car1 from '../../assets/images/car1.png';
import car2 from '../../assets/images/car2.png';
import car3 from '../../assets/images/car3.png';
import car5 from '../../assets/images/car5.png';
import car6 from '../../assets/images/car6.png';
import fit_model_img1 from '../../assets/images/fit_model_img1.png';
import { Link } from 'react-router-dom';
import { ArrowForwardIos, Refresh } from '@mui/icons-material';

const NeedsBasedAssesmentModel = ({ showModal, handleCloseModal }) => {

	const [isNextStep, setIsNextStep] = useState({
		id: 'step1',
	})
	const continueHandleClick = (e) => {
		setIsNextStep({
			id: e,
		})
	}
	return (
		<div>
			<Modal show={showModal} onHide={handleCloseModal} size="lg" className='nba_modelbox'>

				<Modal.Body className='p-0'>
					{/* step popup 1 */}

					{
						isNextStep.id === 'step1' ?
							<div className='pfi_step pfi_step1'>
								<div className="perfectpopup">
									<div className="perfpopimg">
										<img src={bigImg} alt='#' className="img-responsive"></img>
									</div>
									<div className="perfpopright adjustment">
										<div className="fpi_mainheading">
											<h2>Find Your Perfect BMW</h2>
											<p>Answer a few questions about your lifestyle to find the BMW(s) that best fit your
												wants and needs</p>
										</div>

										<div className="subheading">
											<h3>
												What type of vehicle are you interested in?
											</h3>
										</div>
										<div className="fpi_list_items">
											<ul>
												<li className='active'><Link><img src={car1} alt="#"></img>Gran Coup </Link></li>
												<li><Link><img src={car2} alt="#" />Sports Activity Coupe</Link></li>
												<li><Link><img src={car3} alt="#" />Roadster</Link></li>
												<li><Link><img src={car2} alt="#" />Sports Activity Vehicle</Link></li>
												<li><Link><img src={car5} alt="#" />Sedan</Link></li>
												<li><Link><img src={car6} alt="#" />Coupe</Link></li>
												<li><Link><img src={car1} alt="#" />Convertible</Link></li>
												<li><Link><img src={car5} alt="#" />Gran Coup</Link></li>
											</ul>
										</div>
										<div className="continue-btn pt-3">
											<Button onClick={(e) => { continueHandleClick("step2") }} variant="primary">Continue <ArrowForwardIos /></Button>
										</div>
									</div>
								</div>
							</div>
							: ''
					}

					{/* step 2 section condition */}
					{
						isNextStep.id === 'step2' ?
							<div className='pfi_step pfi_step2'>
								<div className="perfectpopup">
									<div className="perfpopimg">
										<img src={bigImg} alt='#' className="img-responsive"></img>
									</div>
									<div className="perfpopright">
										<div className="fpi_mainheading">
											<h2>Find Your Perfect BMW</h2>
											<p>Answer a few questions about your lifestyle to find the BMW's that best fit your
												wants and needs</p>
										</div>

										<div className='fpi_quiz_sec'>
											<div className="subheading">
												<h3>
													How do you envision using your new car?
												</h3>
											</div>
											<div className="fpi_list_items">
												<ul>
													<li>
														<label className="md_radio">
															<input name="form1" type="radio" />
															<span className="md_radio__button"></span>
															Mostly for commuting to work and running errands
														</label>
													</li>
													<li>
														<label className="md_radio">
															<input name="form1" type="radio" />
															<span className="md_radio__button"></span>
															Taking road trips and going offroad

														</label>
													</li>
													<li><label className="md_radio">
														<input name="form1" type="radio" />
														<span className="md_radio__button"></span>
														Going on long drives on the highway
													</label>
													</li>
													<li>
														<label className="md_radio">
															<input name="form1" type="radio" />
															<span className="md_radio__button"></span>
															Cruising around the city and attending events
														</label>
													</li>
												</ul>
											</div>
											<div className="continue-btn pt-3">
												<Button onClick={(e) => { continueHandleClick("step3") }} variant="primary">Continue <ArrowForwardIos /></Button>
											</div>
											<ProgressBar now={0} />
										</div>
									</div>
								</div>
							</div>
							: ''
					}

					{/* step 3 section condition */}
					{
						isNextStep.id === 'step3' ?
							<div className='pfi_step pfi_step2'>
								<div className="perfectpopup">
									<div className="perfpopimg">
										<img src={bigImg} alt='#' className="img-responsive"></img>
									</div>
									<div className="perfpopright">
										<div className="fpi_mainheading">
											<h2>Find Your Perfect BMW</h2>
											<p>Answer a few questions about your lifestyle to find the BMW's that best fit your
												wants and needs</p>
										</div>

										<div className='fpi_quiz_sec'>
											<div className="subheading">
												<h3>
													What is your budget for your vehicle?
												</h3>
											</div>
											<div className="pfi_veh_budget">
												<Nav justify variant="tabs" defaultActiveKey="Finance" className='pfi_filled_tabs'>
													<Nav.Item>
														<Nav.Link eventKey="Finance">Finance</Nav.Link>
													</Nav.Item>
													<Nav.Item>
														<Nav.Link eventKey="Lease">Lease</Nav.Link>
													</Nav.Item>
													<Nav.Item>
														<Nav.Link eventKey="Cash"> Cash </Nav.Link>
													</Nav.Item>
												</Nav>

												<div className="tabsinside">
													<div className="left-area">
														<span className="monthly-price">
															Monthly Price:
														</span>
														<small className="down-payment">
															(Based on 10% Down)
														</small>
													</div>
													<div className="right-area">
														<span className="price">
															$1000
														</span>
														<sub>
															/ Month
														</sub>
													</div>
												</div>

												<Form.Range />

											</div>
											<div className="continue-btn pt-3">
												<Button onClick={(e) => { continueHandleClick("step4") }} variant="primary">Continue <ArrowForwardIos /></Button>
											</div>
											<ProgressBar now={60} />
										</div>
									</div>
								</div>
							</div>
							: ''
					}

					{/* step 4 section condition */}
					{
						isNextStep.id === 'step4' ?
							<div className='pfi_step pfi_step2'>
								<div className="perfectpopup">
									<div className="perfpopimg">
										<img src={bigImg} alt='#' className="img-responsive"></img>
									</div>
									<div className="perfpopright">
										<div className="fpi_mainheading">
											<h2>Find Your Perfect BMW</h2>
											<p>Answer a few questions about your lifestyle to find the BMW's that best fit your
												wants and needs</p>
										</div>
										<div className='fpi_quiz_sec'>
											<div className="subheading">
												<h3> Where will you be garaging your BMW? </h3>
											</div>
											<div className="pfi_cityState_box pb-0 mb-2">

												<div className='row'>
													<div className='col-6 pb-3'>
														<span className="acc_title bold">City</span>
														<Form.Control type="text" placeholder="" />
													</div>
													<div className='col-6 pb-3'>
														<span className="acc_title bold">State</span>
														<Form.Control type="text" placeholder="" />
													</div>
												</div>


											</div>

											<div className="continue-btn pt-3">
												<Button onClick={(e) => { continueHandleClick("step5") }} variant="primary">See Results <ArrowForwardIos /></Button>
											</div>
											<ProgressBar now={100} />
										</div>
									</div>
								</div>
							</div>
							: ''
					}
					{/* step 5 section condition See results */}
					{
						isNextStep.id === 'step5' ?
							<div className='pfi_step pfi_step5'>
								<div className="pfi_step5_popup">
									<div className="fpi_mainheading mb-3 p-0 text-center">
										<h2 className='position-relative'><Link onClick={(e) => { continueHandleClick("step2") }} className='try_again_icon_btn d-flex align-items-center'><Refresh className='me-1' /> Try Again</Link>Your Best Fit Models</h2>
										<p>These models best fit your wants and needs based on your answers. To see the <br />rationale please view the full report which analyzes and describes your needs and our recconmendations</p>
									</div>
									<div className='pfi_step5_sec'>
										<div className='row mb-4'>
											<div className='col-md-4'>
												<div className='pfi_step5_box'>
													<figure>
														<img src={fit_model_img1} alt="#" />
													</figure>
													<div className='pfi_step5_box_text'>
														<h3>M240i xDrive Coupe</h3>
														<small>77.6% Match</small>
														<span className='headline-6'>Starting at: $48,900</span>
														<p>Reasons: M Sport, Horsepower, Size, xDrive, Style</p>
													</div>
													<div className='pfi_step5_box_btn d-flex'>
														<Button variant="primary" className='me-1'>Learn More</Button>
														<Button variant="secondary" className='ms-1'>View Inventory</Button>
													</div>
												</div>
											</div>
											<div className='col-md-4'>
												<div className='pfi_step5_box'>
													<figure>
														<img src={fit_model_img1} alt="#" />
													</figure>
													<div className='pfi_step5_box_text'>
														<h3>M240i xDrive Coupe</h3>
														<small>77.6% Match</small>
														<span className='headline-6'>Starting at: $48,900</span>
														<p>Reasons: M Sport, Horsepower, Size, xDrive, Style</p>
													</div>
													<div className='pfi_step5_box_btn d-flex'>
														<Button variant="primary" className='me-1'>Learn More</Button>
														<Button variant="secondary" className='ms-1'>View Inventory</Button>
													</div>
												</div>
											</div>
											<div className='col-md-4'>
												<div className='pfi_step5_box'>
													<figure>
														<img src={fit_model_img1} alt="#" />
													</figure>
													<div className='pfi_step5_box_text'>
														<h3>M240i xDrive Coupe</h3>
														<small>77.6% Match</small>
														<span className='headline-6'>Starting at: $48,900</span>
														<p>Reasons: M Sport, Horsepower, Size, xDrive, Style</p>
													</div>
													<div className='pfi_step5_box_btn d-flex'>
														<Button variant="primary" className='me-1'>Learn More</Button>
														<Button variant="secondary" className='ms-1'>View Inventory</Button>
													</div>
												</div>
											</div>
										</div>
										<div className="continue-btn pt-3">
											<Link onClick={handleCloseModal}>
												<Button variant="primary" className='me-4'>View Tailored Inventory <ArrowForwardIos /></Button>
											</Link>
											<Button variant="light">View Full Report</Button>
										</div>
									</div>
								</div>
							</div>
							: ''
					}
				</Modal.Body>
				{/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer> */}
			</Modal>

		</div>
	);
};

export default NeedsBasedAssesmentModel;