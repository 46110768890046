import { Button, Tab, Tabs } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { InfoOutlined, Logout, MenuBook, Videocam } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/lucid-multi-coverage.png';
import img2 from '../../assets/images/lucid-windshield .png';
import img3 from '../../assets/images/lucid-paint.png';
import img4 from '../../assets/images/lucid-key.png';

import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import PlusToggleBtn from '../../ScreensUi/DigitalCheckout/PlusToggleBtn';
import { useLocation } from 'react-router-dom';
import FarabiNew from '../../Components/Farabi/indexNew'
import multiCoverage from '../../assets/images/multi-coverage.jpg';
import pentdeinteless from '../../assets/images/paintless-dent-repair-1024x683.jpeg';
import windshield from '../../assets/images/windshield-protection-clovis-1024x683.jpg';
import keyProtection from '../../assets/images/key-protection.avif';
const WarrantyAndProtectionPlansVehicleCoverage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')
    const link = `${location.pathname}${location.search}`

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);

    // const handleStep = (e) => {
    //     setIsStep(e);
    //     console.log(isStep);
    //     if (isStep === 'step2_finalQuestion') {
    //         setIsDisabledNext(false);
    //     }
    // };



    return (
        <div>
            <div className="digital_checkout_wrapper">

                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">
                        <div className="po_header pb-3">
                            <div className="d-flex justify-content-end align-items-center">

                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>
                        <div className='iad_content po_content_height'>
                            <div className='d-flex justify-content-between'>
                                <div className='po_headlines'>
                                    <h3 className='headline-5 regular pb-1 d-flex align-items-center'>Warranty & Protection Plans</h3>
                                    <p className='label-2 regular pb-3'>View and add accessories. These can be applied to your total price or paid separately at checkout</p>
                                </div>
                            </div>


                            <div className='d-flex align-items-center justify-content-between'>
                                <h3 className='content-3 bold pb-1 uppercase'>VEHICLE COVERAGES</h3>
                                {/* <Link to={link} className='content-3 bold underline blue_color blue_hov pb-1'>See All Coverages + </Link> */}
                            </div>

                            <div className='iad_cards_list'>
                                <div className='row pb-4 pt-4'>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img iad_hov_menu'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={multiCoverage} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                                <div className='fullInfoPosList'>
                                                    <ul>
                                                        <li>
                                                            <Link to={link}><i><InfoOutlined /></i>
                                                                <span>Full Information</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to={link}><i><Videocam /></i>
                                                                <span>View All Media</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/ViewBrochure'}> <i><MenuBook /></i>
                                                                <span>View Brochure</span></Link>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Multi Coverage </h3>
                                                <h3 className='label-3--bold pb-1 d-flex justify-content-end black'>
                                                £ 212 / Month
                                                </h3>
                                                <p className='label-2 pb-2'>A selected suite of optional protection products that help preserve your vehicle’s beauty and performance.</p>
                                                {/* <p><img width='130' src={itemlogo} alt='#'></img></p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img iad_hov_menu'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={windshield} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                                <div className='fullInfoPosList'>
                                                    <ul>
                                                        <li>
                                                            <Link to={link}><i><InfoOutlined /></i>
                                                                <span>Full Information</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to={link}><i><Videocam /></i>
                                                                <span>View All Media</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/ViewBrochure'}> <i><MenuBook /></i>
                                                                <span>View Brochure</span></Link>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Windshield Protection</h3>
                                                <h3 className='label-3--bold pb-1 d-flex justify-content-end black'>
                                                £ 157 / Month
                                                </h3>
                                                <p className='label-2 pb-2'>Covers the cost of repairing your front windshield if chipped or cracked by propelled rocks and other road hazards.</p>
                                                {/* <p><img width='130' src={itemlogo} alt='#'></img></p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img iad_hov_menu'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={pentdeinteless} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                                <div className='fullInfoPosList'>
                                                    <ul>
                                                        <li>
                                                            <Link to={link}><i><InfoOutlined /></i>
                                                                <span>Full Information</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to={link}><i><Videocam /></i>
                                                                <span>View All Media</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/ViewBrochure'}> <i><MenuBook /></i>
                                                                <span>View Brochure</span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Paintless Dent Repair</h3>
                                                <h3 className='label-3--bold pb-1 d-flex justify-content-end black'>
                                                £ 118 / Month
                                                </h3>
                                                <p className='label-2 pb-2'>Any chip, dent, or alteration to the paint or body of the vehicle will be covered and repaired.</p>
                                                {/* <p><img width='130' src={itemlogo} alt='#'></img></p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img iad_hov_menu'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={keyProtection} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                                <div className='fullInfoPosList'>
                                                    <ul>
                                                        <li>
                                                            <Link to={link}><i><InfoOutlined /></i>
                                                                <span>Full Information</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to={link}><i><Videocam /></i>
                                                                <span>View All Media</span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to={'/ViewBrochure'}> <i><MenuBook /></i>
                                                                <span>View Brochure</span></Link>
                                                        </li>
                                                    </ul>

                                                </div>
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Key Protection</h3>
                                                <h3 className='label-3--bold pb-1 d-flex justify-content-end black'>
                                                £ 140 / Month
                                                </h3>
                                                <p className='label-2 pb-2'>Provides replacement coverage and lock-out assistance if your keys are ever lost, damaged, or locked inside your vehicle.</p>
                                                {/* <p><img width='130' src={itemlogo} alt='#'></img></p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FarabiNew />
                        <DigitalCheckoutFooter pageUrl={`/DeliveryAndPickup?orderId=${orderID}&customerRefId=${customerRefId}`} />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default WarrantyAndProtectionPlansVehicleCoverage;