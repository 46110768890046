import React from "react";
import carIcon from '../assets/images/car_icon.svg';
import exiticon from '../assets/images/exit.png';
import { Link } from 'react-router-dom';
import {  KeyboardArrowDownOutlined} from '@mui/icons-material';

function HeaderHomeShopingHUB() {
    return (
        <div className="product_detail_wrapper">
            <div className="mg_auto-content">
            <div className="header_inner d-flex justify-content-between align-items-center mg_hs-header">
                <h4 className="headline-5 regular mg_hs-content">MY SHOPPING HUB</h4>
                <div className="mg_headershopping-left">
                    <ul className="d-flex justify-content-end align-items-center">

                        <li className="me-4">
                            <Link className="d-flex align-items-center header_location filtersH_link">
                            <i><img src={carIcon} alt='#'></img></i>
                                <span>Trade-in</span>
                                <KeyboardArrowDownOutlined className="d-block" />
                            </Link>
                        </li>
                        <li className="me-4">
                            <Link className="d-flex align-items-center header_location filtersH_link">
                            <i><img src={carIcon} alt='#'></img></i>
                                <span>Pre-Approval</span>
                                <KeyboardArrowDownOutlined className="d-block" />
                            </Link>
                        </li>
                        <li>
                        <Link to='/' className="d-flex align-items-center header_location filtersH_link">
                            <i><img src={exiticon} alt='#'></img></i>
                        
                            </Link>
                        </li>
                        
                    </ul>
                </div>


            </div>
            </div>
          
        </div>
    )
}
export default HeaderHomeShopingHUB;