import ProductDetailsHeader from "../../Components/ProductDetailsHeader";
import Footer from "../../Components/Footer/Footer";

import DetailsGallery from "../../ScreensUi/ProductDetails/DetailsGallery/DetailsGallery";
import SummaryDetails from "../../ScreensUi/ProductDetails/SummaryDetails/SummaryDetails";
import PricingDetail from "../../ScreensUi/ProductDetails/PricingDetail/PricingDetail";

function ProductDetails() {
  return (
    <div className="product_detail_wrapper">
      <ProductDetailsHeader />
      <div className="content product_detail_grid_row">
        <div className="product_detail_grid_left">
          <DetailsGallery />
          <SummaryDetails />
        </div>
        <div className="product_detail_grid_right">
          <PricingDetail />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ProductDetails