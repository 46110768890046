import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';

import 'react-horizontal-scrolling-menu/dist/styles.css';
import epslideimg1 from '../../assets/images/epslideimg1.png';
import epslideimg2 from '../../assets/images/epslideimg2.png';
import epslideimg3 from '../../assets/images/epslideimg3.png';



function EndingPageSlider() {

  return (
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
      {/* {items.map(({ id }) => (
        <Card
          itemId={id} // NOTE: itemId is required for track items
          title={id}
          key={id}
          onClick={handleClick(id)}
          selected={isItemSelected(id)}
        />
      ))} */}
      <div className="mg_endingslider-box">
        <div className="mgeds_img me-2">
          <img src={epslideimg1} alt="#" />
        </div>
        <div className='mgeds_txt py-3'>
          <span className='fw-semibold'>Exterior</span>
          <p className='headline-6 pb-1'>SLIM REAR TAILLIGHTS</p>

        </div>
      </div>
      <div className="mg_endingslider-box">
        <div className="mgeds_img me-2">
          <img src={epslideimg2} alt="#" />
        </div>
        <div className='mgeds_txt py-3'>
          <span className='fw-semibold'>Exterior</span>
          <p className='headline-6 pb-1'>BOLD WHEEL DESIGN</p>
        </div>
      </div>
      <div className="mg_endingslider-box">
        <div className="mgeds_img me-2">
          <img src={epslideimg3} alt="#" />
        </div>
        <div className='mgeds_txt py-3'>
          <span className='fw-semibold'>Exterior</span>
          <p className='headline-6 pb-1'>AERODYNAMIC DESIGN</p>
        </div>
      </div>
      <div className="mg_endingslider-box">
        <div className="mgeds_img me-2">
          <img src={epslideimg1} alt="#" />
        </div>
        <div className='mgeds_txt py-3'>
          <span className='fw-semibold'>Exterior</span>
          <p className='headline-6 pb-1'>SLIM REAR TAILLIGHTS</p>
        </div>
      </div>
      <div className="mg_endingslider-box">
        <div className="mgeds_img me-2">
          <img src={epslideimg2} alt="#" />
        </div>
        <div className='mgeds_txt py-3'>
          <span className='fw-semibold'>Exterior</span>
          <p className='headline-6 pb-1'>BOLD WHEEL DESIGN </p>
        </div>
      </div>
      <div className="mg_endingslider-box">
        <div className="mgeds_img me-2">
          <img src={epslideimg3} alt="#" />
        </div>
        <div className='mgeds_txt py-3'>
          <span className='fw-semibold'>Exterior</span>
          <p className='headline-6 pb-1'>AERODYNAMIC DESIGN</p>
        </div>
      </div>
      <div className="mg_endingslider-box">
        <div className="mgeds_img me-2">
          <img src={epslideimg3} alt="#" />
        </div>
        <div className='mgeds_txt py-3'>
          <span className='fw-semibold'>Exterior</span>
          <p className='headline-6 pb-1'>SLIM REAR TAILLIGHTS</p>
        </div>
      </div>
    </ScrollMenu>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <ArrowBackIosOutlined />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <ArrowForwardIosOutlined />
    </div>

  );
}

export default EndingPageSlider;