import { Button, Form } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import { Logout } from '@mui/icons-material';
import ReviewDealSlider from '../../ScreensUi/DigitalCheckout/ReviewDealSlider';
import tradin_img from '../../assets/images/lucid-white-trade.png';
import bd_tradin_badge from '../../assets/images/kelley_blueBook.png';
import incentiveImg from '../../assets/images/iad_img1.png';
import coverageImg from '../../assets/images/lucid-multi-coverage.png';
import tradeInVehicle from '../../assets/images/Trade-in vehicle.jpg';
import multiCoverage from '../../assets/images/multi-coverage.jpg';
import keyProtection from '../../assets/images/Protection-key.jpg';

// import keyProtection from '../../assets/images/lucid-key.png';
import { useLocation } from 'react-router-dom';

const ReviewYourDeal = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);
    return (
        <div>
            <div className="digital_checkout_wrapper review_deal_main">

                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">

                        <div className="po_header pb-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>

                        <div className='rd_content po_content_height'>
                            <div className='po_headlines'>
                                <h3 className='headline-5 light pb-1'>REVIEW YOUR DEAL</h3>
                                <p className='label-2 light pb-4'>Here's everything you've selected so far.</p>
                            </div>

                            <div className="rd_section">
                                <h3 className='headline-6 light pb-3'><span className="text-secondary pe-3">01</span> Your Deal</h3>
                                <div className="rd_slider">
                                    <ReviewDealSlider />
                                </div>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 light'><span className="text-secondary pe-3">02</span> Payment Terms</h3>
                                        <Button className="px-3 py-2" variant="outline-secondary">Edit</Button>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='bd_box border'>
                                            <div className='bd_your_lease_sec d-flex align-items-center border-bottom pb-4 mb-4'>
                                                <div className='bd_yl_text border-end pe-5'>
                                                    <div className='content-3--regular-grey bold'>Your Deal</div>
                                                    <h3 className='headline-5 bold'>£ 965.81 <small className='regular'>/month</small></h3>
                                                    <span className='content-3--regular-grey'>Due at Signing: £ 7,752 </span>
                                                </div>
                                                <div className='bd_yl_text ps-5'>
                                                    <div className='content-3--regular-grey'>36-Month Term</div>
                                                    <div className='content-3--regular-grey'>£ 7,752 Down Payment</div>
                                                    {/* <div className='content-3--regular-grey'>40,000 Miles Per Year</div> */}
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <span className='bd_news_badge content-3--regular-grey text-success bold'>GOOD NEWS!</span>
                                                <p className='content-3--regular-grey ps-2 pe-2'>Your pre-approval covers the total cost of this deal.</p>
                                                <b className='text-success content-3--regular-grey bold'>Valid Until Sep 10, 2024</b>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 light d-flex flex-wrap align-items-center'><span className="text-secondary pe-3">03</span>
                                            Trade-in Estimate
                                            <small className='msrp_tip d-flex align-items-center'>

                                            </small>
                                        </h3>
                                        <Button className="px-3 py-2" variant="outline-secondary">Edit</Button>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='bd_box border position-relative'>
                                            {/* <div className='bd_tradin_badge'>
                                                <img src={bd_tradin_badge} alt='#'/>
                                            </div> */}
                                            <div className='bd_tradin_sec d-flex flex-wrap align-items-center justify-content-between'>
                                                <div className='bd_tradin_left d-flex flex-wrap align-items-center'>
                                                    <div className='bd_tradin_img me-4'>
                                                        <img src={tradeInVehicle} alt='#' />
                                                    </div>
                                                    <div className='bd_tradin_text'>
                                                        <h3 className='headline-6 bold'>2021 C Class - C 260</h3>
                                                        {/* <span className='content-3--regular-grey'>Kelley Blue Book Instant Cash Offer</span> */}
                                                    </div>
                                                </div>
                                                <div className='bd_tradin_right text-end'>
                                                    <span className='content-3--regular-grey'>Appraised Value</span>
                                                    <h3 className='headline-6 bold'>£ 7,150</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 light d-flex flex-wrap align-items-center'>
                                            <span className="text-secondary pe-3">04</span>
                                            Full Breakdown
                                        </h3>
                                        <Button className="px-3 py-2" variant="outline-secondary">Edit</Button>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='bd_box border'>
                                            <div className='bd_breakdown px-2'>
                                                <div className='d-flex'>
                                                    <div className='col-md-8'>
                                                        <h3 className='content-2--bold'>Total Cost of Deal</h3>
                                                    </div>
                                                    <div className='col ps-2 text-end'>
                                                        <h3 className='content-2--bold'>£ 37,083 </h3>
                                                    </div>
                                                </div>
                                                <div className="filter_options_divider mt-3 mb-3"></div>
                                                <div className='row'>
                                                    <div className='col-md-8 pb-2'>
                                                        <span className="acc_title mb-0 me-2">MSRP</span>
                                                    </div>
                                                    <div className='col-md-4 pb-2 text-end'>
                                                        <span className="acc_title mb-0">£ 51,680 </span>
                                                    </div>
                                                </div>
                                                {/* <div className='row'>
                                                    <div className='col-md-8 pb-2'>
                                                        <span className="acc_title mb-0 me-2">Loyalty Credit</span>
                                                    </div>
                                                    <div className='col-md-4 pb-2 text-end'>
                                                        <span className="acc_title  mb-0">-$750</span>
                                                    </div>
                                                </div> */}
                                                <div className='row'>
                                                    <div className='col-md-8 pb-2'>
                                                        <span className="acc_title mb-0 me-2 d-flex align-items-center">Cash Down
                                                        </span>
                                                    </div>
                                                    <div className='col-md-4 pb-2 text-end'>
                                                        <span className="acc_title  mb-0">£ 7,752</span>
                                                    </div>
                                                </div>
                                                <div className="filter_options_divider mt-2 mb-3"></div>
                                                <div className='row'>
                                                    <div className='col-md-8 pb-2'>
                                                        <span className="acc_title mb-0 me-2">Trade-in Value</span>
                                                    </div>
                                                    <div className='col-md-4 pb-2 text-end'>
                                                        <span className="acc_title  mb-0">£ 7,150 </span>
                                                    </div>
                                                </div>
                                                {/* <div className='row'>
                                                    <div className='col-md-8 pb-2'>
                                                        <span className="acc_title mb-0 me-2 d-flex align-items-center">Incentives & Discount</span>
                                                    </div>
                                                    <div className='col-md-4 pb-2 text-end'>
                                                        <span className="acc_title  mb-0">$ -2,800</span>
                                                    </div>
                                                </div> */}

                                                {/* <div className='row'>
                                                    <div className='col-md-8 pb-2'>
                                                        <span className="acc_title mb-0 me-2 d-flex align-items-center">Protection and Coverage
                                                        </span>
                                                    </div>
                                                    <div className='col-md-4 pb-2 text-end'>
                                                        <span className="acc_title  mb-0">$ 52,200 </span>
                                                    </div>
                                                </div> */}



                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 light d-flex flex-wrap align-items-center'>
                                            <span className="text-secondary pe-3">05</span>
                                            Acknowledgement of Terms
                                        </h3>
                                    </div>
                                    <div className="col-md-9">
                                        <div className='bd_box border'>
                                            <div className='row'>
                                                <div className='col-12 mb-3'>
                                                    <label className="has_checkbox_row">
                                                        <Form.Check type="checkbox"></Form.Check>
                                                        <span className="ps-3 content-3--regular-grey">
                                                            Confirm that you accept our terms before submitting your application.
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <DigitalCheckoutFooter pageUrl={`/FinancingPage?orderId=${orderID}&customerRefId=${customerRefId}`} />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default ReviewYourDeal;