import { Button, Modal } from 'react-bootstrap';

import { OverlayTrigger } from "react-bootstrap";
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import styles from '../../../ScreensUi/ProductDetails/SummaryDetails/SummaryDetails.module.css';

const VehicleDetailsModal = ({ show, handleClose, item }) => {
  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`${styles.summary_items} paddingBottom`}>
            <ul>
              <li>
                <h4>Interior</h4>
                <p>{item.vehicle.interior_color_description}</p>
              </li>
              <li>
                <h4>EXTERIOR</h4>
                <p>{item.vehicle.exterior_color_description}</p>
              </li>
              <li>
                <h4>ENGINE</h4>
                <p>{item.vehicle.engine_description}</p>
              </li>
              <li>
                <h4>FUEL TYPE </h4>
                <p>{item.vehicle.fuel_type}</p>
              </li>
              <li>
                <h4>DRIVETRAIN</h4>
                <p>{item.vehicle.drive_train}</p>
              </li>
              <li>
                <h4>TRIM</h4>
                <p>{item.vehicle.trim_description}</p>
              </li>
              <li>
                <h4>VIN # </h4>
                <p>{item.vehicle.vin}</p>
              </li>
              <li>
                <h4>MPG <span className='msrp_tip'>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip className='tooltip_box tooltip_box_top'>
                      <>
                        Preliminary EPA-estimated figures are for comparison purposes only. Your actual mileage will vary, depending on speed, driving habits, trip length and driving conditions; actual mileage may be lower. Note for MPGe: All data are provisional and subject to official confirmation. The provisional power consumption figures have been calculated based on the ECE test cycle.
                      </>
                    </Tooltip>}
                  >
                    <i className='help_icon'></i>
                  </OverlayTrigger>
                </span> </h4>
                <p></p>
              </li>
              <li>
                <h4>HORSEPOWER</h4>
                <p></p>
              </li>

            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default VehicleDetailsModal;