import { Link } from "react-router-dom";
import React from "react";
import logo from '../assets/drift_logo.svg';

function Logo() {

    return (
        <div className="header_logo">
            <Link >
                <img src={logo} alt="logo"></img>
            </Link>
        </div>
    )
}
export default Logo;