import { Button } from 'react-bootstrap';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import React from 'react';
import conceptVehicles from '../../assets/images/conceptVehicles.png';
import futureVehicles from '../../assets/images/futureVehicles.png';
import limitedEdition from '../../assets/images/limitedEdition.png';

const MainHomePage = () => {

    return (
        <div className='mainHomePage'>
            <div className='headerShadow sticky-header'>
                <div className='container'>
                    <Header />
                </div>
            </div>
            <div className='homeMainSection homeMainSection1'>
                <div className='container'>
                    <div className='homeMainSectionContent sectionOneContent'>
                        <div className="d-flex align-items-start flex-column has_content justify-content-between h-100">
                            <div className="mb-auto tu_text"><h5 className='pt-3'>The <strong>Ultimate</strong> Driving Machine</h5></div>
                            <div className=" m-auto heading_content">
                                <h1 className=''>BMW ELECTRIFIED</h1>
                            </div>
                            <div className="w-100">
                                <div className="row">
                                    <div className='col-md-4 d-flex align-items-center mt-2'>
                                        <p>iX sxDrive50e, i4 M50 - European Model Shown</p>
                                    </div>
                                    <div className='col-md-4 text-center mt-2'>
                                        <Link className='' to='/'>
                                            <Button className='product_list_btn w-50 ps-2 pe-2' variant="primary">Learn More</Button>
                                        </Link>
                                    </div>
                                    <div className='col-md-4'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='homeMainSection homeMainSection2 '>
                <div className='container'>
                    <div className='homeMainSectionContent sectionTwoContent'>
                        <div className="d-flex align-items-start flex-column justify-content-between h-100">
                            <div className="p-2 heading_content has_content">
                                <div className='second_img_heading'>
                                    <h5 className='pt-5'>THE </h5>
                                    <h1 className=''>i4</h1>
                                </div>

                            </div>
                            <div className="w-100">
                                <div className="row">
                                    <div className='col-md-4 d-flex align-items-center'>
                                        <p>3 Series Sedan, X3, 5 Series Sedan - European Model Shown</p>
                                    </div>
                                    <div className='col-md-4 col-lg-6 text-center'>

                                        <div className="d-flex">
                                            <div className='col pe-1'>
                                                <Link>
                                                    <Button className='product_list_btn ps-2 pe-2 w-100' variant="primary" >Pre-Order</Button>
                                                </Link>
                                            </div>
                                            <div className='col ps-1'>
                                                <Link to='/'>
                                                    <Button className='product_list_btn ps-2 pe-2 w-100' variant="secondary">Learn More</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='homeMainSection homeMainSection3'>
                <div className='container'>
                    <div className='homeMainSectionContent sectionThreeContent'>
                        <div className="d-flex align-items-start flex-column justify-content-between h-100">
                            <div className="p-2 heading_content has_content">
                                <div className='second_img_heading'>
                                    <h5 className='pt-5'>SALES EVENT </h5>
                                    <h1 className=''>ULTIMATE <br /> SUMMER <br /> SALES</h1>
                                    <p>Receive a credit of up to $3,500 on <br />
                                        select models through september 6th.</p>
                                    <Link>
                                        <span className='bold'>Important Info</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="row">
                                    <div className='col-md-4 col-lg-4 d-flex align-items-center'>
                                        <p>3 Series Sedan, X3, 5 Series Sedan - European Model Shown</p>
                                    </div>
                                    <div className='col-md-6 col-lg-4 text-center'>

                                        <div className="d-flex">
                                            <div className='col pe-1'>
                                                <Link>
                                                    <Button className='product_list_btn ps-2 pe-2 w-100' variant="primary" >Pre-Order</Button>
                                                </Link>
                                            </div>
                                            <div className='col ps-1'>
                                                <Link>
                                                    <Button className='product_list_btn ps-2 pe-2 w-100' variant="secondary">View all Offers</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='homeMainSection4'>
                <div className='container'>
                    <div className='sectionFourContent'>
                        <div className="w-100 pt-4">
                            <div className='text-center mb-3'>
                                <h5 className=''>BMW MODELS</h5>
                                <h1 className='mb-3'>EXPLORE OUR LINEUP</h1>
                            </div>

                            <div className='d-flex position-relative justify-content-center all_models_heading'>
                                <div className='models_btn'> All BWM Models</div>
                            </div>
                            <div className="hm_models_section">
                                <div className="hm_models_list">
                                    <ul>
                                        <li>
                                            <Link to='/' className="hm_models_box" title="X1">
                                                <img alt="A BMW iX Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBI&vehicle=24II&client=byo&paint=P0C57&FABRIC=FSCHA&POV=walkaround&ANGLE=60&SA=S01LD,S02VR,S0322,S0330,S03L7,S0407,S0420,S05AZ,S05DN,S09T8&BKGND=TRANSPARENT&RESP=PNG&quality=70&width=450&width=450 " />
                                                <div className='text-center'>
                                                    <Link className="text_arrow_btn">
                                                        Electrified
                                                        <KeyboardArrowRightOutlined />
                                                    </Link>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/' className="hm_models_box" title="X3">
                                                <img alt="X3" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XQ&client=byo&paint=P0A90&fabric=FKHSW&sa=S01X8,S0255,S02TB,S0302,S0319,S03AG,S0420,S0423,S0430,S0431,S0459,S0481,S0493,S04K1,S04U0,S04UR,S0508,S0534,S0552,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U2,S0775&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                <div className='text-center'>
                                                    <Link className="text_arrow_btn">
                                                        Performance
                                                        <KeyboardArrowRightOutlined />
                                                    </Link>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/' className="hm_models_box" title="X4">
                                                <img alt="X1" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=23XB&client=byo&paint=P0C66&fabric=FKHSW&sa=S01ED,S02TF,S0302,S03AT,S03MB,S0420,S0459,S04AT,S05A4,S05AC,S05AS,S05DM,S06AC,S06AK,S06C4,S06CP,S06WC,S0775&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=600&width=900&width=450 " />
                                                <div className='text-center'>
                                                    <Link className="text_arrow_btn">
                                                        SUVs
                                                        <KeyboardArrowRightOutlined />
                                                    </Link>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/' className="hm_models_box" title="X5">
                                                <img alt="M8 Gran Coupe" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248L&client=byo&paint=P0C4G&fabric=FLKSW&sa=S01MA,S01TH,S0302,S0319,S0322,S03MF,S0415,S0416,S04GQ,S04HB,S04MC,S04NB,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0712,S0760,S0776,S07M9,S07MA&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                <div className='text-center'>
                                                    <Link className="text_arrow_btn">
                                                        Sedan
                                                        <KeyboardArrowRightOutlined />
                                                    </Link>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/' className="hm_models_box" title="X6">
                                                <img alt="M2" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=242R&client=byo&paint=P0C6E&fabric=FMANL&sa=S01E6,S01MB,S02MA,S02VF,S0302,S0319,S0322,S0403,S0430,S0431,S043A,S0459,S0493,S0494,S04AT,S04GQ,S04UR,S0508,S0534,S0544,S05AS,S05DC,S0688,S06AC,S06AK,S06C4,S06CP,S06U2,S06WC,S0712,S0760,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&width=900&width=450 " />
                                                <div className='text-center'>
                                                    <Link className="text_arrow_btn">
                                                        Copens
                                                        <KeyboardArrowRightOutlined />
                                                    </Link>
                                                </div>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/' className="hm_models_box" title="X7">
                                                <img alt="M8" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248K&client=byo&paint=P0C57&fabric=FLKSW&sa=S01MA,S01TH,S0302,S0316,S0319,S0322,S03MF,S0453,S04GQ,S04HB,S04MC,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0712,S0760,S07M9,S07MA&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                <div className='text-center'>
                                                    <Link className="text_arrow_btn">
                                                        Convertibles
                                                        <KeyboardArrowRightOutlined />
                                                    </Link>
                                                </div>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='homeMainSection5 mb-5 pb-3'>
                <div className='container'>
                    <div className='sectionFourContent'>
                        <div className="w-100 pt-4">
                            <div className="row text-center">
                                <div className='col-md-4 '>
                                    <Link>
                                        <img src={futureVehicles} alt='#'></img>
                                    </Link>

                                </div>
                                <div className='col-md-4 '>
                                    <Link>
                                        <img src={limitedEdition} alt='#'></img>
                                    </Link>

                                </div>
                                <div className='col-md-4'>
                                    <Link>
                                        <img src={conceptVehicles} alt='#'></img>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MainHomePage;