import { Button, Modal } from 'react-bootstrap';
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Formik, Form, ErrorMessage, Field } from 'formik'
import * as Yup from 'yup'

import { useCustomerCheckByEmail, useGetJWTToken } from '../../hooks'
import { context } from '../../ScreensUi/ProductDetails/PricingDetail/PricingDetail'
import logo from '../../assets/lane_logo.svg';
import { getInitials } from '../../utilities/methods'

const INITIAL_VALUES = {
  email: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  state: ''
}

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().required('Please provide a valid email').email('Invalid email address')
})

const PreQualifySecondsModel = ({ showModal, handleCloseModal, showStep, asset, DISABLE_ON_SAVE }) => {
  const { state, dispatch } = useContext(context);

  const { mutate: JWT_TOKEN, isLoading: JWT_TOKEN_IS_LOADING, isError: JWT_TOKEN_IS_ERROR, error: JWT_TOKEN_ERROR } = useGetJWTToken((data) => {
    localStorage.setItem('token', data.access_token)
    localStorage.setItem('initials', getInitials(`${data?.profile?.first_name} ${data?.profile?.last_name}`))
    localStorage.setItem('email', data?.email || data?.user_name)
    customer_check({
      email: data.user_name || data.email,
      dealer_id: asset.dealer_id
    })
  })

  const { mutate: customer_check, isLoading, isError, error } = useCustomerCheckByEmail((data) => {
    dispatch({ type: 'SET_USER_DATA', payload: data })
  })

  // Use the formik hook to setup the form
  const [isNextStep, setIsNextStep] = useState({ id: showStep })
  const continueHandleClick = (e) => { setIsNextStep({ id: e }) }

  const SUBMIT_HANDLER = (form) => {
    JWT_TOKEN({
      email: form.email,
      first_name: form.firstName,
      last_name: form.lastName
    })
  }

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal} size="lg" >
        <Modal.Body>
          <div className='preQualifyContent'>
            {
              isNextStep.id === 'step1' ?
                <div className='preQualifyContent_step1 text-center'>
                  <div className='fpi_title_logo pb-2'>
                    {/* <img className='logo-setting' src={logo} alt="logo" /> */}
                    <span>Dealer One</span>
                  </div>
                  <h2 className='headline-4 pb-3'>Best Estimate Your Deal</h2>
                  <p className='headline-6 pb-4'>To obtain the most accurate estimate, a soft credit pull would allow you to see the closest final monthly payment possible. This will not show up on your credit report.</p>
                  <div className='d-flex align-items-center justify-content-center pt-3'>
                    <div className='col-md-5 pe-1'>
                      <Link>
                        <Button className='product_list_btn ps-2 pe-2 w-100' variant="secondary" onClick={handleCloseModal}>Close</Button>
                      </Link>
                    </div>
                    <div className='col-md-5  ps-1'>
                      <Link>
                        <Button onClick={(e) => { continueHandleClick("step2") }} className='product_list_btn ps-2 pe-2 w-100' variant="primary">Start Soft Credit Pull</Button>
                      </Link>
                    </div>
                  </div>
                </div>
                : ''
            }

            {
              isNextStep.id === 'step2' ?
                <div className='preQualifyContent_step2'>
                  <div className='fpi_title_logo pb-2'>
                    {/* <img className='logo-setting' src={logo} alt="logo" /> */}
                    <span>Dealer One</span>
                  </div>
                  <h2 className='headline-4 pb-3 text-center'>Enter Information</h2>
                  <Formik initialValues={INITIAL_VALUES} validationSchema={VALIDATION_SCHEMA} onSubmit={SUBMIT_HANDLER}>
                    <Form>
                      <div className="pb-0 mb-1">
                        <div className='row'>
                          <div className='col-md-6 pb-2'>
                            <span className="acc_title bold mb-1">First Name</span>
                            <Field id='firstName' name='firstName' type="text" placeholder='' className="form-control border border-success border-opacity-10  rounded-0" />
                            <div><ErrorMessage name='firstName' /></div>
                          </div>
                          <div className='col-md-6 pb-2'>
                            <span className="acc_title bold  mb-1">Last Name</span>
                            <Field id='lastName' name='lastName' type="text" placeholder='' className="form-control border border-success border-opacity-10  rounded-0" />
                            <div><ErrorMessage name='lastName' /></div>
                          </div>
                          <div className='col-md-6 pb-2'>
                            <span className="acc_title bold mb-1">Post Code</span>
                            <Field id='zipCode' name='zipCode' type="text" placeholder='' className="form-control border border-success border-opacity-10  rounded-0" />
                            <div><ErrorMessage name='zipCode' /></div>
                          </div>
                          <div className='col-md-6 pb-2'>
                            <span className="acc_title bold mb-1">Region</span>
                            <Field id='state' name='state' type="text" placeholder='' className="form-control border border-success border-opacity-10  rounded-0" />
                            <div><ErrorMessage name='state' /></div>
                          </div>
                          <div className='col-md-6 pb-2'>
                            <span className="acc_title bold mb-1">Email</span>
                            <Field id='email' name='email' type="email" placeholder='' className="form-control border border-success border-opacity-10  rounded-0" />
                            <div><ErrorMessage name='email' /></div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center justify-content-center pt-2'>
                        <div className='col-md-5  ps-1'>
                          <Button
                            type='submit'
                            className='product_list_btn ps-2 pe-2 w-100'
                            variant="primary"
                            disabled={DISABLE_ON_SAVE || JWT_TOKEN_IS_LOADING || isLoading}
                          >
                            {DISABLE_ON_SAVE || JWT_TOKEN_IS_LOADING || isLoading ? 'Wait...' : 'Finish'}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
                : ''
            }
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default PreQualifySecondsModel;