import { ArrowOutward, BooMilesarkAddedOutlined, HelpOutlineOutlined, ShoppingCartOutlined } from "@mui/icons-material";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import React, { useReducer, createContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'
import MoreWaysAccordian from '../MoreWaysAccordian/MoreWaysAccordian';
import PreQualifySecondsModel from '../../../Components/Models/PreQualifySecondsModel';
import styles from "./PricingDetail.module.css";
import { useMultipleQueriesForPriceDetail, useQuoteCalculation, useSaveQuotation, useProductRates, updateCreditRating, updateAnnualMileage, updateTerms } from "../../../hooks";
import { Price } from '../../../utilities'
import PricingDetailModal from "./PricingDetailModal";
import { LENDER_ID } from '../../../services'

export const context = createContext();

const initialState = {

  MODAL_STATE: false,
  PRICE_DETAIL_MODAL_VISIBILITY: false,

  PROGRAM: 'Finance',

  SELECTED_CONTRACT_TERM: 0,
  SELECTED_CONTRACT_TERM_ID: 0,

  CREDIT_RATING: '',
  CREDIT_RATING_ID: 0,

  MILEAGE: 0,
  MILEAGE_ID: 0,

  MSRP: 0,

  LEASE_DOWN_PAYMENT_AMOUNT: 0,
  LEASE_DOWN_PAYMENT_PERCENTAGE: 0,
  FINANCE_DOWN_PAYMENT_AMOUNT: 0,
  FINANCE_DOWN_PAYMENT_PERCENTAGE: 0,

  ESTIMATED_PRICE_LEASE: 0,
  ESTIMATED_PRICE_FINANCE: 0,
  PRICE_LEASE: null,
  PRICE_FINANCE: null,

  TRADE_IN_AMOUNT: 0,
  REMAINING_VALUE: 0,
  TRADE_IN_EQUITY: 0,

  ACCESSORIES_WITH_ZERO_VALUE: [],
  ACCESSORIES_WITH_ACTUAL_VALUE: [],

  FNI_PRODUCTS_FINANCE: [],
  FNI_PRODUCTS_FINANCE_SELECTED: [],

  FNI_PRODUCTS_LEASE: [],
  FNI_PRODUCTS_LEASE_SELECTED: [],

  USER_DATA: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_MODAL_VISIBILITY':
      return {
        ...state,
        MODAL_STATE: action.payload
      }

    case 'SET_PROGRAM':
      return {
        ...state,
        PROGRAM: action.payload
      }

    case 'SET_CONTRACT_TERM':
      return {
        ...state,
        SELECTED_CONTRACT_TERM: action.payload.TERM,
        SELECTED_CONTRACT_TERM_ID: action.payload.TERM_ID
      }

    case 'SET_LEASE_MILES':
      return {
        ...state,
        MILEAGE: action.payload.MILEAGE,
        MILEAGE_ID: action.payload.MILEAGE_ID
      }

    case 'SET_CREDIT_SCORE':
      return {
        ...state,
        CREDIT_RATING: action.payload.CREDIT_RATING,
        CREDIT_RATING_ID: action.payload.CREDIT_RATING_ID
      }

    case 'SET_MSRP':
      return {
        ...state,
        MSRP: action.payload
      }

    case 'SET_CASH_DOWN':
      return {
        ...state,
        CASH_DOWN: action.payload
      }

    case 'SET_DOWN_PAYMENT':
      return {
        ...state,
        LEASE_DOWN_PAYMENT_AMOUNT: action.payload.LEASE_DOWN_PAYMENT_AMOUNT,
        LEASE_DOWN_PAYMENT_PERCENTAGE: action.payload.LEASE_DOWN_PAYMENT_PERCENTAGE,
        FINANCE_DOWN_PAYMENT_AMOUNT: action.payload.FINANCE_DOWN_PAYMENT_AMOUNT,
        FINANCE_DOWN_PAYMENT_PERCENTAGE: action.payload.FINANCE_DOWN_PAYMENT_PERCENTAGE
      }

    case 'SET_CHANGED_LEASE_DOWN_PAYMENT':

      return {
        ...state,
        LEASE_DOWN_PAYMENT_AMOUNT: parseFloat(action.payload.LEASE_DOWN_PAYMENT_AMOUNT),
        LEASE_DOWN_PAYMENT_PERCENTAGE: action.payload.LEASE_DOWN_PAYMENT_PERCENTAGE
      }

    case 'SET_CHANGED_FINANCE_DOWN_PAYMENT':
      return {
        ...state,
        FINANCE_DOWN_PAYMENT_AMOUNT: parseFloat(action.payload.FINANCE_DOWN_PAYMENT_AMOUNT),
        FINANCE_DOWN_PAYMENT_PERCENTAGE: action.payload.FINANCE_DOWN_PAYMENT_PERCENTAGE
      }

    case 'ADD_ACCESSORIES':
      return {
        ...state,
        ACCESSORIES: [...state.ACCESSORIES, action.payload]
      }

    case 'REMOVE_ACCESSORIES':
      return {
        ...state,
        ACCESSORIES: state?.ACCESSORIES?.filter((x) => x.id !== action.payload.id)
      }

    case 'SET_STATE':
      return {
        ...state,
        ASSET: action.payload.ASSET
      }

    case 'SET_CREDIT_RATING_MAX_VALUE':
      return {
        ...state,
        CREDIT_RATING_MAX_VALUE: action.payload
      }

    case 'SET_ESTIMATED_PRICE_LEASE':
      return {
        ...state,
        ESTIMATED_PRICE_LEASE: action.payload.ESTIMATED_PRICE_LEASE,
        PRICE_LEASE: action.payload.PRICE_LEASE
      }

    case 'SET_ESTIMATED_PRICE_FINANCE':
      return {
        ...state,
        ESTIMATED_PRICE_FINANCE: action.payload.ESTIMATED_PRICE_FINANCE,
        PRICE_FINANCE: action.payload.PRICE_FINANCE
      }

    case 'SET_USER_DATA':
      return {
        ...state,
        USER_DATA: action.payload
      }

    case 'SET_ACCESSORIES_WITH_ZERO_VALUE':
      return {
        ...state,
        ACCESSORIES_WITH_ZERO_VALUE: [...state.ACCESSORIES_WITH_ZERO_VALUE, action.payload]
      }

    case 'SET_ACCESSORIES_WITH_ACTUAL_VALUE':
      return {
        ...state,
        ACCESSORIES_WITH_ACTUAL_VALUE: [...state.ACCESSORIES_WITH_ACTUAL_VALUE, action.payload]
      }

    case 'REMOVE_ACCESSORIES_WITH_ACTUAL_VALUE':
      return {
        ...state,
        ACCESSORIES_WITH_ACTUAL_VALUE: state.ACCESSORIES_WITH_ACTUAL_VALUE?.filter((x) => x.id !== action.payload.id)
      }

    case 'SET_TRADE_IN_AMOUNT':
      return {
        ...state,
        TRADE_IN_AMOUNT: action.payload,
        TRADE_IN_EQUITY: action.payload - state.REMAINING_VALUE
      }

    case 'SET_REMAINING_VALUE':
      return {
        ...state,
        REMAINING_VALUE: action.payload,
        TRADE_IN_EQUITY: state.TRADE_IN_AMOUNT - action.payload
      }

    case 'SET_TRADE_IN_EQUITY':
      return {
        ...state,
        TRADE_IN_EQUITY: action.payload
      }

    case 'RESET_TRADE_IN':
      return {
        ...state,
        TRADE_IN_AMOUNT: 0,
        REMAINING_VALUE: 0,
        TRADE_IN_EQUITY: 0
      }

    case 'SET_PRICE_DETAIL_MODAL_VISIBILITY':
      return {
        ...state,
        PRICE_DETAIL_MODAL_VISIBILITY: action.payload
      }

    case 'SET_FNI_PRODUCTS_FINANCE':
      return {
        ...state,
        FNI_PRODUCTS_FINANCE: action.payload
      }

    case 'ADD_FNI_PRODUCTS_FINANCE_SELECTED':
      return {
        ...state,
        FNI_PRODUCTS_FINANCE_SELECTED: [...state.FNI_PRODUCTS_FINANCE_SELECTED, action.payload]
      }

    case 'REMOVE_FNI_PRODUCTS_FINANCE_SELECTED':
      return {
        ...state,
        FNI_PRODUCTS_FINANCE_SELECTED: state.FNI_PRODUCTS_FINANCE_SELECTED?.filter((x) => x.product_id !== action.payload.product_id)
      }

    case 'SET_FNI_PRODUCTS_LEASE':
      return {
        ...state,
        FNI_PRODUCTS_LEASE: action.payload
      }

    case 'ADD_FNI_PRODUCTS_LEASE_SELECTED':
      return {
        ...state,
        FNI_PRODUCTS_LEASE_SELECTED: [...state.FNI_PRODUCTS_LEASE_SELECTED, action.payload]
      }

    case 'REMOVE_FNI_PRODUCTS_LEASE_SELECTED':
      return {
        ...state,
        FNI_PRODUCTS_LEASE_SELECTED: state.FNI_PRODUCTS_LEASE_SELECTED?.filter((x) => x.product_id !== action.payload.product_id)
      }

    default:
      return state
  }
}

function PricingDetail() {
  const navigate = useNavigate();

  const { vin ,terms} = useParams();
  const [state, dispatch] = useReducer(reducer, initialState)

  const onSuccessProgram = (data) => {
    console.log('Data: ', data)
    CALLCULATIN_CALL({
      PROGRAMS_DATA: data
    })
  }

  const result = useMultipleQueriesForPriceDetail(vin, onSuccessProgram);
  const { data: ASSET, isError: ASSET_IS_ERROR, isFetched: ASSET_IS_FETCHED } = result[0];
  const { data: VEHICLE_DETAIL, isError: VEHICLE_DETAIL_IS_ERROR, isFetched: VEHICLE_DETAIL_IS_FETCHED } = result[1];
  const { data: DEALER_PREFERENCE, isError: DEALER_PREFERENCE_IS_ERROR, isFetched: DEALER_PREFERENCE_IS_FETCHED } = result[2];
  const { data: CONTRACT_TERM, isError: CONTRACT_TERM_IS_ERROR, isFetched: CONTRACT_TERM_IS_FETCHED } = result[3];
  const { data: CREDIT_RATINGS, isError: CREDIT_RATINGS_IS_ERROR, isFetched: CREDIT_RATINGS_IS_FETCHED } = result[4];
  const { data: CONTRACT_ALLOWED_ANNUM_MILEAGE, isError: CONTRACT_ALLOWED_ANNUM_MILEAGE_IS_ERROR, isFetched: CONTRACT_ALLOWED_ANNUM_MILEAGE_IS_FETCHED } = result[5];
  const { data: ASSETS_BY_TRIM_CODE, isError: ASSETS_BY_TRIM_CODE_IS_ERROR, isFetched: ASSETS_BY_TRIM_CODE_IS_FETCHED } = result[6];
  const { data: PROGRAMS, isError: PROGRAMS_IS_ERROR, isFetched: PROGRAMS_IS_FETCHED, refetch: PROGRAM_REFETCH } = result[7];
  const { data: DEALER_FEE, isError: DEALER_FEE_IS_ERROR, isFetched: DEALER_FEE_IS_FETCHED } = result[8];
  const { data: OPTION_TERMS, isError: OPTION_TERMS_IS_ERROR, isFetched: OPTION_TERMS_IS_FETCHED } = result[9];
  const { data: FNI_PRODUCTS, isError: FNI_PRODUCTS_IS_ERROR, isFetched: FNI_PRODUCTS_IS_FETCHED } = result[10];
  const { data: DEALER_PROFILE, isError: DEALER_PROFILE_IS_ERROR, isFetched: DEALER_PROFILE_IS_FETCHED } = result[11];
  const { data: INTEGRATION, isError: INTEGRATION_IS_ERROR, isFetched: INTEGRATION_IS_FETCHED } = result[12];

  // GET QUOTE CALCULATION
  const { mutate: QUOTE_CALCULATION_LEASE, isError: QUOTE_CALCULATION_LEASE_IS_ERROR, error: QUOTE_CALCULATION_LEASE_ERROR } = useQuoteCalculation((data) => {
    dispatch({
      type: 'SET_ESTIMATED_PRICE_LEASE',
      payload: {
        ESTIMATED_PRICE_LEASE: data.estimated_monthly_payment,
        PRICE_LEASE: data
      }
    })
  })
  const { mutate: QUOTE_CALCULATION_FINANCE, isError: QUOTE_CALCULATION_FINANCE_IS_ERROR, error: QUOTE_CALCULATION_FINANCE_ERROR } = useQuoteCalculation((data) => {
    dispatch({
      type: 'SET_ESTIMATED_PRICE_FINANCE',
      payload: {
        ESTIMATED_PRICE_FINANCE: data.estimated_monthly_payment,
        PRICE_FINANCE: data
      }
    })
  })

  // SAVE QUOTATION
  const {
    mutate: SAVE_QUOTATION,
    isError: SAVE_QUOTATION_IS_ERROR,
    error: SAVE_QUOTATION_ERROR,
    isLoading: SAVE_QUOTATION_IS_LOADING
  } = useSaveQuotation((data) => {
    localStorage.setItem('quotation', data)
    navigate(`/home-shoping-hub/${state.USER_DATA.reference_id}`)
  })

  const getPrice = data => {
    if (data.coverages) {
      let response = {
        applied_price: Infinity,
        form_id: 0,
        rate_id: 0,
        session_id: ''
      }
      data.coverages?.coverage?.filter(x => parseInt(x.term_months) === state.SELECTED_CONTRACT_TERM)?.forEach(x => {
        if (x.deductibles.deductible.retail_price < response.applied_price) {
          response.applied_price = x.deductibles.deductible.retail_price || 0;
          response.form_id = x.form.form_id || 0;
          response.rate_id = x.deductibles.deductible.rate_id || 0;
          response.session_id = data.session_id || '';
        }
      })
      return response;
    } else {
      return {
        applied_price: 0,
        form_id: 0,
        rate_id: 0,
        session_id: ''
      }
    }
  }

  // GET PRODUCT RATES

  const { mutate: PRODUCT_RATES_FINANCE, isError: PRODUCT_RATES_FINANCE_IS_ERROR, error: PRODUCT_RATES_FINANCE_ERROR } = useProductRates((data) => {
    // console.group('PRODUCT_RATES_FINANCE')
    // console.log('PRODUCT_RATES_FINANCE: ', data)

    let FNI_PRODUCTS_FINANCE = []
    FNI_PRODUCTS?.forEach((product) => {
      const response = getPrice(data.find(x => x.product_id === product.product_id))
      FNI_PRODUCTS_FINANCE.push({
        product_id: product.product_id,
        product_name: product.product_name,
        financial_insurance_id: product.id,
        ...response
      })
    })

    // console.log('FNI_PRODUCTS_FINANCE: ', FNI_PRODUCTS_FINANCE)
    // console.groupEnd()

    dispatch({ type: 'SET_FNI_PRODUCTS_FINANCE', payload: FNI_PRODUCTS_FINANCE })
  })

  const { mutate: PRODUCT_RATES_LEASE, isError: PRODUCT_RATES_LEASE_IS_ERROR, error: PRODUCT_RATES_LEASE_ERROR } = useProductRates((data) => {

    // console.group('PRODUCT_RATES_LEASE')
    // console.log('PRODUCT_RATES_LEASE: ', data)

    let FNI_PRODUCTS_LEASE = []
    FNI_PRODUCTS?.forEach((product) => {
      const response = getPrice(data.find(x => x.product_id === product.product_id))
      FNI_PRODUCTS_LEASE.push({
        product_id: product.product_id,
        product_name: product.product_name,
        financial_insurance_id: product.id,
        ...response
      })
    })

    // console.log('FNI_PRODUCTS_LEASE: ', FNI_PRODUCTS_LEASE)
    // console.groupEnd()

    dispatch({ type: 'SET_FNI_PRODUCTS_LEASE', payload: FNI_PRODUCTS_LEASE })
  })

  const CONSOLE_LOG = () => {
    console.log('======================================================================')
    console.log('STATE: ', state)
    console.log('ASSET: ', ASSET)
    console.log('VEHICLE_DETAIL: ', VEHICLE_DETAIL)
    console.log('DEALER_PREFERENCE: ', DEALER_PREFERENCE)
    console.log('CONTRACT_TERM: ', CONTRACT_TERM)
    console.log('CREDIT_RATINGS: ', CREDIT_RATINGS)
    console.log('CONTRACT_ALLOWED_ANNUM_MILEAGE: ', CONTRACT_ALLOWED_ANNUM_MILEAGE)
    console.log('ASSETS_BY_TRIM_CODE: ', ASSETS_BY_TRIM_CODE)
    console.log('PROGRAMS: ', PROGRAMS)
    console.log('DEALER_FEE: ', DEALER_FEE)
    console.log('OPTION_TERMS: ', OPTION_TERMS)
    console.log('FNI_PRODUCTS: ', FNI_PRODUCTS)
    console.log('DEALER_PROFILE: ', DEALER_PROFILE)
    console.log('INTEGRATION: ', INTEGRATION)
    console.log('======================================================================')
  }
  useEffect(() => {
    if(terms && CONTRACT_TERM_IS_FETCHED){
      dispatch({
        type: 'SET_CONTRACT_TERM', payload: {
          TERM: CONTRACT_TERM?.filter((x) => x?.term === terms)[0]?.term,
          TERM_ID: CONTRACT_TERM?.filter((x) => x?.term === terms)[0]?.id
        }
      })
    }
    }, [terms, CONTRACT_TERM_IS_FETCHED])
  // SET DEFAULT VALUE
  useEffect(() => {

    window.scrollTo(0, 0)

    // SET DEFAULT VALUE OF CONTRCAT TERMS
    if (DEALER_PREFERENCE_IS_FETCHED && CONTRACT_TERM_IS_FETCHED) {
      dispatch({
        type: 'SET_CONTRACT_TERM', payload: {
          TERM: CONTRACT_TERM?.filter((x) => x.id === DEALER_PREFERENCE.default_term_id)[0].term,
          TERM_ID: CONTRACT_TERM?.filter((x) => x.id === DEALER_PREFERENCE.default_term_id)[0].id
        }
      })
    }

    // SET DEFAULT VALUE OF MILEAGE
    if (DEALER_PREFERENCE_IS_FETCHED && CONTRACT_ALLOWED_ANNUM_MILEAGE_IS_FETCHED) {
      dispatch({
        type: 'SET_LEASE_MILES', payload: {
          MILEAGE: CONTRACT_ALLOWED_ANNUM_MILEAGE?.filter((x) => x.id === DEALER_PREFERENCE.default_mileage_id)[0].value,
          MILEAGE_ID: CONTRACT_ALLOWED_ANNUM_MILEAGE?.filter((x) => x.id === DEALER_PREFERENCE.default_mileage_id)[0].id
        }
      })
    }

    // GET MAXIMUM VALUE AND SET AS DEFAULT VALUE OF CREDIT RATING
    if (CREDIT_RATINGS_IS_FETCHED) {
      dispatch({
        type: 'SET_CREDIT_SCORE', payload: {
          CREDIT_RATING: CREDIT_RATINGS?.filter((x) => x.is_active === true).sort((a, b) => b.score_to - a.score_to)[0].description,
          CREDIT_RATING_ID: CREDIT_RATINGS?.filter((x) => x.is_active === true).sort((a, b) => b.score_to - a.score_to)[0].id
        }
      })
      dispatch({
        type: 'SET_CREDIT_RATING_MAX_VALUE',
        payload: CREDIT_RATINGS?.filter((x) => x.is_active === true).sort((a, b) => b.score_to - a.score_to)[0].description
      })
    }

    // SET MSRP VALUE
    if (ASSET_IS_FETCHED) {
      dispatch({ type: 'SET_MSRP', payload: ASSET.internet_price })
    }

    // SET DOWN PAYMENT AMOUNT AND DOWN PAYMENT PERCENTGE
    // DOWN PAYMENT PERCENTAGE WILL THE PROGRAMS[0].downpayment_chart[0].downpayment_chart
    // DOWN PAYMENT AMOUNT WILL BE THE MSRP * DOWN PAYMENT PERCENTAGE / 100
    if (ASSET_IS_FETCHED && PROGRAMS_IS_FETCHED) {
      const PROGRAM_LEASE = PROGRAMS?.filter((x) => x?.finance_type === 'Lease')[0]
      const PROGRAM_FINANCE = PROGRAMS?.filter((x) => x?.finance_type === 'Finance')[0]
      dispatch({
        type: 'SET_DOWN_PAYMENT', payload: {
          LEASE_DOWN_PAYMENT_AMOUNT: ASSET.internet_price * (PROGRAM_LEASE && PROGRAM_LEASE.downpayment_chart ? PROGRAM_LEASE.downpayment_chart[0].default_down_payment : 0) / 100,
          LEASE_DOWN_PAYMENT_PERCENTAGE: PROGRAM_LEASE && PROGRAM_LEASE.downpayment_chart ? PROGRAM_LEASE.downpayment_chart[0].default_down_payment : 0,
          FINANCE_DOWN_PAYMENT_AMOUNT: ASSET.internet_price * (PROGRAM_FINANCE && PROGRAM_FINANCE.downpayment_chart ? PROGRAM_FINANCE.downpayment_chart[0].default_down_payment : 0) / 100,
          FINANCE_DOWN_PAYMENT_PERCENTAGE: PROGRAM_FINANCE && PROGRAM_FINANCE.downpayment_chart ? PROGRAM_FINANCE.downpayment_chart[0].default_down_payment : 0
        }
      })
    }

    if (OPTION_TERMS_IS_FETCHED && VEHICLE_DETAIL_IS_FETCHED) {
      OPTION_TERMS?.filter((x) => VEHICLE_DETAIL.dealer_options.map((y) => y.id).includes(x.id)).map((item, index) => {
        if (!state.ACCESSORIES_WITH_ZERO_VALUE.map((x) => x.id).includes(item.id)) {
          dispatch({ type: 'SET_ACCESSORIES_WITH_ZERO_VALUE', payload: item })
        }
      })
    }

    // GET QUOTE CALCULATION ONCE AT THE TIME OF PAGE LOAD
    if (
      ASSET_IS_FETCHED &&
      VEHICLE_DETAIL_IS_FETCHED &&
      DEALER_PREFERENCE_IS_FETCHED &&
      CONTRACT_TERM_IS_FETCHED &&
      CREDIT_RATINGS_IS_FETCHED &&
      CONTRACT_ALLOWED_ANNUM_MILEAGE_IS_FETCHED &&
      ASSETS_BY_TRIM_CODE_IS_FETCHED &&
      PROGRAMS_IS_FETCHED &&
      OPTION_TERMS_IS_FETCHED
    ) {
      PROGRAMS?.forEach(e => {
        const PROGRAM_LEASE = PROGRAMS?.filter((x) => x.finance_type === 'Lease')[0]
        const PROGRAM_FINANCE = PROGRAMS?.filter((x) => x.finance_type === 'Finance')[0]
        const LEASE_DOWN_PAYMENT_AMOUNT = ASSET.internet_price * (PROGRAM_LEASE && PROGRAM_LEASE.downpayment_chart ? PROGRAM_LEASE.downpayment_chart[0].default_down_payment : 0) / 100
        const FINANCE_DOWN_PAYMENT_AMOUNT = ASSET.internet_price * (PROGRAM_FINANCE && PROGRAM_FINANCE.downpayment_chart ? PROGRAM_FINANCE.downpayment_chart[0].default_down_payment : 0) / 100

        const FEES = DEALER_FEE?.filter((x) => x.applicable_finance_type.includes(e.finance_type)).map((fee) => {
          return {
            fee_name: fee.fee_name,
            fee_amount: fee.default_amount
          }
        })
        const TOTAL_FEE_AMOUNT = FEES?.reduce((a, b) => a + b.fee_amount, 0)

        let OPTIONS = []
        OPTION_TERMS?.filter((x) => VEHICLE_DETAIL.dealer_options.map((y) => y.id).includes(x.id)).map((item, index) => {
          if (!state.ACCESSORIES_WITH_ZERO_VALUE.map((x) => x.id).includes(item.id)) {
            OPTIONS.push({
              option_name: item.product_name,
              option_amount: 0,
              rv_amount_on_option: item.rv_adder,
              installation_mode: item.installation_mode
            })
          }
        })

        const payload = {
          apr: parseFloat((e.calculation_method === 'flat plus' ? e.final_customer_rate / 2400 : e.final_customer_rate).toFixed(9)),
          annual_usage: CONTRACT_ALLOWED_ANNUM_MILEAGE?.filter((x) => x.id === DEALER_PREFERENCE.default_mileage_id)[0].value,
          asset_condition: 63,
          base_rate: e.base_rate_chart ? e.base_rate_chart.base_rate : 0,
          calculation_method: e.calculation_method,
          credit_rating_id: CREDIT_RATINGS?.filter((x) => x.is_active === true).sort((a, b) => b.score_to - a.score_to)[0].id,
          dealer_id: DEALER_PREFERENCE.dealer_id,
          down_payment_paid_by_dealer: 0,
          down_payment_paid_by_oem: 0,
          down_payment: (ASSET.internet_price * (e.downpayment_chart ? e.downpayment_chart[0].default_down_payment : 0)) / 100,
          fees: [...FEES],
          finance_type: e.finance_type,
          financed_amount: ASSET.internet_price - (e.finance_type === "Finance" ? FINANCE_DOWN_PAYMENT_AMOUNT : LEASE_DOWN_PAYMENT_AMOUNT) + TOTAL_FEE_AMOUNT - state.TRADE_IN_EQUITY,
          fni_products: [],
          insurance_amount: 0,
          insurance: [],
          interest_chart_margin: e.interest_chart[0].margin,
          is_rv_guaranteed: 'False',
          options: OPTIONS,
          per_unit_selling_price: ASSET.internet_price,
          quantity: 1,
          rental_frequency: 'Monthly',
          rental_mode: e.rental_mode,
          rv_amount: 0,
          rv_value: e.rv_chart ? (ASSET.internet_price * e.rv_chart[0].rv_value) / 100 : 0,
          structure_rentals: [],
          subsidy: {},
          taxes_amount: 0,
          taxes: [],
          terms:terms? terms: CONTRACT_TERM?.filter((x) => x.id === DEALER_PREFERENCE.default_term_id)[0].term,
          trade_in_amount: state.TRADE_IN_EQUITY
        }

        if (e.finance_type === 'Lease') {
          QUOTE_CALCULATION_LEASE(payload)
        } else {
          QUOTE_CALCULATION_FINANCE(payload)
        }
      });
    }

    // GET PRODUCT RATES
    if (
      ASSET_IS_FETCHED &&
      DEALER_PROFILE_IS_FETCHED &&
      PROGRAMS_IS_FETCHED &&
      DEALER_FEE_IS_FETCHED &&
      OPTION_TERMS_IS_FETCHED &&
      FNI_PRODUCTS_IS_FETCHED &&
      CONTRACT_TERM_IS_FETCHED &&
      INTEGRATION_IS_FETCHED
    ) {
      const PROGRAM_LEASE = PROGRAMS?.filter((x) => x.finance_type === 'Lease')[0]
      const PROGRAM_FINANCE = PROGRAMS?.filter((x) => x.finance_type === 'Finance')[0]
      const LEASE_DOWN_PAYMENT_AMOUNT = ASSET.internet_price * (PROGRAM_LEASE && PROGRAM_LEASE.downpayment_chart ? PROGRAM_LEASE.downpayment_chart[0].default_down_payment : 0) / 100
      const FINANCE_DOWN_PAYMENT_AMOUNT = ASSET.internet_price * (PROGRAM_FINANCE && PROGRAM_FINANCE.downpayment_chart ? PROGRAM_FINANCE.downpayment_chart[0].default_down_payment : 0) / 100

      PROGRAMS?.forEach(e => {

        const FEES = DEALER_FEE?.filter((x) => x.applicable_finance_type.includes(e.finance_type)).map((fee) => {
          return {
            fee_name: fee.fee_name,
            fee_amount: fee.default_amount
          }
        })
        const TOTAL_FEE_AMOUNT = FEES?.reduce((a, b) => a + b.fee_amount, 0)

        const DEALER_INFO = {
          pen_dealer_id: DEALER_PROFILE.pen_dealer_id,
          vin: ASSET.vin,
          mileage: ASSET.mileage,
          car_status: (ASSET.type + '').toUpperCase(),
          effective_date: "03/18/2023",
          in_service_date: "04/18/2023",
          finance_type: e.finance_type,
          vehicle_msrp: ASSET.msrp,
          vehicle_purchase_price: ASSET.internet_price,
          deal_type: "LOAN",
          finance_terms: [
            state.SELECTED_CONTRACT_TERM
          ],
          finance_terms_mileage: [
            state.MILEAGE
          ],
          apr: e.final_customer_rate,
          financed_amount: ASSET.internet_price - (e.finance_type === "Finance" ? FINANCE_DOWN_PAYMENT_AMOUNT : LEASE_DOWN_PAYMENT_AMOUNT) + TOTAL_FEE_AMOUNT - state.TRADE_IN_EQUITY,
          language: "ENGLISH",
          user_role: "FI_MANAGER"
        }
        const PRODUCT_INFO = []
        FNI_PRODUCTS?.forEach((product) => {
          PRODUCT_INFO.push({
            product_id: product.product_id,
            starting_mileage: 0,
            ending_mileage: 10000
          })
        })

        let payload = {
          data: {
            deal_info: DEALER_INFO,
            products_info: PRODUCT_INFO,
            test_request: true
          },
          headers: {
            'X-Pen-User-Name': INTEGRATION.lender_integration_user_inputs[0].user_input_value,
            'X-Pen-User-Secret': INTEGRATION.lender_integration_user_inputs[1].user_input_value,
            'X-Pen-User-Token': INTEGRATION.lender_integration_user_inputs[2].user_input_value
          }
        }
        if (e.finance_type === 'Finance') {
          PRODUCT_RATES_FINANCE(payload)
        } else {
          PRODUCT_RATES_LEASE(payload)
        }

      })

    }

  }, [
    CONTRACT_TERM_IS_FETCHED,
    CONTRACT_ALLOWED_ANNUM_MILEAGE_IS_FETCHED,
    CREDIT_RATINGS_IS_FETCHED,
    ASSET_IS_FETCHED,
    VEHICLE_DETAIL_IS_FETCHED,
    PROGRAMS_IS_FETCHED,
    OPTION_TERMS_IS_FETCHED
  ])

  // SAVE QUOTATION WHEN USER DATA IS AVAILABLE
  useEffect(() => {
    if (state.USER_DATA) {
      const PROGRAM_LEASE = PROGRAMS?.filter((x) => x.finance_type === 'Lease')[0]
      const PROGRAM_FINANCE = PROGRAMS?.filter((x) => x.finance_type === 'Finance')[0]
      const DOWN_PAYMENT = state.PROGRAM === 'Lease' ? state.LEASE_DOWN_PAYMENT_PERCENTAGE : state.FINANCE_DOWN_PAYMENT_PERCENTAGE;

      const RV_RATE = state.PROGRAM === 'Lease' ? PROGRAM_LEASE.rv_chart[0].rv_value : PROGRAM_FINANCE.rv_chart[0].rv_value
      const LEASE_DOWN_PAYMENT_AMOUNT = state.LEASE_DOWN_PAYMENT_AMOUNT
      const FINANCE_DOWN_PAYMENT_AMOUNT = state.FINANCE_DOWN_PAYMENT_AMOUNT

      const FEES = DEALER_FEE?.filter((x) => x.applicable_finance_type.includes(state.PROGRAM)).map((fee) => {
        return {
          fee_name: fee.fee_name,
          applied_price: fee.default_amount,
          dealer_fee_id: fee.id
        }
      })
      const TOTAL_FEE_AMOUNT = FEES?.reduce((a, b) => a + b.applied_price, 0)

      let OPTIONS = []
      state.ACCESSORIES_WITH_ZERO_VALUE?.forEach(item => {
        OPTIONS.push({
          option_id: item.id,
          applied_price: 0,
          rv_balloon_percentage: item.rv_adder,
          rv_balloon_value: item.rv_adder
        })
      })
      state.ACCESSORIES_WITH_ACTUAL_VALUE?.forEach(item => {
        OPTIONS.push({
          option_id: item.id,
          applied_price: item.price,
          rv_balloon_percentage: item.rv_adder,
          rv_balloon_value: item.rv_adder
        })
      })
      const OPTIONS_AMOUNT = OPTIONS?.reduce((a, b) => a + b.applied_price, 0)

      const FNI_PRODUCTS = (state.PROGRAM !== "Finance" ? state.FNI_PRODUCTS_LEASE_SELECTED : state.FNI_PRODUCTS_FINANCE_SELECTED)
        .map((product) => {
          return {
            financial_insurance_id: product.financial_insurance_id,
            applied_price: product.applied_price,
            form_id: product.form_id,
            rate_id: product.rate_id,
            session_id: product.session_id
          }
        })
      const FNI_PRODUCTS_TOTAL = FNI_PRODUCTS?.reduce((a, b) => a + b.applied_price, 0)

      let ORDER_TRADEIN = null
      if (state.TRADE_IN_AMOUNT > 0) {
        ORDER_TRADEIN = {
          trade_in_amount: state.TRADE_IN_AMOUNT,
          payoff_amount: state.REMAINING_VALUE
        }
      }
console.log(state.PROGRAM,"state.PROGRAM")
      const e = PROGRAMS?.filter((x) => x.finance_type === state.PROGRAM)[0]
      const payload = [{
        calcuation_method: state.PROGRAM === 'Lease' ? PROGRAM_LEASE.calculation_method : PROGRAM_FINANCE.calculation_method,
        dealer_id: ASSET.dealer_id,
        lender_id: LENDER_ID,
        reference_id: state.USER_DATA.reference_id,
        contract_term: state.SELECTED_CONTRACT_TERM,
        allowed_usage: state.MILEAGE_ID,
        credit_rating: state.CREDIT_RATING_ID,
        apr: e.final_customer_rate,
        estimated_monthly_payment: state.PROGRAM === 'Lease' ? state.ESTIMATED_PRICE_LEASE : state.ESTIMATED_PRICE_FINANCE,
        annual_usage: state.MILEAGE_ID,
        due_at_signing: state.PROGRAM === 'Finance' ? state.PRICE_FINANCE.due_at_signing + state.ESTIMATED_PRICE_FINANCE : state.PRICE_LEASE.due_at_signing + state.ESTIMATED_PRICE_LEASE,
        selling_price: ASSET.internet_price,
        down_payment: DOWN_PAYMENT,
        down_payment_lease: DOWN_PAYMENT,
        rv_balloon_percentage: RV_RATE,
        rv_balloon_value: RV_RATE,
        fp_id: state.PROGRAM === 'Lease' ? PROGRAM_LEASE.fp_id : PROGRAM_FINANCE.fp_id,
        fni_products: [],
        vehicle_id: ASSET.id,
        vin: ASSET.vin,
        finance_amount: ASSET.internet_price - (state.PROGRAM === "Finance" ? FINANCE_DOWN_PAYMENT_AMOUNT : LEASE_DOWN_PAYMENT_AMOUNT) + TOTAL_FEE_AMOUNT + OPTIONS_AMOUNT + FNI_PRODUCTS_TOTAL - state.TRADE_IN_EQUITY,
        finance_type: state.PROGRAM,
        order_fnI: [],
        order_fees: FEES,
        order_options: OPTIONS,
        asset_usage: 'Commercial',
        schedule_option: 'Pick up',
        order_tradein: ORDER_TRADEIN,
        order_fnI: FNI_PRODUCTS,
        rental_frequency: 'Monthly',
        rental_mode: e.rental_mode,
        base_rate: e.base_rate_chart.base_rate,
        margin: e.interest_chart[0]?.margin,
        order_asset: {
          make: ASSET?.make,
          model: ASSET?.model,
          trim: ASSET?.trim_description,
          year_manufacturer: ASSET?.year,
          msrp: ASSET?.msrp,
          exterior_color: ASSET?.exterior_color_description,
          classification: ASSET?.vehicle_class,
          engine_cylinder: ASSET?.engine_cylinders,
          power_type: ASSET?.engine_power,
          engine_displacement: ASSET?.engine_displacement,
          fuel_type: ASSET?.fuel_type,
          quantity: 1,
          unit_price: ASSET?.internet_price,
          vin: ASSET?.vin
        },
      }]

      console.log('Payload: ', payload)

      SAVE_QUOTATION(payload)
    }
  }, [state.USER_DATA])

  // GET QUOTE CALCULATION WHEN NEEDED
  const CALLCULATIN_CALL = ({
    SELECTED_CONTRACT_TERM = state.SELECTED_CONTRACT_TERM,
    CREDIT_RATING_ID = state.CREDIT_RATING_ID,
    MILEAGE = state.MILEAGE,
    LEASE_DOWN_PAYMENT_AMOUNT = state.LEASE_DOWN_PAYMENT_AMOUNT,
    FINANCE_DOWN_PAYMENT_AMOUNT = state.FINANCE_DOWN_PAYMENT_AMOUNT,
    PROGRAMS_DATA = PROGRAMS
  }) => {
    if (
      ASSET_IS_FETCHED &&
      VEHICLE_DETAIL_IS_FETCHED &&
      DEALER_PREFERENCE_IS_FETCHED &&
      CONTRACT_TERM_IS_FETCHED &&
      CREDIT_RATINGS_IS_FETCHED &&
      CONTRACT_ALLOWED_ANNUM_MILEAGE_IS_FETCHED &&
      ASSETS_BY_TRIM_CODE_IS_FETCHED &&
      PROGRAMS_IS_FETCHED &&
      OPTION_TERMS_IS_FETCHED
    ) {
      PROGRAMS_DATA?.forEach(e => {
        const FEES = DEALER_FEE?.filter((x) => x.applicable_finance_type.includes(e.finance_type)).map((fee) => {
          return {
            fee_name: fee.fee_name,
            fee_amount: fee.default_amount
          }
        })
        const TOTAL_FEE_AMOUNT = FEES?.reduce((a, b) => a + b.fee_amount, 0)

        let OPTIONS = []
        state.ACCESSORIES_WITH_ZERO_VALUE?.forEach(item => {
          OPTIONS.push({
            option_name: item.product_name,
            option_amount: 0,
            rv_amount_on_option: item.rv_adder,
            installation_mode: item.installation_mode
          })
        })
        state.ACCESSORIES_WITH_ACTUAL_VALUE?.forEach(item => {
          OPTIONS.push({
            option_name: item.product_name,
            option_amount: item.price,
            rv_amount_on_option: item.rv_adder,
            installation_mode: item.installation_mode
          })
        })
        const OPTIONS_AMOUNT = OPTIONS?.reduce((a, b) => a + b.option_amount, 0)

        const FNI_PRODUCTS = (e.finance_type !== "Finance" ? state.FNI_PRODUCTS_LEASE_SELECTED : state.FNI_PRODUCTS_FINANCE_SELECTED)
          .map((product) => {
            return {
              financial_insurance_id: product.financial_insurance_id,
              applied_price: product.applied_price,
              form_id: product.form_id,
              rate_id: product.rate_id,
              session_id: product.session_id
            }
          })
        const FNI_PRODUCTS_TOTAL = FNI_PRODUCTS?.reduce((a, b) => a + b.applied_price, 0)

        const payload = {
          apr: e.calculation_method === 'flat plus' ? e.final_customer_rate / 2400 : e.final_customer_rate,
          annual_usage: parseFloat(MILEAGE),
          asset_condition: 63,
          base_rate: e.base_rate_chart ? e.base_rate_chart.base_rate : 0,
          calculation_method: e.calculation_method,
          credit_rating_id: parseFloat(CREDIT_RATING_ID),
          dealer_id: DEALER_PREFERENCE.dealer_id,
          down_payment_paid_by_dealer: 0,
          down_payment_paid_by_oem: 0,
          down_payment: e.finance_type === "Finance" ? parseFloat(FINANCE_DOWN_PAYMENT_AMOUNT) : parseFloat(LEASE_DOWN_PAYMENT_AMOUNT),
          fees: [...FEES],
          finance_type: e.finance_type,
          financed_amount: ASSET.internet_price - (e.finance_type === "Finance" ? FINANCE_DOWN_PAYMENT_AMOUNT : LEASE_DOWN_PAYMENT_AMOUNT) + TOTAL_FEE_AMOUNT + OPTIONS_AMOUNT + FNI_PRODUCTS_TOTAL - state.TRADE_IN_EQUITY,
          fni_products: [],
          insurance_amount: 0,
          insurance: [],
          interest_chart_margin: e.interest_chart[0].margin,
          is_rv_guaranteed: 'False',
          options: OPTIONS,
          per_unit_selling_price: ASSET.internet_price,
          quantity: 1,
          rental_frequency: 'Monthly',
          rental_mode: e.rental_mode,
          rv_amount: 0,
          rv_value: e.rv_chart ? (ASSET.internet_price * e.rv_chart[0].rv_value) / 100 : 0,
          structure_rentals: [],
          subsidy: {},
          taxes_amount: 0,
          taxes: [],
          terms: parseFloat(SELECTED_CONTRACT_TERM),
          trade_in_amount: state.TRADE_IN_EQUITY,
          order_fnI: FNI_PRODUCTS
        }

        if (e.finance_type === 'Lease') {
          QUOTE_CALCULATION_LEASE(payload)
        } else {
          QUOTE_CALCULATION_FINANCE(payload)
        }
      });
    }

  }

  // GET QUOTE CALCULATION WHEN NEEDED
  useEffect(() => {
    CALLCULATIN_CALL({})
  }, [state.ACCESSORIES_WITH_ACTUAL_VALUE])

  useEffect(() => {
    CALLCULATIN_CALL({})
  }, [state.FNI_PRODUCTS_LEASE_SELECTED])

  useEffect(() => {
    CALLCULATIN_CALL({})
  }, [state.FNI_PRODUCTS_FINANCE_SELECTED])

  useEffect(() => {
    if (state.TRADE_IN_AMOUNT === 0)
      CALLCULATIN_CALL({})
  }, [state.TRADE_IN_AMOUNT])

  useEffect(() => {
    updateCreditRating(state.CREDIT_RATING)
    PROGRAM_REFETCH()
  }, [state.CREDIT_RATING])

  useEffect(() => {
    updateAnnualMileage(state.MILEAGE)
    PROGRAM_REFETCH()
  }, [state.MILEAGE])

  useEffect(() => {
    if(terms){
      updateTerms(terms)
      PROGRAM_REFETCH()
    }
    else{
    updateTerms(state.SELECTED_CONTRACT_TERM)
    PROGRAM_REFETCH()
    }

  }, [state.SELECTED_CONTRACT_TERM, terms])

  if (
    ASSET_IS_ERROR ||
    VEHICLE_DETAIL_IS_ERROR ||
    DEALER_PREFERENCE_IS_ERROR ||
    CONTRACT_TERM_IS_ERROR ||
    CREDIT_RATINGS_IS_ERROR ||
    CONTRACT_ALLOWED_ANNUM_MILEAGE_IS_ERROR ||
    PROGRAMS_IS_ERROR ||
    ASSETS_BY_TRIM_CODE_IS_ERROR ||
    DEALER_FEE_IS_ERROR ||
    OPTION_TERMS_IS_ERROR
  ) {
    return <div className='text-center'>Error in fetching data</div>
  }

  if (
    !ASSET_IS_FETCHED ||
    !VEHICLE_DETAIL_IS_FETCHED ||
    !DEALER_PREFERENCE_IS_FETCHED ||
    !CONTRACT_TERM_IS_FETCHED ||
    !CREDIT_RATINGS_IS_FETCHED ||
    !CONTRACT_ALLOWED_ANNUM_MILEAGE_IS_FETCHED ||
    !PROGRAMS_IS_FETCHED ||
    !ASSETS_BY_TRIM_CODE_IS_FETCHED ||
    !DEALER_FEE_IS_FETCHED ||
    !OPTION_TERMS_IS_FETCHED
  ) {
    return <div className='text-center'>Loading...</div>
  }

  return (
    <context.Provider value={{ state, dispatch }}>
      <div className={`${styles.pricing_detai}`}>
        <div className={styles.detail_overview}>
          <div className="d-flex justify-content-between align-items-start">
            <h2 className="headline-4 regular pe-3">{ASSET.year} {ASSET.make} {ASSET.model} {ASSET.trim_description} {ASSET.body_type}</h2>
          </div>
          <div className={`${styles.arrive_soon} d-flex justify-content-between align-items-center`}>
            <div>
              <p className="order_status_text">
                <i className="order_status_icon"></i>
                At Dealership
                <span className='msrp_tip'></span>
              </p>
            </div>
          </div>

          <div className={styles.price_block}>
            <div className={styles.price_sec}>
              <div className={styles.price_heading_row}>
                <div className={styles.price_heading_box}>
                  <small className="d-flex align-items-center eyebrow-2">
                    MSRP
                    <span className='msrp_tip'></span>
                  </small>
                  <h2 className="headline-7 bold"><Price value={ASSET.internet_price} /></h2>
                </div>
                <div className={styles.verticle_line}></div>
                <div className={styles.price_heading_box}>
                  <small className="d-flex align-items-center eyebrow-2">
                    Estimated Payment
                    <span className='msrp_tip'></span>
                  </small>
                  <h2 className="headline-7 bold">{state.PROGRAM === 'Lease' ? <Price value={state.ESTIMATED_PRICE_LEASE} /> : <Price value={state.ESTIMATED_PRICE_FINANCE} />}<small>/month</small></h2>
                </div>
              </div>

              <div className={styles.incentives}>
                <h3 className="label-1 bold pb-2">
                  <Price value={ASSET.internet_price} /> <span className="content-3 regular">After £0 incentive</span>
                </h3>
                <p className="content-3 regular pb-3"> This estimate includes taxes and other fees. The final sale price will be provided  by Dealer One. </p>
                <Link onClick={() => { dispatch({ type: 'SET_PRICE_DETAIL_MODAL_VISIBILITY', payload: true }) }} className="underline_text">Price Details</Link>
              </div>
            </div>

            <div className={styles.leasingTabs}>
              <Tabs
                onSelect={(x) => dispatch({ type: 'SET_PROGRAM', payload: x })}
                defaultActiveKey={terms ? "Finance": state.PROGRAM}
                id="uncontrolled-tab-example"
                className="mb-3 transparent_bold_tabs"
                justify
              >
                {
                  PROGRAMS?.filter((x) => x.finance_type === 'Lease').length > 0 &&
                  <Tab eventKey="Lease" title="Lease" >
                    <div className={styles.leaseForm}>
                      <div className="d-flex flex-wrap align-items-center pb-3">
                        <div className="col-lg-5">
                          <span className="acc_title mb-0 pe-2">LEASE LENGTH</span>
                        </div>
                        <div className="col-lg-7">
                          <Form.Select value={state.SELECTED_CONTRACT_TERM} onChange={(x) => {
                            dispatch({
                              type: 'SET_CONTRACT_TERM',
                              payload: {
                                TERM: x.target.value,
                                TERM_ID: x.target.options[x.target.selectedIndex].getAttribute('data-id')
                              }
                            })
                            CALLCULATIN_CALL({ SELECTED_CONTRACT_TERM: x.target.value })
                          }}>
                            <option value={0} data-id={0} disabled>Select</option>
                            {
                              CONTRACT_TERM?.filter((x) => x.is_active === true).map((item, index) => {
                                return (
                                  <option key={index} value={item.term} data-id={item.id}>{item.term} Months</option>
                                )
                              })
                            }
                          </Form.Select>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap align-items-center pb-3">
                        <div className="col-lg-5">
                          <span className="acc_title mb-0 pe-2 d-flex align-items-center">
                            CREDIT SCORE
                            <span className='msrp_tip'>
                            </span>
                          </span>
                        </div>
                        <div className="col-lg-7">
                          <Form.Select value={state.CREDIT_RATING_ID} onChange={(x) => {
                            dispatch({
                              type: 'SET_CREDIT_SCORE',
                              payload: {
                                CREDIT_RATING_ID: x.target.value,
                                CREDIT_RATING: x.target.options[x.target.selectedIndex].getAttribute('data-desc')
                              }
                            })
                          }}>
                            <option value={0} data-desc={''} disabled>Select</option>
                            {
                              CREDIT_RATINGS?.filter((x) => x.is_active === true).map((item, index) => {
                                return (
                                  <option key={index} value={item.id} data-desc={item.description}>{item.description} ({item.score_from} - {item.score_to})</option>
                                )
                              })
                            }
                          </Form.Select>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap align-items-center pb-3">
                        <div className="col-lg-5">
                          <span className="acc_title mb-0 pe-2">LEASE Miles</span>
                        </div>
                        <div className="col-lg-7">
                          <Form.Select value={state.MILEAGE} onChange={(x) => {
                            dispatch({
                              type: 'SET_LEASE_MILES',
                              payload: {
                                MILEAGE: x.target.value,
                                MILEAGE_ID: x.target.options[x.target.selectedIndex].getAttribute('data-id')
                              }
                            })
                          }}>
                            <option value={0} disabled>Select</option>
                            {
                              CONTRACT_ALLOWED_ANNUM_MILEAGE?.filter((x) => x.is_active === true).map((item, index) => {
                                return (
                                  <option key={index} value={item.value} data-id={item.id} >{item.value}</option>
                                )
                              })
                            }
                          </Form.Select>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap align-items-center pb-3">
                        <div className="col-lg-5">
                          <span className="acc_title mb-0 pe-2 d-flex align-items-center">
                            CASH DOWN
                            <span className='msrp_tip'>
                            </span>
                          </span>
                        </div>
                        <div className="col-lg-7">
                          <Form.Control type="text" placeholder="Cash Down" value={state.LEASE_DOWN_PAYMENT_AMOUNT}
                            onChange={(x) => {
                              dispatch({
                                type: 'SET_CHANGED_LEASE_DOWN_PAYMENT',
                                payload: {
                                  LEASE_DOWN_PAYMENT_AMOUNT: x.target.value ? parseFloat(x.target.value) : 0,
                                  LEASE_DOWN_PAYMENT_PERCENTAGE: x.target.value ? parseFloat(x.target.value) * 100 / state.MSRP : 0
                                }
                              })
                            }}
                            onBlur={(x) => {
                              CALLCULATIN_CALL({ LEASE_DOWN_PAYMENT_AMOUNT: x.target.value ? parseFloat(x.target.value) : 0 })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Tab>
                }

                {
                  PROGRAMS?.filter((x) => x.finance_type === 'Finance').length > 0 &&
                  <Tab eventKey="Finance" title="Finance">
                    <div className={styles.leaseForm}>
                      <div className="d-flex flex-wrap align-items-center pb-3">
                        <div className="col-lg-5">
                          <span className="acc_title mb-0 pe-2 d-flex align-items-center">FINANCING TERM
                            <span className='msrp_tip'>

                            </span>
                          </span>
                        </div>
                        <div className="col-lg-7">
                          <Form.Select value={terms? terms : state.SELECTED_CONTRACT_TERM} onChange={(x) => {
                            dispatch({
                              type: 'SET_CONTRACT_TERM',
                              payload: {
                                TERM: x.target.value,
                                TERM_ID: x.target.options[x.target.selectedIndex].getAttribute('data-id')
                              }
                            })
                            CALLCULATIN_CALL({ SELECTED_CONTRACT_TERM: x.target.value })
                          }}>
                            <option value={0} data-id={0} disabled>Select</option>
                            {
                              CONTRACT_TERM?.filter((x) => x.is_active === true).map((item, index) => {
                                return (
                                  <option key={index} value={item.term} data-id={item.id}>{item.term} Months</option>
                                )
                              })
                            }
                          </Form.Select>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-center pb-3">
                        <div className="col-lg-5">
                          <span className="acc_title mb-0 pe-2 d-flex align-items-center">CREDIT SCORE
                            <span className='msrp_tip'>
                            </span>
                          </span>
                        </div>
                        <div className="col-lg-7">
                          <Form.Select value={state.CREDIT_RATING_ID} onChange={(x) => {
                            dispatch({
                              type: 'SET_CREDIT_SCORE',
                              payload: {
                                CREDIT_RATING_ID: x.target.value,
                                CREDIT_RATING: x.target.options[x.target.selectedIndex].getAttribute('data-desc')
                              }
                            })
                          }}>
                            <option value={0} disabled>Select</option>
                            {
                              CREDIT_RATINGS?.filter((x) => x.is_active === true).map((item, index) => {
                                return (
                                  <option key={index} value={item.id} data-desc={item.description}>{item.description} ({item.score_from} - {item.score_to})</option>
                                )
                              })
                            }
                          </Form.Select>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap align-items-center pb-3">
                        <div className="col-lg-5">
                          <span className="acc_title mb-0 pe-2 d-flex align-items-center">DOWN PAYMENT
                            <span className='msrp_tip'>
                            </span>
                          </span>
                        </div>
                        <div className="col-lg-7">
                          <Form.Control type="text" placeholder="Down Payment" value={state.FINANCE_DOWN_PAYMENT_AMOUNT}
                            onChange={(x) => {
                              dispatch({
                                type: 'SET_CHANGED_FINANCE_DOWN_PAYMENT',
                                payload: {
                                  FINANCE_DOWN_PAYMENT_AMOUNT: x.target.value ? parseFloat(x.target.value) : 0,
                                  FINANCE_DOWN_PAYMENT_PERCENTAGE: x.target.value ? parseFloat(x.target.value) * 100 / state.MSRP : 0
                                }
                              })
                            }}
                            onBlur={(x) => {
                              CALLCULATIN_CALL({ FINANCE_DOWN_PAYMENT_AMOUNT: x.target.value })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Tab>
                }
              </Tabs>
            </div>
          </div>

          <div className={styles.moreWays_main}>
            <p className="label-2 bold uppercase text-center pb-4">More ways to personalize</p>
            <div className={styles.moreways_accordian_box}>
              <MoreWaysAccordian recalculate={() => CALLCULATIN_CALL({})} />
            </div>
          </div>

          <div className={styles.start_checkout_sec}>
            <div className={styles.checkout_title}>
              <div className='d-flex flex-wrap align-items-start justify-content-between'>
                <p className='label-2 regular pe-2'>Estimated Payment: <strong className='bold'>{state.PROGRAM === 'Lease' ? <Price value={state.ESTIMATED_PRICE_LEASE} /> : <Price value={state.ESTIMATED_PRICE_FINANCE} />}</strong> /month </p>
                <Link onClick={() => { dispatch({ type: 'SET_PRICE_DETAIL_MODAL_VISIBILITY', payload: true }) }} className="underline_text color_black">Price Details</Link>
              </div>
            </div>
            <div className={styles.checkout_btn_row}>
              <Link onClick={() => { dispatch({ type: 'SET_MODAL_VISIBILITY', payload: true }) }} className='d-block mb-1'>
                <Button className='checkout_btn' variant="primary">
                  <ShoppingCartOutlined />
                  <span>Start Checkout</span>
                  <i className='checkout_btn_help'>
                    <HelpOutlineOutlined />
                  </i>
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <PreQualifySecondsModel
          asset={ASSET}
          showModal={state.MODAL_STATE}
          showStep='step2'
          DISABLE_ON_SAVE={SAVE_QUOTATION_IS_LOADING}
          handleCloseModal={() => { dispatch({ type: 'SET_MODAL_VISIBILITY', payload: false }) }}
        />

        <PricingDetailModal
          showModal={state.PRICE_DETAIL_MODAL_VISIBILITY}
          handleCloseModal={() => dispatch({ type: 'SET_PRICE_DETAIL_MODAL_VISIBILITY', payload: false })}
          ASSET={ASSET}
          PROGRAMS={PROGRAMS}
        />
      </div>
    </context.Provider>
  )
}

export default PricingDetail;
