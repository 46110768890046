import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, Input } from '@mui/material'
import clsx from 'clsx'
import uploadIcon from '../../assets/images/upload-icon.svg';

const CustomFileUpload = styled('div')(({ theme }) => ({
  '&.u-custom-file-upload': {
    position: 'relative',
    marginBottom: 24
  }
}))

const BootstrapFileUploadInput = styled(Input)(({ theme }) => ({
  '&.file-drag-input-hidden': {
    position: 'absolute',
    opacity: 0,
    width: '100%',
    height: '100%',
    display: 'none',
    input: {
      width: '100%',
      height: '100%',
      opacity: 0,
      padding: 0
    }
  }
}))

const BootstrapFileUploadLabel = styled('div')(() => ({
  '&.root-drag-file': {
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dashed #dee2e6',
    height: 224,
    '.file-upload-label': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      // '.text-primary': {
      //   color: theme.palette.primary.main
      // }
    },
    '&.is-loading': {
      height: 'auto',
      // border: '1px solid' + theme.palette.grey[100],
      textAlign: 'left',
      cursor: 'auto',
      '.file-upload-label': {
        justifyContent: 'flex-start',
        cursor: 'auto',
        '.file-uploading-wrap': {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          padding: 16,
          '.loader-wrap': {
            width: 24,
            height: 24,
            marginRight: 16,
            '.loader': {
              position: 'relative',
              top: 3,
              transform: 'none',
              lineHeight: '24px'
            }
          },
          '.loading-label': {
            flexGrow: 1,
            marginRight: 16,
            // fontWeight: theme.typography.fontWeightMedium
          },
          '.file-del': {
            flexShrink: 0,
            cursor: 'pointer',
            // path: {
            //   stroke: theme.palette.grey[500]
            // }
          }
        }
      }
    }
  },

  '.file-drag-nomouse-event': {
    pointerEvents: 'none',
    '.file-drag-icon-text': {
      // color: theme.palette.grey[900],
      svg: {
        marginBottom: 24
      },
      // '.MuiTypography-root': {
      //   fontWeight: theme.typography.fontWeightMedium,
      //   fontFamily: theme.typography.fontFamily
      // },
      // '.text-primary': {
      //   color: theme.palette.primary.main
      // }
    }
  },

  '&.error': {
    // border: '2px dashed' + theme.palette.error.main,
    '.file-upload-label': {
      '.file-drag-nomouse-event': {
        '.file-drag-icon-text': {
          // color: theme.palette.error.main,
          // '.text-primary': {
          //   color: theme.palette.error.main
          // }
        }
      }
    }
  },

  '&.disabled': {
    opacity: '0.4',
    pointerEvents: 'none'
  }

}))

const FileDragandDrop = ({
  theme,
  accept,
  imageButton = false,
  hoverLabel = 'Click or drag to upload file',
  dropLabel = 'Drop file here',
  error,
  disabled,
  isLoading,
  loadingLabel = 'Uploading file...',
  image: {
    url = '',
    imageStyle = {
      height: 'inherit'
    }
  } = {},
  allowMultiple = false,
  onChange,
  onDrop,
  id = 'file-upload',
  name,
  onClickIcon
}) => {
  const [fileList, setFileList] = React.useState([])
  const [imageUrl, setImageUrl] = React.useState(url)
  const [imageFileName, setImageFileName] = React.useState('')
  const [imageFileSize, setImageFileSize] = React.useState('')
  const [labelText, setLabelText] = React.useState(hoverLabel)
  const [isDragOver, setIsDragOver] = React.useState(false)
  const [isMouseOver, setIsMouseOver] = React.useState(false)
  const stopDefaults = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true)
    },
    onMouseLeave: () => {
      setIsMouseOver(false)
    },
    onDragEnter: (e) => {
      stopDefaults(e)
      setIsDragOver(true)
      setLabelText(dropLabel)
    },
    onDragLeave: (e) => {
      stopDefaults(e)
      setIsDragOver(false)
      setLabelText(hoverLabel)
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e)
      setLabelText(hoverLabel)
      setIsDragOver(false)

      if (e?.dataTransfer?.files) {
        setImageUrl(URL.createObjectURL(e.dataTransfer.files[0]))
      }
      onDrop(e)
    }
  }

  const handleChange = (event) => {
    if (event && event?.target && ((event?.target?.files) != null) && event?.target?.files) {
      setImageUrl(URL.createObjectURL(event?.target?.files[0]))
    }
    onChange(event)
  }

  return (
    <CustomFileUpload theme={theme} className="u-custom-file-upload">
      <>
        {isLoading
          ? <></>
          : <BootstrapFileUploadInput
            theme={theme}
            onChange={handleChange}
            // onClick={(event) => {
            //   event.target?.value = null
            // }}
            onClick={() => {
            }}
            // accept={accept}
            className={'file-drag-input-hidden'}
            id={id}
            name={name}
            type="file"
            inputProps={{ multiple: allowMultiple }}
          />
        }
      </>

      <BootstrapFileUploadLabel
        theme={theme}
        {...dragEvents}
        className={clsx('root-drag-file', isDragOver && 'root-drag-file-dragover', error && 'error', disabled && 'disabled', isLoading && 'is-loading')}
      >
        <label htmlFor={id} className="file-upload-label">
          <>
            {isLoading
              ? <Box className="file-uploading-wrap">
                <Typography className="loading-label" display={'block'} variant='body2' component='span'>{loadingLabel}</Typography>
              </Box>
              : <Box className={'file-drag-nomouse-event'} >
                {imageButton && (
                  <Box position="absolute">
                    <img
                      alt="file upload"
                      src={imageUrl}
                      style={imageStyle}
                      width={'100px'}
                      height={'100px'}
                    />
                  </Box>
                )}

                {(!imageButton || isDragOver || isMouseOver) && (
                  <>
                    <Box className={'file-drag-icon-text'}>
                      <img src={uploadIcon} />
                      <Typography display={'block'} variant='body2' component='span'>{labelText}</Typography>
                    </Box>
                  </>
                )}
              </Box>}
          </>
        </label>
      </BootstrapFileUploadLabel>

    </CustomFileUpload>
  )
}

export default FileDragandDrop