import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import { styled } from "@mui/material";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import SignatureCanvas from "react-signature-canvas";
import { useSaveCustomerSignatures, useGetCustomerSignatures } from '../../hooks'
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";


const SignatureCanvasUi = styled("div")(() => ({
  ".signature-canvas": {
    width: "100%",
    height: 150,
  },
}));

const DocViewerUi = styled("div")(() => ({
  "&.doc-viewer-ui": {
  display: "none"
},
".doc-viewer": {
  width: "100%",
  height: "63vh",
}
}));

function DocumentView({ document, setIsScrolledToBottom }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerRefId = searchParams.get('customerRefId');

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // const docs = Object.keys(document ?? {}).length > 0 ? [
  //   {
  //     uri: document?.document_signed_url ?? document?.document_raw_url,
  //   }
  // ] : [];
  
  const signatureCanvas = useRef();
  const initialCanvas = useRef();
  const containerRef = useRef(null);
  const [isSignatureFilled, setIsSignatureFilled] = useState(false);
  const [isInitialFilled, setIsInitialFilled] = useState(false);

  const { data: signatures } = useGetCustomerSignatures(customerRefId)
  const { mutate: saveCustomerSignature, isLoading: savingCustomerSignature } = useSaveCustomerSignatures()

  const handleEndSignature = () => {
    if (signatureCanvas.current.isEmpty()) {
      setIsSignatureFilled(false)
    } else {
      setIsSignatureFilled(true)
    }
  };

  const handleEndInitial = () => {
    if (initialCanvas.current.isEmpty()) {
      setIsInitialFilled(false)
    } else {
      setIsInitialFilled(true)
    }
  };
  
  const handleClearSignature = () => {
    if (signatureCanvas && signatureCanvas.current) {
      signatureCanvas.current.clear();
      setIsSignatureFilled(false)
    }
      if (initialCanvas && initialCanvas.current) {
        initialCanvas.current.clear();
        setIsInitialFilled(false)
    }
  };

  useEffect(() => {
    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
          const isBottom = container.scrollHeight - Math.ceil(container.scrollTop) <= container.clientHeight
          if (isBottom) {
            setIsScrolledToBottom(true);
          }
        }
      };
    
      // Add a scroll event listener to the container element
      const container = containerRef.current;
      if (container) {
        container.addEventListener('scroll', handleScroll);
      }

      // Clean up the event listener when the component unmounts
      return () => {
      if (container) {
          container.removeEventListener('scroll', handleScroll);
      }
      };
  }, [setIsScrolledToBottom]);


  useEffect(() => {
    if (signatures) {
      if (signatures?.signature_image) { 
        signatureCanvas.current.fromDataURL(signatures.signature_image)
        setIsSignatureFilled(true)
      } 
      if (signatures.initial_image) {
        initialCanvas.current.fromDataURL(signatures.initial_image)
        setIsInitialFilled(true)
      }
    }
  }, [signatures]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleShow = () => {
    // setShow(true)
    // handleShowDoc()
    if (signatureCanvas && signatureCanvas.current) {
      if (!signatureCanvas.current.isEmpty()) {
        const signature = signatureCanvas.current.toDataURL()
        const initial = initialCanvas.current.toDataURL()

        saveCustomerSignature(
        {
          signature_image: signature,
          initial_image: initial,
          reference_id: customerRefId
        }
      )
      }
    }
  };

  return (
    <>
      <SignatureCanvasUi className="signature-canvas-ui">
        <h3 className='headline-6 regular mb-2'>
          <span className="text-secondary">Signature:</span>
        </h3>
        <div className="border mb-3">
        <SignatureCanvas
          canvasProps={{ className: "signature-canvas" }}
            ref={signatureCanvas}
            onEnd={handleEndSignature}
          />
        </div>
        <h3 className='headline-6 regular mb-2'>
         <span className="text-secondary">Initials:</span>
        </h3>
        <div className="border mb-3">
          <SignatureCanvas
          canvasProps={{ className: "signature-canvas" }}
            ref={initialCanvas}
            onEnd={handleEndInitial}
          />
        </div>
        <Container className="p-0">
          <Row>
            <Col>
              <Button
                className="w-100"
                variant="primary"
                onClick={handleClearSignature}
                disabled={savingCustomerSignature || !(isSignatureFilled || isInitialFilled)}
              >
              Clear Signature
            </Button>
            </Col>
            <Col>
              <Button
                className="w-100"
                variant="primary"
                onClick={handleShow}
                disabled={savingCustomerSignature || !(isSignatureFilled && isInitialFilled)}
              >
              Confirm Signature
            </Button>
            </Col>
          </Row>
        </Container>
      </SignatureCanvasUi>

       <div
        ref={containerRef}
        style={{ height: '475px'}}
        className="hidden-scroll"
      >
        <DocViewerUi className="doc-viewer-ui">
          {/* <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docs}
            className="doc-viewer mb-3"
            config={{
              header: {
                disableFileName: true,
              },
              pdfVerticalScrollByDefault: true,
            }}
          /> */}
         <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
            {/* <div
              style={{
                height: "750px",
                maxWidth: "900px",
                marginLeft: "auto",
                marginRight: "auto"
              }}
            > */}
              <Viewer
                // fileUrl="https://unity-generated-documents.s3.amazonaws.com/BMW_Financial_Service_NAac61558e-799f-4250-a0a1-b29bcc3c6c79.pdf"
                fileUrl={document?.document_signed_url ?? document?.document_raw_url ?? ''}
                plugins={[defaultLayoutPluginInstance]}
              />
            {/* </div> */}
          </Worker>
          <Button className="w-100" variant="primary">
            Confirm
          </Button>
        </DocViewerUi>
      </div>
    </>
  );
}

export default React.memo(DocumentView);
