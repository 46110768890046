import { Button, Form, OverlayTrigger, Tab, Tabs } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { Logout } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import badge from '../../assets/images/kelley_blueBook.png';
import img1 from '../../assets/images/lucid-white-trade.png';
import vehicleImges from '../../assets/images/Trade-in vehicle.jpg';
import badge2 from '../../assets/images/kelley_blueBook_text.png';

import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

const TradeInOptions = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')
    const link = `${location.pathname}${location.search}`

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);

    const [isDisabledNext, setIsDisabledNext] = useState(true);

    const [selectedOption, setSelectedOption] = useState('');

    const [isStep, setIsStep] = useState('basicTabs');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        console.log(selectedOption)
    };

    const handleStep = (e) => {
        setIsStep(e);
        console.log(isStep);
        if (isStep === 'step2_finalQuestion') {
            setIsDisabledNext(false);
        }
    };

    return (
        <div>
            <div className="digital_checkout_wrapper">

                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">

                        <div className="po_header pb-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>

                        <div className='tradeoptions_content po_content_height'>

                            {
                                isStep === 'step3_yourOffer' ? '' :
                                    <>
                                        <div className='d-flex justify-content-between'>
                                            <div className='po_headlines'>
                                                <h3 className='headline-5 light pb-1 d-flex align-items-center'>Trade-In Options <span className='msrp_tip'>

                                                </span></h3>
                                                <p className='label-2 light pb-3'>Choose one that applies to you. If you do not have a trade-in you can continue to the next step.</p>
                                            </div>


                                        </div>

                                        <div className='tradeOpt_topRadios pb-4'>
                                            <h3 className='headline-6 light pb-3'>01 Do You Have a Trade-In?</h3>
                                            <div className='d-flex flex-wrap'>
                                                <div className='pe-5'>
                                                    <label className='d-flex align-items-center content-3 bold black'>
                                                        <Form.Check type="radio" name='TradeInTop' aria-label="radio 1"
                                                            value="option1"
                                                            checked={selectedOption === 'option1'}
                                                            onChange={handleOptionChange} />
                                                        <span className='ps-2'>Yes, I Have a Trade-In</span></label>
                                                </div>
                                                <div>
                                                    <label className='d-flex align-items-center content-3 bold black'>
                                                        <Form.Check type="radio" name='TradeInTop' aria-label="radio 1"
                                                            value="option2"
                                                            checked={selectedOption === 'option2'}
                                                            onChange={handleOptionChange} />
                                                        <span className='ps-2'>No, I Don't Have a Trade-In</span></label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }

                            {/* Do You Have a Trade-In switch */}
                            {
                                selectedOption === 'option1' ?
                                    <>
                                        {/* License Plate box */}
                                        {
                                            isStep === 'basicTabs' ?
                                                <div className='tradeOpt_vintabs mb-4'>
                                                    <Tabs defaultActiveKey="LicensePlate" className='pfi_filled_tabs' fill>
                                                        <Tab eventKey="LicensePlate" title="License Plate">
                                                            <div className='tradeOpt_vintabs_content p-3'>
                                                                <div className='row'>
                                                                    <div className='col-md-7 pb-3'>
                                                                        <span className='acc_title mb-2'> License Plate Number: </span>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                    <div className='col-md-5 pb-3'>
                                                                        <span className='acc_title mb-2'>Region</span>
                                                                        <Form.Control type="text" />
                                                                        {/* <Form.Select className='regular' aria-label="Default select example">
                                                                            <option>State</option>
                                                                            <option value="1">California</option>
                                                                            <option value="2">New York</option>
                                                                            <option value="3">Texas</option>
                                                                            <option value="3">Tennessee</option>
                                                                        </Form.Select> */}
                                                                    </div>
                                                                </div>

                                                                <div className='row'>
                                                                    <div className='col-md-7 pb-3'>
                                                                        <span className='acc_title mb-2'> Mileage </span>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                    <div className='col-md-5 pb-3'>
                                                                        <span className='acc_title mb-2'>Post Code</span>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex align-items-end justify-content-end pt-2'>
                                                                    <Button onClick={(e) => { handleStep("step1_basicInfo") }} variant='dark'>Save & Continue</Button>
                                                                </div>

                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="VIN#" title="VIN #">
                                                            <div className='tradeOpt_vintabs_content p-3'>
                                                                <div className='row'>
                                                                    <div className='col-md-12 pb-3'>
                                                                        <span className='acc_title mb-2'> VIN NUMBER: </span>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-end justify-content-end pt-2'>
                                                                    <Button variant='dark'>Save & Continue</Button>
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                        <Tab eventKey="ManualInput" title="Manual Input">
                                                            <div className='tradeOpt_vintabs_content p-3'>
                                                                <div className='row'>
                                                                    <div className='col-md-7 pb-3'>
                                                                        <span className='acc_title mb-2'> MAKE </span>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                    <div className='col-md-5 pb-3'>
                                                                        <span className='acc_title mb-2'>YEAR</span>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                </div>

                                                                <div className='row'>
                                                                    <div className='col-md-7 pb-3'>
                                                                        <span className='acc_title mb-2'> MODEL </span>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                    <div className='col-md-5 pb-3'>
                                                                        <span className='acc_title mb-2'>MILEAGE</span>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex align-items-end justify-content-end pt-2'>
                                                                    <Button variant='dark'>Save & Continue</Button>
                                                                </div>
                                                            </div>
                                                        </Tab>
                                                    </Tabs>
                                                </div>
                                                :
                                                ''
                                        }
                                        {/* your vehicle box */}

                                        {
                                            isStep === 'step1_basicInfo' ?
                                                <>
                                                    <div className='tradeOpt_vintabs tradeOpt_yourVehBox mb-4'>
                                                        <div className='tradeOpt_yourVehBox_title'>
                                                            <span>Your Vehicle</span>
                                                        </div>

                                                        <div className='tradeOpt_yourVehBox_content p-3'>
                                                            <div className='d-flex justify-content-end pb-2'>
                                                                <Link to={link} className='underline_text regular'>Edit</Link>
                                                            </div>
                                                            <div className='row align-items-center'>
                                                                <div className='col-md-5 '>
                                                                    <span className='pe-3 d-block'>
                                                                        <img src={vehicleImges} alt='#'></img>
                                                                    </span>
                                                                </div>
                                                                <div className='col-md-7'>
                                                                    <h2 className='headline-5 bold'>2021 C Class - C 260</h2>
                                                                    <p className='content-2--bold'>Trim: C 260</p>
                                                                    <p className='content-2--bold'>VIN: ABC12345678901234 </p>
                                                                    <p className='content-2--bold'>Color: White </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='tradeOpt_info_sec mb-4'>
                                                        <div className='tradeOpt_info_title pb-4'>
                                                            <h2 className='headline-6 light pb-1'>02 Enter The Basics</h2>
                                                            <p className='label-2 light'>Provide some more details of the trade-in.</p>
                                                        </div>
                                                        <div className='tradeOpt_info_box'>
                                                            <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>What is the Color of your Vehicle?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <Form.Control type="text" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>Transmission Type?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='tt' />
                                                                            Automatic 8-Speed
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='tt' />
                                                                            Manual 8-Speed
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>Premium Features?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            Leather Seats
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            Weather Packages
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            Powered Tailgate
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            Audio and Navigation
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            Heated Mirrors
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            Cruise Control
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-end justify-content-end pt-2'>
                                                                <Button onClick={(e) => { handleStep("step2_finalQuestion") }} variant='dark'>Save & Continue</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                ''
                                        }

                                        {
                                            isStep === 'step2_finalQuestion' ?
                                                <>
                                                    <div className='tradeOpt_vintabs tradeOpt_yourVehBox mb-4'>
                                                        <div className='tradeOpt_yourVehBox_title'>
                                                            <span>Your Vehicle</span>
                                                        </div>

                                                        <div className='tradeOpt_yourVehBox_content p-3'>
                                                            <div className='d-flex justify-content-end pb-2'>
                                                                <Link to={link} className='underline_text regular'>Edit</Link>
                                                            </div>
                                                            <div className='row align-items-center'>
                                                                <div className='col-md-5 '>
                                                                    <span className='pe-3 d-block'>
                                                                        <img src={vehicleImges} alt='#'></img>
                                                                    </span>
                                                                </div>
                                                                <div className='col-md-7'>
                                                                    <h2 className='headline-5 bold'>2021 Altima SR AWD </h2>
                                                                    <p className='content-2--bold'>Trim: SR AWD</p>
                                                                    <p className='content-2--bold'>VIN: ABC12345678901234 </p>
                                                                    <p className='content-2--bold'>Color: Black </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='tradeOpt_info_sec mb-4'>
                                                        <div className='tradeOpt_info_title pb-4'>
                                                            <h2 className='headline-6 light pb-1'>03 Few Final Questions</h2>
                                                            <p className='label-2 light'>Provide some more details of the trade-in.</p>
                                                        </div>
                                                        <div className='tradeOpt_info_box'>
                                                            <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>What is the condition of your vehicle?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='Great' />
                                                                            <span>
                                                                                Great
                                                                                <small className='d-block'>It looks like it's never been driven (13% of vehicle)</small>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='Great' />
                                                                            <span>
                                                                                Good
                                                                                <small className='d-block'>It has been well taken care of with a few
                                                                                    scratches or scrapes (52% of vehicle)</small>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='Great' />
                                                                            <span>
                                                                                Fair
                                                                                <small className='d-block'>Has been in an accident or had some damage
                                                                                    however has been fixed. Visible scratches or
                                                                                    dents (28% of vehicle)</small>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='Great' />
                                                                            <span>
                                                                                Poor
                                                                                <small className='d-block'>There is damage to the vehicle and some
                                                                                    components are not working (17% of vehicle)</small>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>Are there active working lights?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='ttlights' />
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='ttlights' />
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>Has the vehicle been in an accident?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='ttaccident' />
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='ttaccident' />
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>Has this vehicle been smoked in?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='ttsmoked' />
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='ttsmoked' />
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>Is there a loan or lease  on vehicle?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-4 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            Loan
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-4 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            Lease
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-4 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='pfeatures' />
                                                                            None
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='tradeOpt_info_row'>
                                                                <h3 className='content-2--regular-grey pb-3'>Are the spare keys available?</h3>
                                                                <div className='row'>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='areavailable' />
                                                                            Yes
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-md-6 pb-4'>
                                                                        <label className='tradeOpt_info_check'>
                                                                            <Form.Check type="radio" name='areavailable' />
                                                                            No
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex align-items-end justify-content-end pt-2'>
                                                                <Button onClick={(e) => { handleStep("step3_yourOffer") }} variant='dark'>Save & Continue</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                ''
                                        }


                                        {/* end tag of top check box yes radio */}
                                    </>
                                    :
                                    ''
                            }

                            {/* last step your offer section */}

                            {
                                isStep === 'step3_yourOffer' ?
                                    <>
                                        <div className='d-flex justify-content-between'>
                                            <div className='po_headlines'>
                                                <h3 className='headline-5 light pb-1 d-flex align-items-center'>Your Offer</h3>
                                                <p className='label-2 light'>This is a penny-perfect offer; assuming that the vehicle is in the condition <br /> as you described it this is what you will be  given for your trade-in.</p>
                                                <h2 className='headline-4 color_blue semiBold'>£ 7,150</h2>
                                                <p className='pb-4'><Link className='label-1 underline blue_hov semiBold'>Find Out Why Your Offer Is What it is +</Link></p>
                                            </div>
                                            {/* <div className='to_bb_badge ps-3'>
                                                <span><img src={badge} alt="#"></img></span>
                                            </div> */}
                                        </div>

                                        <div className='tradeOpt_vintabs tradeOpt_yourVehBox mb-4'>
                                            <div className='tradeOpt_yourVehBox_title'>
                                                <span>Your Vehicle</span>
                                            </div>

                                            <div className='tradeOpt_yourVehBox_content p-3'>
                                                <div className='row align-items-center'>
                                                    <div className='col-md-5 '>
                                                        <span className='pe-3 d-block'>
                                                            <img src={vehicleImges} alt='#'></img>
                                                        </span>
                                                    </div>
                                                    <div className='col-md-7'>
                                                        <div className='d-flex justify-content-between pb-2'>
                                                            {/* <span className='step3_yourOffer-logo pe-3'>
                                                                <img src={badge2} alt="#"></img>
                                                            </span> */}
                                                            <Link to={link} className='underline_text regular'>Edit</Link>
                                                        </div>
                                                        <h2 className='headline-5 bold'>2021 C Class - C 260</h2>
                                                        <p className='content-2--bold'>Trim: C 260</p>
                                                        <p className='content-2--bold'>VIN: ABC12345678901234 </p>
                                                        <p className='content-2--bold'>Color: White </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    ''
                            }

                        </div>
                        <DigitalCheckoutFooter pageUrl={`/IncentivesAndDiscounts?orderId=${orderID}&customerRefId=${customerRefId}`} disabled={isDisabledNext} />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default TradeInOptions;