import { styled } from '@mui/material/styles'

export const CustomImageUploader = styled('div')(({ theme }) => ({
    marginBottom: 24,
    position: 'relative',
    '.img-wrap': {
        backgroundColor: '#fff',
        width: '100%',
        height: 224,
        display: 'flex',
        verticalAlign: 'middle',
        textAlign: 'center',
        border: '2px dashed #dee2e6',
        overflow: 'hidden',

        img: {
            maxWidth: '100%',
            maxHeight: '100%',
            width: 'auto',
            height: 'auto',
            display: 'block',
            margin: 'auto'
        },

        '.btn-close': {
            backgroundColor: '#fff',
            padding: '0 !important',
            position: 'absolute',
            top: 10,
            right: 10,
            height: 26,
            width: 26,
            border: '1px solid #dee2e6',
            borderRadius: '100%',
        }
    }
}))
