import { Add, Check } from "@mui/icons-material";
import React, { useState } from 'react';

function PlusToggleBtn(props) {
    console.log(props?.setActiveClass);
    const [isActivePlus, setIsActivePlus] = useState(false);

    // Function to toggle the class
    const handlePlusClick = () => {
        setIsActivePlus(!isActivePlus);
    };
    return (
        <span className={`iad_card_add_btn ${isActivePlus ? 'active' : ''}`} onClick={handlePlusClick}>
            {
                isActivePlus ? <Check /> : <Add />
            }

        </span>
    )
}

export default PlusToggleBtn;