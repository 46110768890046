import { Modal, Tab, Tabs } from 'react-bootstrap';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import chargingCarImg from "../../assets/images/BMW-2021-EV-Page-Charging-ALL.avif";
import electricImg from "../../assets/images/All-Electric-Identifier_Icon_2000.png";
import fututreImg from "../../assets/images/BMW-SOC24-i5-GlobalNav.avif";
import iGreyImg from "../../assets/images/BMW_i_Grey-Colour_RGB.png";
import mImg from "../../assets/images/BMW_M_White-Colour_RGB.webp";

const Models = ({ show, handleClose }) => {

    const [activeKey, setActiveKey] = useState('Electrified');

    const handleTabSelect = (eventKey) => {
        setActiveKey(eventKey);
        console.log(`Active tab is now: ${activeKey}`);
        // You can perform additional actions based on the active tab
    };

    //BMWM
    return (
        <div>
            <Modal show={show} onHide={handleClose} size="xxl" className={`w-100 models_popup ${activeKey === "BMWM" ? 'models_popup_dark' : ''}`}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="hm_models_auto">
                        <div className='modelTabs'>
                            <Link to='/' className='inventory_btn btn' onClick={handleClose} title="Shop Inventory">Shop Inventory</Link>
                            <Tabs
                                activeKey={activeKey}
                                onSelect={handleTabSelect}
                                defaultActiveKey="All Models"
                                id="uncontrolled-tab-example"
                                className="mb-3 transparent_bold_tabs"
                                justify
                            >
                                <Tab eventKey="All Models" title="All Models">
                                    <div className="hm_models_section">
                                        <div className="hm_models_heading">
                                            <h1>All Models</h1>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="X1">
                                                        <img alt="X1" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=23XB&client=byo&paint=P0C66&fabric=FKHSW&sa=S01ED,S02TF,S0302,S03AT,S03MB,S0420,S0459,S04AT,S05A4,S05AC,S05AS,S05DM,S06AC,S06AK,S06C4,S06CP,S06WC,S0775&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=600&width=900&width=450 " />
                                                        <b>X1</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X3">
                                                        <img alt="X3" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XQ&client=byo&paint=P0A90&fabric=FKHSW&sa=S01X8,S0255,S02TB,S0302,S0319,S03AG,S0420,S0423,S0430,S0431,S0459,S0481,S0493,S04K1,S04U0,S04UR,S0508,S0534,S0552,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U2,S0775&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>X3</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X4">
                                                        <img alt="X4" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XV&client=byo&paint=P0C4P&fabric=FKHSW&sa=S01CE,S01VU,S02NH,S02TB,S02VF,S0302,S0319,S0322,S03AG,S03DZ,S0402,S0420,S0423,S0430,S0459,S0481,S0493,S04AW,S04KN,S04U0,S04UR,S0534,S0552,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U3,S0710,S0715,S0760,S0775&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>X4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X5">
                                                        <img alt="X5" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XO&client=byo&paint=P0300&fabric=FKPSW&sa=S01CE,S01SF,S0255,S02TB,S0302,S0319,S0322,S03AT,S03MB,S0402,S0420,S0423,S0459,S0481,S0494,S04FL,S04KR,S04T8,S04UR,S05AC,S05AS,S05DM,S0676,S06AC,S06AK,S06C4,S06CP,S06NX,S06U2,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>X5</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X6">
                                                        <img alt="X6" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XL&client=byo&paint=P0300&fabric=FKPSW&sa=S01CE,S01SF,S0255,S0302,S0319,S0322,S03MB,S0402,S0420,S0423,S0459,S0481,S0494,S04KM,S04T8,S04UR,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U3,S0775,S07HW&quality=70&bkgnd=transparent&resp=png&width=560&angle=60&width=900&width=450&width=450" />
                                                        <b>X6</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X7">
                                                        <img alt="X7" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SA&client=byo&paint=P0C55&fabric=FKPSW&sa=S01CE,S01XK,S0255,S02TB,S0302,S0319,S0322,S03AT,S03MB,S0418,S0420,S0423,S0456,S04FL,S04HB,S04KR,S04UR,S05AC,S05AS,S05DM,S0676,S06AC,S06AK,S06C4,S06NX,S06U3&quality=70&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>X7</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="2">
                                                        <img alt="2 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=242U&client=byo&paint=P0C1G&fabric=FPDSW&sa=S01RP,S02NH,S02TB,S0302,S0313,S0322,S03AG,S03DZ,S0423,S0430,S0431,S0459,S0481,S0493,S04P2,S0508,S0534,S0544,S0563,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06CP,S06U2,S0704,S0710,S0715,S0754,S0760,S0775&quality=70&bkgnd=transparent&resp=png&width=600&width=450&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>2</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="3">
                                                        <img alt="3 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=243Y&client=byo&paint=P0C3Z&fabric=FKHSW&sa=S01CB,S01G4,S0255,S02TB,S0302,S0319,S03AG,S03MB,S0403,S0430,S0431,S0459,S0465,S0481,S0493,S04KA,S04UR,S0534,S0544,S05AC,S0676,S06AC,S06AK,S06C4,S06U2,S06WC,S0775&date=20220705&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450&width=450" />
                                                        <b>3</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="4">
                                                        <img alt="4 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=244B&client=byo&paint=P0C4F&fabric=FKCSW&sa=S01T4,S0255,S02TB,S0302,S0319,S0403,S0430,S0431,S0459,S0493,S04KA,S0508,S0534,S0544,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U2,S0760,S0775&date=20181204&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450 " />
                                                        <b>4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="5">
                                                        <img alt="5 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=245A&client=byo&paint=P0300&fabric=FKSSW&sa=S01CE,S02TE,S0302,S0316,S0319,S0322,S03G2,S0403,S043L,S0459,S04FL,S05AS,S05DM,S0674,S06AC,S06AK,S06C4,S06NX,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>5</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="7">
                                                        <img alt="7 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=247F&client=byo&paint=P0C64&fabric=FKVSW&sa=S01CB,S01FB,S02TB,S0302,S0319,S0337,S033E,S03DZ,S0416,S043D,S0465,S04A2,S04FL,S04FM,S04NR,S05AS,S05DM,S06AC,S06AK,S06C4,S06NX,S06U3,S06U7,S0710,S0760,S0775,S09T1,S09T2&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>7</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="8">
                                                        <img alt="8 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248C&client=byo&paint=P0C31&fabric=FVASW&sa=S01E4,S02VW,S0302,S0319,S0322,S03AG,S03DZ,S0453,S04FM,S04HB,S04L8,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0710,S0715,S0754,S0760,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>8</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="Z4">
                                                        <img alt="Z4" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24ZA&client=byo&paint=P0300&fabric=FK8SW&sa=S01P1,S02TB,S0302,S0319,S0387,S03DZ,S0430,S0431,S0459,S0465,S0493,S04AT,S04AW,S0534,S0544,S05AC,S0676,S06AC,S06AK,S06C4,S06U3,S0704,S0710,S0711,S0715,S0760&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>Z4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M Models">
                                                        <img alt="M Models" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=232R&client=byo&paint=P0C6E&fabric=FMANL&sa=S01E6,S01MB,S02MA,S02VF,S0302,S0319,S0322,S0403,S0430,S0431,S043A,S0459,S0493,S0494,S04AT,S04GQ,S04UR,S0508,S0534,S0544,S05AS,S05DC,S0688,S06AC,S06AK,S06C4,S06CP,S06U2,S06WC,S0712,S0760,S0775&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=900&width=900&width=450 " />
                                                        <b>M Models</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="iX">
                                                        <img alt="A BMW iX Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBI&vehicle=24II&client=byo&paint=P0C57&FABRIC=FSCHA&POV=walkaround&ANGLE=60&SA=S01LD,S02VR,S0322,S0330,S03L7,S0407,S0420,S05AZ,S05DN,S09T8&BKGND=TRANSPARENT&RESP=PNG&quality=70&width=450&width=450 " />
                                                        <b>iX</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="i4">
                                                        <img alt="A BMW i4 Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24DD&client=byo&paint=P0300&fabric=FKHSW&sa=S01CB,S0255,S0302,S0319,S0322,S03AG,S03DE,S03F7,S03MB,S0403,S0430,S0431,S0459,S0493,S04AT,S04UR,S0508,S0534,S0544,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06U2,S06WC,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>i4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="i5">
                                                        <img alt="A BMW i4 Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24DD&client=byo&paint=P0300&fabric=FKHSW&sa=S01CB,S0255,S0302,S0319,S0322,S03AG,S03DE,S03F7,S03MB,S0403,S0430,S0431,S0459,S0493,S04AT,S04UR,S0508,S0534,S0544,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06U2,S06WC,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>i5</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="XM">
                                                        <img alt="The 2024 BMW XM" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SR&client=byo&paint=P0C5Y&fabric=FVCSW&sa=S01FY,S02TB,S02VW,S0302,S0319,S0322,S03DN,S03M3,S0420,S0423,S044A,S0453,S04FL,S04HA,S04HB,S04MA,S04MC,S04T7,S04UR,S05AC,S05AU,S05DN,S0688,S06AC,S06AK,S06C4,S06NX,S06U3,S07M9&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>XM</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="XM">
                                                        <img alt="The 2024 BMW XM" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SR&client=byo&paint=P0C5Y&fabric=FVCSW&sa=S01FY,S02TB,S02VW,S0302,S0319,S0322,S03DN,S03M3,S0420,S0423,S044A,S0453,S04FL,S04HA,S04HB,S04MA,S04MC,S04T7,S04UR,S05AC,S05AU,S05DN,S0688,S06AC,S06AK,S06C4,S06NX,S06U3,S07M9&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>XM</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="hm_future_section">
                                            <div className="hm_future_left">
                                                <div className="hm_future_title">
                                                    <span>PREVIEW</span>
                                                    <strong>Future Vehicles</strong>
                                                </div>
                                                <div className="hm_future_img">
                                                    <img src={fututreImg} alt="The 2024 BMW i5 Sedan"></img>
                                                </div>
                                                <Link title="Future Vehicles"></Link>
                                            </div>
                                            <div className="hm_future_right">
                                                <ul>
                                                    <li>
                                                        <div className="hm_future_title">
                                                            <span>LEARN</span>
                                                            <Link>All BMWs</Link>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="hm_future_title">
                                                            <span>EXPLORE</span>
                                                            <Link>Limited Edition Vehicles</Link>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="hm_future_title">
                                                            <span>DISCOVER</span>
                                                            <Link>Concept Vehicles</Link>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="SAVs" title="SAVs">
                                    <div className="hm_models_section">
                                        <div className="hm_models_heading">
                                            <h1>Sports Activity Vehicles <Link>Learn More</Link></h1>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="X1">
                                                        <img alt="X1" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=23XB&client=byo&paint=P0C66&fabric=FKHSW&sa=S01ED,S02TF,S0302,S03AT,S03MB,S0420,S0459,S04AT,S05A4,S05AC,S05AS,S05DM,S06AC,S06AK,S06C4,S06CP,S06WC,S0775&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=600&width=900&width=450 " />
                                                        <b>X1</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X3">
                                                        <img alt="X3" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XQ&client=byo&paint=P0A90&fabric=FKHSW&sa=S01X8,S0255,S02TB,S0302,S0319,S03AG,S0420,S0423,S0430,S0431,S0459,S0481,S0493,S04K1,S04U0,S04UR,S0508,S0534,S0552,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U2,S0775&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>X3</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X4">
                                                        <img alt="X4" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XV&client=byo&paint=P0C4P&fabric=FKHSW&sa=S01CE,S01VU,S02NH,S02TB,S02VF,S0302,S0319,S0322,S03AG,S03DZ,S0402,S0420,S0423,S0430,S0459,S0481,S0493,S04AW,S04KN,S04U0,S04UR,S0534,S0552,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U3,S0710,S0715,S0760,S0775&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>X4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X5">
                                                        <img alt="X5" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XO&client=byo&paint=P0300&fabric=FKPSW&sa=S01CE,S01SF,S0255,S02TB,S0302,S0319,S0322,S03AT,S03MB,S0402,S0420,S0423,S0459,S0481,S0494,S04FL,S04KR,S04T8,S04UR,S05AC,S05AS,S05DM,S0676,S06AC,S06AK,S06C4,S06CP,S06NX,S06U2,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>X5</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X6">
                                                        <img alt="X6" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XL&client=byo&paint=P0300&fabric=FKPSW&sa=S01CE,S01SF,S0255,S0302,S0319,S0322,S03MB,S0402,S0420,S0423,S0459,S0481,S0494,S04KM,S04T8,S04UR,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U3,S0775,S07HW&quality=70&bkgnd=transparent&resp=png&width=560&angle=60&width=900&width=450&width=450" />
                                                        <b>X6</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X7">
                                                        <img alt="X7" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SA&client=byo&paint=P0C55&fabric=FKPSW&sa=S01CE,S01XK,S0255,S02TB,S0302,S0319,S0322,S03AT,S03MB,S0418,S0420,S0423,S0456,S04FL,S04HB,S04KR,S04UR,S05AC,S05AS,S05DM,S0676,S06AC,S06AK,S06C4,S06NX,S06U3&quality=70&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>X7</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X3 M">
                                                        <img alt="X3 M" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SO&client=byo&paint=P0C1K&fabric=FMAH7&sa=S01RF,S0302,S0319,S0322,S03AG,S03MC,S0420,S0423,S0493,S04MC,S0552,S05AS,S05AV,S0688,S06AC,S06AK,S06C4,S06U3&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>X3 M</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X4 M">
                                                        <img alt="X4 M" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SP&client=byo&paint=P0C4H&fabric=FMAH7&sa=S01RF,S0302,S0319,S0322,S03AG,S03MC,S0402,S0420,S0423,S0493,S04MC,S0552,S05AS,S05AV,S0688,S06AC,S06AK,S06C4,S06U3&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>X4 M</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X5 M">
                                                        <img alt="X5 M" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XK&client=byo&paint=P0C1K&fabric=FLKSW&sa=S01P6,S0302,S0319,S0322,S03MQ,S0402,S0420,S0423,S04HB,S04MA,S04MC,S04NB,S05AC,S05AS,S05AZ,S05DN,S0688,S06AC,S06AK,S06C4,S06DR,S06NW,S06U3,S06WD,S0776&quality=70&bkgnd=transparent&resp=png&width=600&width=450&angle=60&w=10000&h=10000&x=0&y=0&width=450&width=450 " />
                                                        <b>X5 M</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X6 M">
                                                        <img alt="X6 M" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XN&client=byo&paint=P0475&fabric=FLKSW&sa=S01P6,S0302,S0319,S0322,S0402,S0420,S0423,S04HB,S04MA,S04MC,S04NB,S05AC,S05AS,S05AZ,S05DN,S0688,S06AC,S06AK,S06C4,S06DR,S06U3,S06WD,S0776&quality=hight&bkgnd=transparent&resp=png&angle=60&width=900&width=450&width=450 " />
                                                        <b>X6 M</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="iX">
                                                        <img alt="A BMW iX Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBI&vehicle=24II&client=byo&paint=P0C57&FABRIC=FSCHA&POV=walkaround&ANGLE=60&SA=S01LD,S02VR,S0322,S0330,S03L7,S0407,S0420,S05AZ,S05DN,S09T8&BKGND=TRANSPARENT&RESP=PNG&quality=70&width=450&width=450 " />
                                                        <b>iX</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="XM">
                                                        <img alt="The 2024 BMW XM" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SR&client=byo&paint=P0C5Y&fabric=FVCSW&sa=S01FY,S02TB,S02VW,S0302,S0319,S0322,S03DN,S03M3,S0420,S0423,S044A,S0453,S04FL,S04HA,S04HB,S04MA,S04MC,S04T7,S04UR,S05AC,S05AU,S05DN,S0688,S06AC,S06AK,S06C4,S06NX,S06U3,S07M9&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>XM</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Sedans" title="Sedans">
                                    <div className="hm_models_section">
                                        <div className="hm_models_heading">
                                            <h1>Sedans & Gran Coupes</h1>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="2">
                                                        <img alt="2 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=242U&client=byo&paint=P0C1G&fabric=FPDSW&sa=S01RP,S02NH,S02TB,S0302,S0313,S0322,S03AG,S03DZ,S0423,S0430,S0431,S0459,S0481,S0493,S04P2,S0508,S0534,S0544,S0563,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06CP,S06U2,S0704,S0710,S0715,S0754,S0760,S0775&quality=70&bkgnd=transparent&resp=png&width=600&width=450&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>2</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="3">
                                                        <img alt="3 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=243Y&client=byo&paint=P0C3Z&fabric=FKHSW&sa=S01CB,S01G4,S0255,S02TB,S0302,S0319,S03AG,S03MB,S0403,S0430,S0431,S0459,S0465,S0481,S0493,S04KA,S04UR,S0534,S0544,S05AC,S0676,S06AC,S06AK,S06C4,S06U2,S06WC,S0775&date=20220705&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450&width=450" />
                                                        <b>3</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="4">
                                                        <img alt="4 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=244B&client=byo&paint=P0C4F&fabric=FKCSW&sa=S01T4,S0255,S02TB,S0302,S0319,S0403,S0430,S0431,S0459,S0493,S04KA,S0508,S0534,S0544,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U2,S0760,S0775&date=20181204&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450 " />
                                                        <b>4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="5">
                                                        <img alt="5 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=245A&client=byo&paint=P0300&fabric=FKSSW&sa=S01CE,S02TE,S0302,S0316,S0319,S0322,S03G2,S0403,S043L,S0459,S04FL,S05AS,S05DM,S0674,S06AC,S06AK,S06C4,S06NX,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>5</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="7">
                                                        <img alt="7 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=247F&client=byo&paint=P0C64&fabric=FKVSW&sa=S01CB,S01FB,S02TB,S0302,S0319,S0337,S033E,S03DZ,S0416,S043D,S0465,S04A2,S04FL,S04FM,S04NR,S05AS,S05DM,S06AC,S06AK,S06C4,S06NX,S06U3,S06U7,S0710,S0760,S0775,S09T1,S09T2&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>7</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="8">
                                                        <img alt="8 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248C&client=byo&paint=P0C31&fabric=FVASW&sa=S01E4,S02VW,S0302,S0319,S0322,S03AG,S03DZ,S0453,S04FM,S04HB,S04L8,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0710,S0715,S0754,S0760,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>8</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M3">
                                                        <img alt="8 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24TP&client=byo&paint=P0C4G&fabric=FLKSW&sa=S01T8,S0302,S0319,S0322,S0430,S0431,S0459,S0493,S0494,S04GQ,S04KK,S0508,S0534,S0544,S05AC,S05AS,S0688,S06AC,S06AK,S06C4,S06U3,S0712,S0760,S0775,S07M9&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=900&width=450 " />
                                                        <b>M3</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M8">
                                                        <img alt="M8 Gran Coupe" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248L&client=byo&paint=P0C4G&fabric=FLKSW&sa=S01MA,S01TH,S0302,S0319,S0322,S03MF,S0415,S0416,S04GQ,S04HB,S04MC,S04NB,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0712,S0760,S0776,S07M9,S07MA&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>M8</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="i4">
                                                        <img alt="A BMW i4 Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24DD&client=byo&paint=P0300&fabric=FKHSW&sa=S01CB,S0255,S0302,S0319,S0322,S03AG,S03DE,S03F7,S03MB,S0403,S0430,S0431,S0459,S0493,S04AT,S04UR,S0508,S0534,S0544,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06U2,S06WC,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>i4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="i5">
                                                        <img alt="A BMW i5 Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=245U&client=byo&paint=P0C4P&fabric=FKSSW&sa=S02VV,S0302,S0316,S0319,S0322,S03GD,S0403,S043L,S0456,S0494,S04FL,S04UR,S05AS,S05DM,S06AC,S06AK,S06C4,S06F4,S06NX,S0710,S0760,S0775,S09T1,S09T2&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450" />
                                                        <b>i5</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="i7">
                                                        <img alt="A BMW i7 Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=247Q&client=byo&paint=P0C4A&fabric=FVCSW&sa=S01F7,S0302,S0319,S0407,S0416,S043D,S0465,S046A,S04A2,S04FL,S04FM,S04NR,S04V1,S05AS,S05DM,S06AC,S06AK,S06C4,S06NX,S06U3,S06U7,S0775,S07M7&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>i7</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Coupes" title="Coupes">
                                    <div className="hm_models_section">
                                        <div className="hm_models_heading">
                                            <h1>Coupes</h1>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="2">
                                                        <img alt="2 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=242U&client=byo&paint=P0C1G&fabric=FPDSW&sa=S01RP,S02NH,S02TB,S0302,S0313,S0322,S03AG,S03DZ,S0423,S0430,S0431,S0459,S0481,S0493,S04P2,S0508,S0534,S0544,S0563,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06CP,S06U2,S0704,S0710,S0715,S0754,S0760,S0775&quality=70&bkgnd=transparent&resp=png&width=600&width=450&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>2</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="4">
                                                        <img alt="4 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=244B&client=byo&paint=P0C4F&fabric=FKCSW&sa=S01T4,S0255,S02TB,S0302,S0319,S0403,S0430,S0431,S0459,S0493,S04KA,S0508,S0534,S0544,S05AC,S05AS,S05AV,S0676,S06AC,S06AK,S06C4,S06U2,S0760,S0775&date=20181204&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450 " />
                                                        <b>4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="8">
                                                        <img alt="8 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248C&client=byo&paint=P0C31&fabric=FVASW&sa=S01E4,S02VW,S0302,S0319,S0322,S03AG,S03DZ,S0453,S04FM,S04HB,S04L8,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0710,S0715,S0754,S0760,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>8</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M2">
                                                        <img alt="M2" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=242R&client=byo&paint=P0C6E&fabric=FMANL&sa=S01E6,S01MB,S02MA,S02VF,S0302,S0319,S0322,S0403,S0430,S0431,S043A,S0459,S0493,S0494,S04AT,S04GQ,S04UR,S0508,S0534,S0544,S05AS,S05DC,S0688,S06AC,S06AK,S06C4,S06CP,S06U2,S06WC,S0712,S0760,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&width=900&width=450 " />
                                                        <b>M2</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M4">
                                                        <img alt="M4" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=244X&client=byo&paint=P0C4H&fabric=FLKSW&sa=S01T8,S0302,S0319,S0322,S0430,S0431,S0459,S0493,S0494,S04GQ,S04KK,S0508,S0534,S0544,S05AC,S05AS,S0688,S06AC,S06AK,S06C4,S06U3,S0712,S0760,S0775,S07M9&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450 " />
                                                        <b>M4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M8">
                                                        <img alt="M8 Coupe" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248F&client=byo&paint=P0C4P&fabric=FLKSW&sa=S01MA,S01TH,S0302,S0319,S0322,S03MF,S0453,S04GQ,S04HB,S04MC,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0712,S0760,S0776,S07M9,S07MA&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>M8</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Convertibles" title="Convertibles">
                                    <div className="hm_models_section">
                                        <div className="hm_models_heading">
                                            <h1>Convertibles</h1>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="4">
                                                        <img alt="4 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=244J&client=byo&paint=P0C4E&fabric=FMANL&sa=S01T4,S0255,S02TB,S0302,S0319,S0387,S03MB,S0430,S0431,S0493,S04KA,S04UR,S0508,S0534,S0544,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06U3&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450 " />
                                                        <b>4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="8">
                                                        <img alt="8 Series" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248G&client=byo&paint=P0C5A&fabric=FVASW&sa=S01E4,S02VW,S0302,S0316,S0319,S0322,S03AG,S03DZ,S0453,S04FM,S04HB,S04L8,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0710,S0715,S0760&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>8</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="Z4">
                                                        <img alt="Z4" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24ZA&client=byo&paint=P0300&fabric=FK8SW&sa=S01P1,S02TB,S0302,S0319,S0387,S03DZ,S0430,S0431,S0459,S0465,S0493,S04AT,S04AW,S0534,S0544,S05AC,S0676,S06AC,S06AK,S06C4,S06U3,S0704,S0710,S0711,S0715,S0760&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>Z4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M4">
                                                        <img alt="M4" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=244M&client=byo&paint=P0X1E&fabric=FX3IA&sa=S01T8,S0302,S0319,S0322,S0387,S0430,S0431,S0459,S0493,S0494,S04GQ,S04KK,S0508,S0534,S0544,S05AC,S05AS,S0688,S06AC,S06AK,S06C4,S06U3,S0712,S0760,S0775,S07M9&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450 " />
                                                        <b>M4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M8">
                                                        <img alt="M8" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=248K&client=byo&paint=P0C57&fabric=FLKSW&sa=S01MA,S01TH,S0302,S0316,S0319,S0322,S03MF,S0453,S04GQ,S04HB,S04MC,S05AC,S05AZ,S06AC,S06AK,S06C4,S06NW,S06U3,S06WD,S0712,S0760,S07M9,S07MA&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>M8</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="Electrified" title="Electrified">
                                    <div className="hm_models_section hm_col3">
                                        <div className="hm_models_heading">
                                            <h1><img alt="#" src={electricImg} />All-Electric<Link>Learn More</Link></h1>
                                            <span>#bornelectric <i><img alt="#" src={iGreyImg} /></i></span>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="iX">
                                                        <img alt="A BMW iX Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBI&vehicle=24II&client=byo&paint=P0C57&FABRIC=FSCHA&POV=walkaround&ANGLE=60&SA=S01LD,S02VR,S0322,S0330,S03L7,S0407,S0420,S05AZ,S05DN,S09T8&BKGND=TRANSPARENT&RESP=PNG&quality=70&width=450&width=450 " />
                                                        <b>iX</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="i4">
                                                        <img alt="A BMW i4 Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24DD&client=byo&paint=P0300&fabric=FKHSW&sa=S01CB,S0255,S0302,S0319,S0322,S03AG,S03DE,S03F7,S03MB,S0403,S0430,S0431,S0459,S0493,S04AT,S04UR,S0508,S0534,S0544,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06U2,S06WC,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>i4</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="i5">
                                                        <img alt="A BMW i4 Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24DD&client=byo&paint=P0300&fabric=FKHSW&sa=S01CB,S0255,S0302,S0319,S0322,S03AG,S03DE,S03F7,S03MB,S0403,S0430,S0431,S0459,S0493,S04AT,S04UR,S0508,S0534,S0544,S05AC,S05AS,S0676,S06AC,S06AK,S06C4,S06U2,S06WC,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>i5</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="i7">
                                                        <img alt="A BMW i7 Electric Vehicle" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=247Q&client=byo&paint=P0C4A&fabric=FVCSW&sa=S01F7,S0302,S0319,S0407,S0416,S043D,S0465,S046A,S04A2,S04FL,S04FM,S04NR,S04V1,S05AS,S05DM,S06AC,S06AK,S06C4,S06NX,S06U3,S06U7,S0775,S07M7&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>i7</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="hm_models_section hm_col3">
                                        <div className="hm_models_heading">
                                            <h1><img alt="#" src={electricImg} />Plug-in Hybrids<Link>Learn More</Link></h1>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="X5 xDrive50e">
                                                        <img alt="X5 xDrive50e" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XT&client=byo&paint=P0300&fabric=FKPSW&sa=S01SF,S0255,S02TB,S02VR,S0302,S0319,S0322,S03AT,S03MB,S0402,S0420,S0423,S0459,S0481,S0494,S04FL,S04KR,S04T8,S04UR,S05AC,S05AS,S05DM,S0676,S06AC,S06AK,S06C4,S06CP,S06NX,S06U2,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>X5 xDrive50e</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="330e Sedan">
                                                        <img alt="330e Sedan" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=247P&client=byo&paint=P0C64&fabric=FVCSW&sa=S01CB,S01FB,S02TB,S0302,S0319,S0337,S03DZ,S0416,S043D,S0465,S04A2,S04FL,S04FM,S04NR,S04V1,S05AS,S05DM,S06AC,S06AK,S06C4,S06NX,S06U3,S06U7,S0710,S0760,S0775,S07M7,S09T1,S09T2&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>330e Sedan</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="750e xDrive Sedan">
                                                        <img alt="750e xDrive Sedan" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=247P&client=byo&paint=P0C64&fabric=FVCSW&sa=S01CB,S01FB,S02TB,S0302,S0319,S0337,S03DZ,S0416,S043D,S0465,S04A2,S04FL,S04FM,S04NR,S04V1,S05AS,S05DM,S06AC,S06AK,S06C4,S06NX,S06U3,S06U7,S0710,S0760,S0775,S07M7,S09T1,S09T2&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>750e xDrive Sedan</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="XM">
                                                        <img alt="The 2024 BMW XM" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SR&client=byo&paint=P0C5Y&fabric=FVCSW&sa=S01FY,S02TB,S02VW,S0302,S0319,S0322,S03DN,S03M3,S0420,S0423,S044A,S0453,S04FL,S04HA,S04HB,S04MA,S04MC,S04T7,S04UR,S05AC,S05AU,S05DN,S0688,S06AC,S06AK,S06C4,S06NX,S06U3,S07M9&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>XM</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="hm_future_section">
                                        <div className="hm_future_left">
                                            <div className="hm_future_title">
                                                <span>LEARN</span>
                                                <strong>BMW Charging</strong>
                                            </div>
                                            <div className="hm_future_img">
                                                <img src={chargingCarImg} alt="Angled rear view of BMW iX in Alpine White charging up with BMW Wallbox"></img>
                                            </div>
                                            <Link title="Future Vehicles"></Link>
                                        </div>
                                        <div className="hm_future_right">
                                            <ul>
                                                <li>
                                                    <div className="hm_future_title">
                                                        <span>DISCOVER</span>
                                                        <Link>All-Electric Vehicles</Link>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="hm_future_title">
                                                        <span>EXPLORE</span>
                                                        <Link>Plug-in Hybrid Vehicles</Link>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="BMWM" title="BMW M">
                                    <div className="hm_models_section hm_col3">
                                        <div className="hm_models_heading">
                                            <h1><img alt="#" src={mImg} />HE MOST POWERFUL LETTER IN THE WORLD<Link>Learn More</Link></h1>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="M2">
                                                        <img alt="M2" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=242R&client=byo&paint=P0C6E&fabric=FMANL&sa=S01E6,S01MB,S02MA,S02VF,S0302,S0319,S0322,S0403,S0430,S0431,S043A,S0459,S0493,S0494,S04AT,S04GQ,S04UR,S0508,S0534,S0544,S05AS,S05DC,S0688,S06AC,S06AK,S06C4,S06CP,S06U2,S06WC,S0712,S0760,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&width=900&width=450 " />
                                                        <b>M2</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M3">
                                                        <img alt="M3" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24TP&client=byo&paint=P0C4G&fabric=FLKSW&sa=S01T8,S0302,S0319,S0322,S0430,S0431,S0459,S0493,S0494,S04GQ,S04KK,S0508,S0534,S0544,S05AC,S05AS,S0688,S06AC,S06AK,S06C4,S06U3,S0712,S0760,S0775,S07M9&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=900&width=450 " />
                                                        <b>M3</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M4 Coupe">
                                                        <img alt="M4 Coupe" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=244X&client=byo&paint=P0C4H&fabric=FLKSW&sa=S01T8,S0302,S0319,S0322,S0430,S0431,S0459,S0493,S0494,S04GQ,S04KK,S0508,S0534,S0544,S05AC,S05AS,S0688,S06AC,S06AK,S06C4,S06U3,S0712,S0760,S0775,S07M9&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450 " />
                                                        <b>M4 Coupe</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M4 Convertible">
                                                        <img alt="M4 Convertible" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=244M&client=byo&paint=P0X1E&fabric=FX3IA&sa=S01T8,S0302,S0319,S0322,S0387,S0430,S0431,S0459,S0493,S0494,S04GQ,S04KK,S0508,S0534,S0544,S05AC,S05AS,S0688,S06AC,S06AK,S06C4,S06U3,S0712,S0760,S0775,S07M9&quality=70&bkgnd=transparent&RESP=PNG&angle=60&width=450&width=450 " />
                                                        <b>M4 Convertible</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="M8 Coupe">
                                                        <img alt="M8 Coupe" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SP&client=byo&paint=P0C4H&fabric=FMAH7&sa=S01RF,S0302,S0319,S0322,S03AG,S03MC,S0402,S0420,S0423,S0493,S04MC,S0552,S05AS,S05AV,S0688,S06AC,S06AK,S06C4,S06U3&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>M8 Coupe</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X5 M">
                                                        <img alt="X5 M" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XK&client=byo&paint=P0C1K&fabric=FLKSW&sa=S01P6,S0302,S0319,S0322,S03MQ,S0402,S0420,S0423,S04HB,S04MA,S04MC,S04NB,S05AC,S05AS,S05AZ,S05DN,S0688,S06AC,S06AK,S06C4,S06DR,S06NW,S06U3,S06WD,S0776&quality=70&bkgnd=transparent&resp=png&width=600&width=450&angle=60&w=10000&h=10000&x=0&y=0&width=450&width=450 " />
                                                        <b>X5 M</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="X6 M">
                                                        <img alt="X6 M" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XN&client=byo&paint=P0475&fabric=FLKSW&sa=S01P6,S0302,S0319,S0322,S0402,S0420,S0423,S04HB,S04MA,S04MC,S04NB,S05AC,S05AS,S05AZ,S05DN,S0688,S06AC,S06AK,S06C4,S06DR,S06U3,S06WD,S0776&quality=hight&bkgnd=transparent&resp=png&angle=60&width=900&width=450&width=450 " />
                                                        <b>X6 M</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="XM">
                                                        <img alt="The 2024 BMW XM" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SP&client=byo&paint=P0C4H&fabric=FMAH7&sa=S01RF,S0302,S0319,S0322,S03AG,S03MC,S0402,S0420,S0423,S0493,S04MC,S0552,S05AS,S05AV,S0688,S06AC,S06AK,S06C4,S06U3&quality=90&bkgnd=transparent&resp=png&width=600&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>XM</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="hm_models_section hm_col3">
                                        <div className="hm_models_heading">
                                            <h1>Additional M Models</h1>
                                        </div>
                                        <div className="hm_models_list">
                                            <ul>
                                                <li>
                                                    <Link className="hm_models_box" title="X5 xDrive50e">
                                                        <img alt="X5 xDrive50e" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24XT&client=byo&paint=P0300&fabric=FKPSW&sa=S01SF,S0255,S02TB,S02VR,S0302,S0319,S0322,S03AT,S03MB,S0402,S0420,S0423,S0459,S0481,S0494,S04FL,S04KR,S04T8,S04UR,S05AC,S05AS,S05DM,S0676,S06AC,S06AK,S06C4,S06CP,S06NX,S06U2,S0775&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>X5 xDrive50e</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="330e Sedan">
                                                        <img alt="330e Sedan" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=247P&client=byo&paint=P0C64&fabric=FVCSW&sa=S01CB,S01FB,S02TB,S0302,S0319,S0337,S03DZ,S0416,S043D,S0465,S04A2,S04FL,S04FM,S04NR,S04V1,S05AS,S05DM,S06AC,S06AK,S06C4,S06NX,S06U3,S06U7,S0710,S0760,S0775,S07M7,S09T1,S09T2&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>330e Sedan</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="750e xDrive Sedan">
                                                        <img alt="750e xDrive Sedan" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=247P&client=byo&paint=P0C64&fabric=FVCSW&sa=S01CB,S01FB,S02TB,S0302,S0319,S0337,S03DZ,S0416,S043D,S0465,S04A2,S04FL,S04FM,S04NR,S04V1,S05AS,S05DM,S06AC,S06AK,S06C4,S06NX,S06U3,S06U7,S0710,S0760,S0775,S07M7,S09T1,S09T2&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>750e xDrive Sedan</b>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className="hm_models_box" title="XM">
                                                        <img alt="The 2024 BMW XM" src="https://cache.bmwusa.com/cosy.arox?pov=walkaround&brand=WBBM&vehicle=24SR&client=byo&paint=P0C5Y&fabric=FVCSW&sa=S01FY,S02TB,S02VW,S0302,S0319,S0322,S03DN,S03M3,S0420,S0423,S044A,S0453,S04FL,S04HA,S04HB,S04MA,S04MC,S04T7,S04UR,S05AC,S05AU,S05DN,S0688,S06AC,S06AK,S06C4,S06NX,S06U3,S07M9&quality=70&bkgnd=transparent&resp=png&angle=60&w=10000&h=10000&x=0&y=0&width=450 " />
                                                        <b>XM</b>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>

        </div>
    );
};

export default Models;