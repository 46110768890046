
import { useReducer, createContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RoomOutlined } from "@mui/icons-material";
import { Button } from "react-bootstrap";

import FiltersHeader from "../../Components/FiltersHeader";

import Footer from "../../Components/Footer/Footer";
import GridItemCard from "../../Components/GridItemCard/GridItemCard";
import Header from "../../Components/Header";
import { SearchFilters } from '../../Components/Search/search'

import { useAssets } from '../../hooks'
import { Context } from '../../utilities'

const initialState = {
  totalAssets: [],
  series: ['Mercedes','Toyota','Nissan', 'Audi'],
  selectedSeries: [],
  models: [
    { value: 0, label: 'Select' },
    { value: 1, label: 'Model 1' },
    { value: 2, label: 'Model 1' }
  ],
  selectedModel: '',
  bodyStyles: [
    { id: 1, label: 'Gran Coupe' },
    { id: 2, label: 'Sports Activity Coupe' },
    { id: 3, label: 'Roadster' }
  ],
  selectedBodyStyle: '',
  priceOptions: [
    { value: 0, label: 'Select' },
    ...Array.from({ length: 20 }, (_, i) => {
      return { value: (i + 1) * 10000, label: `£ ${(i + 1) * 10000}` }
    })
  ],
  minSelectedPrice: 0,
  maxSelectedPrice: 0,
  yearOptions: [
    { value: 0, label: 'Select' },
    { value: 2020, label: '2020' },
    { value: 2021, label: '2021' },
    { value: 2022, label: '2022' },
    { value: 2023, label: '2023' },
    { value: 2024, label: '2024' }
  ],
  minSelectedYear: 0,
  maxSelectedYear: 0,
  exteriorColorOptions: [
    { id: 1, label: 'Black', cssClass: 'coloricon-black' },
    { id: 2, label: 'Blue', cssClass: 'coloricon-blue' },
    { id: 3, label: 'Brown', cssClass: 'coloricon-brown' },
    { id: 4, label: 'Gray', cssClass: 'coloricon-gray' },
    { id: 5, label: 'Green', cssClass: 'coloricon-green' },
    { id: 6, label: 'Orange', cssClass: 'coloricon-orange' },
    { id: 7, label: 'Purple', cssClass: 'coloricon-purple' },
    { id: 8, label: 'Silver', cssClass: 'coloricon-silver' },
    { id: 9, label: 'White', cssClass: 'coloricon-white' },
    { id: 10, label: 'Yellow', cssClass: 'coloricon-yellow' },
    { id: 11, label: 'Other', cssClass: 'coloricon-multy' }
  ],
  selectedExteriorColor: [],
  packages: [
    { id: 1, label: 'BMW Individual Composition' },
    { id: 2, label: 'Comfort Seating Package' },
    { id: 3, label: 'Convenience Package' }
  ],
  selectedPackages: [],
  options: [
    { id: 1, label: '19" M Y-spoke Bi-color Midnight Grey Whe...' },
    { id: 2, label: '4-zone automatic climate control' },
    { id: 3, label: '48V Mild Hybrid System' }
  ],
  selectedOptions: [],
  fuelTypes: [
    { id: 1, label: 'Gas' },
    { id: 2, label: 'Electric' },
    { id: 3, label: 'Plug-in Hybrid' }
  ],
  selectedfuelTypes: [],
  drivetrains: [
    { id: 1, label: 'xDrive' },
    { id: 2, label: 'RWD/sDrive' },
    { id: 3, label: 'FWD/sDrive' }
  ],
  selectedDrivetrains: [],
  vin: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_SERIES':
      return {
        ...state,
        selectedSeries: [],
        selectedModel: '',
        selectedBodyStyle: '',
        minSelectedPrice: 0,
        maxSelectedPrice: 0,
        minSelectedYear: 0,
        maxSelectedYear: 0,
        selectedExteriorColor: [],
        selectedPackages: [],
        selectedOptions: [],
        selectedfuelTypes: [],
        selectedDrivetrains: [],
        vin: ''
      }

    case 'SET_TOTAL_ASSETS':
      return {
        ...state,
        totalAssets: action.payload
      }

    case 'ADD_SERIES':
      if (state.selectedSeries.includes(action.payload)) {
        return state
      }
      return {
        ...state,
        selectedSeries: [...state.selectedSeries, action.payload]
      }

    case 'REMOVE_SERIES':
      return {
        ...state,
        selectedSeries: state.selectedSeries.filter((item) => item !== action.payload)
      }

    case 'SELECT_MODEL':
      if (action.payload === 'Select') {
        return {
          ...state,
          selectedModel: ''
        }
      }
      return {
        ...state,
        selectedModel: action.payload
      }

    case 'REMOVE_MODEL':
      return {
        ...state,
        selectedModel: ''
      }

    case 'SELECT_BODY_STYLE':
      return {
        ...state,
        selectedBodyStyle: action.payload
      }

    case 'REMOVE_BODY_STYLE':
      return {
        ...state,
        selectedBodyStyle: ''
      }

    case 'SELECT_MIN_PRICE':
      return {
        ...state,
        minSelectedPrice: action.payload
      }

    case 'REMOVE_MIN_PRICE':
      return {
        ...state,
        minSelectedPrice: 0
      }

    case 'SELECT_MAX_PRICE':
      return {
        ...state,
        maxSelectedPrice: action.payload
      }

    case 'REMOVE_MAX_PRICE':
      return {
        ...state,
        maxSelectedPrice: 0
      }

    case 'SELECT_MIN_YEAR':
      return {
        ...state,
        minSelectedYear: action.payload
      }

    case 'REMOVE_MIN_YEAR':
      return {
        ...state,
        minSelectedYear: 0
      }

    case 'SELECT_MAX_YEAR':
      return {
        ...state,
        maxSelectedYear: action.payload
      }

    case 'REMOVE_MAX_YEAR':
      return {
        ...state,
        maxSelectedYear: 0
      }

    case 'SELECT_EXTERIOR_COLOR':
      if (state.selectedExteriorColor.includes(action.payload)) {
        return state
      }
      return {
        ...state,
        selectedExteriorColor: [...state.selectedExteriorColor, action.payload]
      }

    case 'REMOVE_EXTERIOR_COLOR':
      return {
        ...state,
        selectedExteriorColor: state.selectedExteriorColor.filter((item) => item !== action.payload)
      }

    case 'SELECT_PACKAGE':
      if (state.selectedPackages.includes(action.payload)) {
        return state
      }
      return {
        ...state,
        selectedPackages: [...state.selectedPackages, action.payload]
      }

    case 'REMOVE_PACKAGE':
      return {
        ...state,
        selectedPackages: state.selectedPackages.filter((item) => item !== action.payload)
      }

    case 'SELECT_OPTION':
      if (state.selectedOptions.includes(action.payload)) {
        return state
      }
      return {
        ...state,
        selectedOptions: [...state.selectedOptions, action.payload]
      }

    case 'REMOVE_OPTION':
      return {
        ...state,
        selectedOptions: state.selectedOptions.filter((item) => item !== action.payload)
      }

    case 'SELECT_FUEL_TYPE':
      if (state.selectedfuelTypes.includes(action.payload)) {
        return state
      }
      return {
        ...state,
        selectedfuelTypes: [...state.selectedfuelTypes, action.payload]
      }

    case 'REMOVE_FUEL_TYPE':
      return {
        ...state,
        selectedfuelTypes: state.selectedfuelTypes.filter((item) => item !== action.payload)
      }

    case 'SET_VIN':
      return {
        ...state,
        vin: action.payload
      }

    default:
      return state
  }
}

function HomePage() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const contextValue = { state, dispatch };

  const [isVisible, setIsVisible] = useState(true);
  const { data: ASSETS, isError, error, isFetching, isFetched } = useAssets();

  useEffect(() => {
    if (state.vin) {
      let filteredAssets = ASSETS.filter((item) => { return item.vin.toLowerCase().includes(state.vin.toLowerCase()) })
      dispatch({ type: 'SET_TOTAL_ASSETS', payload: filteredAssets })
    } else {
      dispatch({ type: 'SET_TOTAL_ASSETS', payload: ASSETS || [] })
    }
  }, [state.vin])

  useEffect(() => {
    dispatch({ type: 'SET_TOTAL_ASSETS', payload: ASSETS || [] })
  }, [ASSETS])

  if (isError) {
    return <div>{error.message}</div>
  }

  if (isFetching) {
    return <div>Loading...</div>
  }

  if (!isFetched) {
    return <div>Not Fetched</div>
  }

  return (
    <Context.Provider value={contextValue}>
      <Header />
      <div className="content">
        <FiltersHeader handleClick={() => { setIsVisible(!isVisible) }} isVisible={isVisible} />
        <div className="filter_drawer_wrapper">
          {
            isVisible ? <SearchFilters handleClick={() => setIsVisible(!isVisible)} isVisible={isVisible} /> : ''
          }

          <div className="inventory_result_content">
            <div className="inventory_result_content_inner">
              <div className="results_infoheader d-flex align-items-center justify-content-between flex-wrap pb-4">
                <div className="col">
                  <h2 className="content-2--bold"> {state?.totalAssets?.length} Dealer One Vehicles </h2>
                </div>
                <div className="col d-flex justify-content-end align-items-center flex-wrap">
                  <div className="me-4 text-end">
                    <span className="acc_title mb-1">Primary Dealer</span>
                    <Link className="d-flex align-items-center header_location content-2--bold">
                      <RoomOutlined className="d-block me-1" />
                      Dealer One
                    </Link>
                  </div>
                  <div>
                    <Button variant="dark">Contact Dealer</Button>
                  </div>
                </div>
              </div>
              <div className="vehicles_list_items">
                <ul className="vehicles_list_ul">
                  {
                    state.totalAssets.map((asset, index) => {
                      return (
                        <li key={index}>
                          <GridItemCard item={asset} />
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Context.Provider>
  )
}

export default HomePage