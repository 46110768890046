import React from "react";
import Logo from "./Logo";
import HeaderMenu from "./HeaderMenu";
import HeaderInfo from "./HeaderInfo";
import 'bootstrap/dist/js/bootstrap.bundle';

function Header() {
    return (
        <header className="header">
            <div className="header_inner navbar navbar-expand-md d-flex justify-content-between align-items-center">
                <Logo />
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="headerRight w-100 collapse navbar-collapse" id="navbarNav">
                    <div className="headerRightInner w-100 d-flex justify-content-between align-items-center">
                        <HeaderMenu />

                        <HeaderInfo/>
                     </div>
                </div>
            </div>
        </header>
    )
}
export default Header;