import { ArrowForwardIos, ChatBubbleOutlineOutlined } from '@mui/icons-material';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { useSaveCreditRequest } from "../../hooks";
import { useNavigate, useLocation } from 'react-router-dom'

import Logo from '../Logo';
import bigImg from '../../assets/images/lucid-crop1.jpeg';
import img1 from '../../assets/images/top_ourVehimg.png';

const AreYouSureToSubmit = ({ show, handleClose, orderInformation }) => {
    const { mutate: saveOrder, isLoading: isOrderSubmitting } = useSaveCreditRequest()
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');



    const [isNextStep, setIsNextStep] = useState({
        id: 'step1',
    })
    const continueHandleClick = (e) => {
        setIsNextStep({
            id: e,
        })
    }
    const submitOrder = (e) => {
        saveOrder(orderInformation, {
            onSuccess() {
                continueHandleClick("step2")
            }
        })
    }

    return (
        <Modal className='areYouSureModal' show={show} onHide={handleClose} size="xl">
            <Modal.Body className='p-0'>
                {/* Add your modal content here */}

                <div className='pfi_step pfi_step1'>
                    <div className="perfectpopup">
                        <div className="perfpopimg">
                            <img src={bigImg} alt='#' className="img-responsive"></img>
                        </div>
                        {
                            isNextStep.id === 'step1' ?
                                <div className="perfpopright adjustment">
                                    <div className="fpi_mainheading mb-3">
                                        <h2>Are You Sure You Want to Submit? <br /> - This is a Detailed Credit Check </h2>
                                        <p className='mb-3'>Please read this disclaimer carefully before submitting your credit application. By proceeding with the application, you acknowledge and accept that Dealer One will perform a detailed evaluation yielding offers from Finance Company as well as various other Lenders. <br /> <br />This is a formal request to review your credit information and may temporarily impact your credit score. This authorization is necessary for us to assess your creditworthiness in order to make a lending decision. </p>
                                        <div className="continue-btn pt-3 d-flex align-items-end justify-content-start">
                                            <div className='pe-3'>
                                                <Button disabled={isOrderSubmitting} variant="primary " onClick={(e) => {
                                                    submitOrder()
                                                    // continueHandleClick("step2")
                                                }}>Submit <ArrowForwardIos /></Button>
                                            </div>

                                            <div className=''>
                                                <Button variant="secondary" >Online Support <ChatBubbleOutlineOutlined /></Button>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                                : ''
                        }


                        {
                            isNextStep.id === 'step2' ?
                                <div className="perfpopright adjustment">
                                    <div className="fpi_mainheading mb-3">
                                        <h2>We're Processing Your Application In the Background  <br /> - This Won't Take Long! </h2>
                                        <p className='mb-3'>We are currently reviewing the details you provided. Once verified, you'll receive an official loan offer. This process usually takes no more than 10 minutes.  <br /> <br />In the meantime, you can provide your insurance and registration information. </p>
                                        <div className="continue-btn pt-3 d-flex align-items-end justify-content-start">
                                            <div className='pe-3'>
                                                <Button variant="primary " onClick={(e) => {
                                                    //continueHandleClick("step3")
                                                    navigate(`/home-shoping-hub/${customerRefId}`)

                                                }}>Continue <ArrowForwardIos /></Button>
                                            </div>

                                            <div className=''>
                                                <Button variant="secondary">Online Support <ChatBubbleOutlineOutlined /></Button>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                                : ''
                        }

                        {
                            isNextStep.id === 'step3' ?
                                <div className="perfpopright adjustment">
                                    <div className="fpi_mainheading mb-3">
                                        <h2>Your Financing Application <br />Was Successful!<Badge bg="success">APPROVED</Badge></h2>
                                        <p className='mb-3'>Congratulation. You have been approved for your financing terms by BMW Financial Services. You have also been approved from other lender(s). Please review your offers and select which best fits. </p>
                                        <div className='box tradeOpt_yourVehBox_content mb-3'>
                                            <div className='row align-items-center'>

                                                <div className='col-md-7'>
                                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                                        <Logo />
                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                            <div className="checkout_logo">
                                                                <h1 className="content-2--bold bold">BMW Financial Services</h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h3 className='bold mb-1'>$595/month</h3>
                                                    <h2 className='bold'>5.9% APR</h2>
                                                    <h2 className='bold'>60 Months </h2>
                                                </div>

                                                <div className='col-md-5 '>
                                                    <span className='pe-3 d-block'>
                                                        <img src={img1} alt='#'></img>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="continue-btn pt-3 d-flex align-items-end justify-content-start">
                                            <div className='pe-3'>
                                                <Button variant="primary " onClick={handleClose} >Accept Offers <ArrowForwardIos /></Button>
                                            </div>
                                            <div className=''>
                                                <Button variant="secondary">Online Support <ChatBubbleOutlineOutlined /></Button>
                                            </div>
                                        </div>
                                        <Button variant="link">View Other Offers</Button>
                                    </div>

                                </div>

                                : ''
                        }

                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default AreYouSureToSubmit;
