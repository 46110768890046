import { Button } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { Logout } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/iad_img1.png';
import img2 from '../../assets/images/iad_img2.png';
import img3 from '../../assets/images/iad_img3.png';
import img4 from '../../assets/images/saudi-graduate.png';


import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import PlusToggleBtn from '../../ScreensUi/DigitalCheckout/PlusToggleBtn';
import { useLocation } from 'react-router-dom';

const IncentivesAndDiscounts = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')
    const link = `${location.pathname}${location.search}`

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);

    // const handleStep = (e) => {
    //     setIsStep(e);
    //     console.log(isStep);
    //     if (isStep === 'step2_finalQuestion') {
    //         setIsDisabledNext(false);
    //     }
    // };



    return (
        <div>
            <div className="digital_checkout_wrapper">

                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">

                        <div className="po_header pb-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>
                        <div className='iad_content po_content_height'>
                            <div className='d-flex justify-content-between'>
                                <div className='po_headlines'>
                                    <h3 className='headline-5 light pb-1 d-flex align-items-center'>Incentives & Discounts</h3>
                                    <p className='label-2 light pb-3'>Choose one that applies to you. If you are not eligible for an incentive or discount, you can continue to the next step.</p>
                                </div>
                            </div>

                            <div className='iad_cards_list'>
                                <div className='row pb-4 pt-4'>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img1} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Loyalty Credit</h3>
                                                <p className='label-2 pb-1'>Applies to lease/finance contracts for 2023-2024 vehicle models.</p>
                                                <strong className='label-2 bold'>£ 219</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img2} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Military Sales Program</h3>
                                                <p className='label-2 pb-1'>Exclusive incentives for military members living at home or overseas.</p>
                                                <strong className='label-2 bold'>£ 1,220</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img3} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Mobility Program</h3>
                                                <p className='label-2 pb-1'>Enhance accessibility with rebates on adaptive equipment.</p>
                                                <strong className='label-2 bold'>£ 1,475</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img4} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1'>Graduate Discounts</h3>
                                                <p className='label-2 pb-1'>Recent graduates save on a new or used vehicles.</p>
                                                <strong className='label-2 bold'>£ 1,475</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DigitalCheckoutFooter pageUrl={`/VehicleAddOns?orderId=${orderID}&customerRefId=${customerRefId}`} />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default IncentivesAndDiscounts;