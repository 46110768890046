import { Button, Form } from 'react-bootstrap';

import { LightbulbOutlined, } from '@mui/icons-material';
const ReviewTermsAndOrder = ({
    activeState7, setActiveState8, activeState8, handleShow
}) => {
    return (
        <div className={activeState7.id === 'state7Saved' ? `rd_section mb-2 section-08` : 'd-none'}>
            <div className='row'>
                <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                    <h3 className='headline-6 light'><span className="text-secondary pe-3">08</span>Review & Accept Our Terms</h3>
                    <Button className="px-3 py-2" variant="outline-secondary" onClick={(e) => { setActiveState8("state8Notsaved") }}>Edit</Button>
                </div>
                <div className="col-md-9">

                    <div className={activeState8.id === 'state8Notsaved' ? `bd_box border input-Form-main mb-4` : ' d-none'} id="state8Notsaved">
                        <div className='form-check-wrap mb-4'>
                            <Form.Check // prettier-ignore
                                type={"checkbox"}
                                id={`default-${"checkbox"}`}
                                label={"Confirm that you accept our terms before submitting your application."}
                            />
                        </div>
                        {/* <label class="form-check-label" for="flexCheckDefault">
                                Confirm that you accept our terms before submitting your application.
                            </label> */}

                        <div className='d-flex align-items-end justify-content-start'>
                            <Button onClick={handleShow} variant='dark'>Save & Continue</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewTermsAndOrder