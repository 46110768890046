import { Alarm, ArrowForwardIos, KeyboardArrowDownOutlined, Language, LocationOn, Phone, RoomOutlined, Schedule, Star } from '@mui/icons-material';
import React, { useState } from 'react';

import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NeedsBasedAssesmentModel from './Models/NeedsBasedAssesmentModel';
import PreQualifySecondsModel from './Models/PreQualifySecondsModel';
import SavedVehiclesModel from './Models/SavedVehiclesModel';
import ShowHideFilterButton from "./ShowHideFilterButton";
import Toast from 'react-bootstrap/Toast';
import carIcon from '../assets/images/car_icon.svg';
import sortArrow from '../assets/images/sort_arrow.svg';

function FiltersHeader(props) {

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const handleCloseModal1 = () => setShowModal1(false);
  const handleShowModal1 = () => setShowModal1(true);

  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = () => setShowModal2(true);

  const handleCloseModal3 = () => setShowModal3(false);
  const handleShowModal3 = () => setShowModal3(true);

  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);


  function startFinanceFirst() {
    const DEALER_ID = 3218;
    const event = new CustomEvent("onFinanceFirstButtonClick", {
      detail: { state:true, popup: false, dealer_id:DEALER_ID, order_initiate_link:"https://drift.netsolapp.io/asset-details/"},
    })
    window.dispatchEvent(event);
  }

  return (
    <>
      <div className="filtersHeader">
        <div className="filtersHeader_inner d-flex align-items-center justify-content-between">
          <div className="filtersHeader_left">
            <ShowHideFilterButton handleClick={props.handleClick} isVisible={props.isVisible} />
          </div>
          <div className="filtersHeader_right d-flex align-items-center justify-content-end">
            <ul className='d-flex align-items-center justify-content-end flex-wrap'>
              <li>
                <Link className='filtersH_link'>
                  <Alarm className="d-block" />
                  <span>Prequalify In Seconds</span>
                </Link>
              </li>
              <li>
                <Link className='filtersH_link'>
                  <i><img src={carIcon} alt='#' onClick={startFinanceFirst}></img></i>
                  <span onClick={startFinanceFirst}>Finance First</span>
                </Link>
              </li>
              <li>
                <Link className='filtersH_link'>
                  <RoomOutlined className="d-block" />
                  <span> Nearby Dealers</span>
                  <KeyboardArrowDownOutlined className="d-block" />
                </Link>
              </li>
              <li>
                <Link className='filtersH_link'>
                  <span>Saved Vehicles</span>
                  <small className='number_badge'>1</small>
                </Link>
              </li>

            </ul>
          </div>
        </div>
      </div>
      {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
      <NeedsBasedAssesmentModel showModal={showModal2} handleCloseModal={handleCloseModal2} />
      <SavedVehiclesModel showModal={showModal3} handleCloseModal={handleCloseModal3} />
    </>
  )
}
export default FiltersHeader;