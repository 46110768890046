import dayjs from 'dayjs'

export const formatCurrency = (number) => {
  const currencySymbol = '£';
  const formattedNumber = Math.round(number).toLocaleString('en-US');

  return `${currencySymbol} ${formattedNumber}`;
}







export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'string' && !value.trim().length) ||
  (typeof value === 'object' && !Object.keys(value).length)

export const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/

export const formatPayloadDate = (date) => dayjs(date).format('YYYY-MM-DD')

export const isValidUrl = (url) => {
  return urlPattern.test(url)
}

export const getInitials = (fullName) => {
  return fullName.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
}

export const loadJsFile = (script_url = 'bundle.js') => {
  let unityPluginDiv=document.getElementById('unityPlugin');
  if(!unityPluginDiv){
    unityPluginDiv=document.createElement('div');
    unityPluginDiv.id="unityPlugin";
  }
  document.body.appendChild(unityPluginDiv)
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = script_url;
  head.appendChild(script);
}