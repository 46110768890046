import React, { useState } from 'react';

import Icon from '@mui/material/Icon';
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Models from './Models/Models';

function HeaderMenu() {

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div className="header_menu">
      <ul className="d-flex flex-wrap">
        <li>
          <Link>Models</Link>
        </li>
        <li>
          <Link>Existing Deals</Link>
        </li>
        <li>
          <Link>Order List</Link>
        </li>
        <li className="has_dropdown">
          <Link>Owners</Link>
        </li>
      </ul>

      <Models show={showModal} handleClose={handleClose} />

    </div>
  )
}

export default HeaderMenu;