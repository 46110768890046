import { Modal } from 'react-bootstrap';
import React from 'react';

import img from '../../assets/images/svh_pop_img.png';
import { Link } from 'react-router-dom';

const SavedVehiclesModel = ({ showModal, handleCloseModal }) => {

    return (
        <div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" className='modal_has_leftImg'>
                <Modal.Body className='p-0'>
                    {/* Add your modal content here */}
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 mg_svm-leftcontent p-0">
                                <div className="mg_modal-left position-relative">
                                    <img src={img} alt="#"></img>
                                    <div className="mg_modal-left-content position-absolute">
                                        <h1>BUILD OUT YOUR <span className="d-block">BMW <b>GARAGE</b></span></h1>
                                        <ul className="px-3">
                                            <li>Save as many vehicles as you want</li>
                                            <li>Get tracking and live updates on your favorite vehicles</li>
                                            {/* <li>View smart recommendations and find your perfect BMW</li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="svh_modal_content p-4">
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <h2>Provide Your Information</h2>
                                            <h3>We'll need a few details to  create your  account
                                            </h3>
                                            <div className="px-0 pt-4">
                                                <form className="row g-2">
                                                    <div className="col-lg-6 col-md-12 form-floating">
                                                        <label className="form-label py-1">First name</label>
                                                        <input type="text" className="form-control border border-success border-opacity-10 rounded-0" />
                                                    </div>
                                                    <div className="col-lg-6 col-md-12 form-floating">
                                                        <label className="form-label py-1">Last name</label>
                                                        <input type="text" className="form-control border border-success border-opacity-10 rounded-0" />
                                                    </div>
                                                    <div className="col-lg-8 col-md-12 form-floating">
                                                        <label className="form-label py-1">Email Address</label>
                                                        <input type="text" className="form-control border border-success border-opacity-10  rounded-0" />
                                                    </div>
                                                    <div className="col-lg-4 col-md-12 form-floating">
                                                        <label className="form-label py-1">Zip  Code</label>
                                                        <input type="text" className="form-control border border-success border-opacity-10 rounded-0" />
                                                    </div>
                                                    <div className="col-md-12 form-floating">
                                                        <label className="form-label py-1"
                                                        >Password</label>
                                                        <input type="text" className="form-control border border-success border-opacity-10 rounded-0" />
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-check d-flex align-items-center">
                                                            <input className="form-check-input" type="checkbox" value="" />
                                                            <label className="form-check-label ps-2 pt-1">
                                                                I have read and accept the <span className=" text-decoration-underline fw-bold">*Terms and Conditions*</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 py-4">
                                                        <div>
                                                            <Link to={"/HomeShopingHub"} className='d-block w-100 mb-2'>
                                                                <button className="btn btn-primary d-block w-100" type="button">Create Account</button>
                                                            </Link>
                                                            <button className="btn btn-secondary d-block w-100" type="button" data-bs-dismiss="modal">Back to Inventory</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer d-flex justify-content-center">
                                                <span className="mg_modal-custom-text"> Already have a MyBMW account?<Link className="mg_signin-btn"> Sign in</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );
};

export default SavedVehiclesModel;