import React, { useContext } from 'react'
import { Link } from "react-router-dom";

import { CloseOutlined, Search } from "@mui/icons-material";
import { Form } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import FiltersSwitch from '../../Components/FiltersSwitch/FiltersSwitch'

import bodyStyleIcon1 from '../../assets/images/icon-gran-coupe.svg';
import { Context } from '../../utilities'

const CroseLabel = () => {
  const { state, dispatch } = useContext(Context);
  return (
    <div className="cross_label_main">
      <div className="cross_label">
        <ul>

          {
            state.selectedSeries?.map((item, index) => {
              return (
                <li key={index}>
                  <label>{item}
                    <i onClick={() => dispatch({ type: 'REMOVE_SERIES', payload: item })}>
                      <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                      </svg>
                    </i>
                  </label>
                </li>
              )
            })
          }

          {
            state.selectedModel &&
            <li>
              <label>{state.selectedModel}
                <i onClick={() => dispatch({ type: 'REMOVE_MODEL' })}>
                  <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                  </svg>
                </i>
              </label>
            </li>
          }

          {
            state.selectedBodyStyle &&
            <li>
              <label>{state.selectedBodyStyle}
                <i onClick={() => dispatch({ type: 'REMOVE_BODY_STYLE' })}>
                  <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                  </svg>
                </i>
              </label>
            </li>
          }

          {
            state.minSelectedPrice !== 0 &&
            <li>
              <label>{state.minSelectedPrice}
                <i onClick={() => dispatch({ type: 'REMOVE_MIN_PRICE' })}>
                  <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                  </svg>
                </i>
              </label>
            </li>
          }

          {
            state.maxSelectedPrice !== 0 &&
            <li>
              <label>{state.maxSelectedPrice}
                <i onClick={() => dispatch({ type: 'REMOVE_MAX_PRICE' })}>
                  <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                  </svg>
                </i>
              </label>
            </li>
          }

          {
            state.minSelectedYear !== 0 &&
            <li>
              <label>{state.minSelectedYear}
                <i onClick={() => dispatch({ type: 'REMOVE_MIN_YEAR' })}>
                  <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                  </svg>
                </i>
              </label>
            </li>
          }

          {
            state.maxSelectedYear !== 0 &&
            <li>
              <label>{state.maxSelectedYear}
                <i onClick={() => dispatch({ type: 'REMOVE_MAX_YEAR' })}>
                  <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                  </svg>
                </i>
              </label>
            </li>
          }

          {
            state.selectedExteriorColor?.map((item, index) => {
              return (
                <li key={index}>
                  <label>{item}
                    <i onClick={() => dispatch({ type: 'REMOVE_EXTERIOR_COLOR', payload: item })}>
                      <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                      </svg>
                    </i>
                  </label>
                </li>
              )
            })
          }

          {
            state.selectedPackages?.map((item, index) => {
              return (
                <li key={index}>
                  <label>{item}
                    <i onClick={() => dispatch({ type: 'REMOVE_PACKAGE', payload: item })}>
                      <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                      </svg>
                    </i>
                  </label>
                </li>
              )
            })
          }

          {
            state.selectedOptions?.map((item, index) => {
              return (
                <li key={index}>
                  <label>{item}
                    <i onClick={() => dispatch({ type: 'REMOVE_OPTION', payload: item })}>
                      <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                      </svg>
                    </i>
                  </label>
                </li>
              )
            })
          }

          {
            state.selectedfuelTypes?.map((item, index) => {
              return (
                <li key={index}>
                  <label>{item}
                    <i onClick={() => dispatch({ type: 'REMOVE_FUEL_TYPE', payload: item })}>
                      <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                      </svg>
                    </i>
                  </label>
                </li>
              )
            })
          }

          {
            state.selectedDrivetrains?.map((item, index) => {
              return (
                <li key={index}>
                  <label>{item}
                    <i onClick={() => dispatch({ type: 'REMOVE_DRIVETRAIN', payload: item })}>
                      <svg aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon" tabIndex="-1" title="Close">
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill="#fff"></path>
                      </svg>
                    </i>
                  </label>
                </li>
              )
            })
          }

        </ul>
      </div>
      <div className="cross_label_clear">
        <Link onClick={() => dispatch({ type: 'CLEAR_SERIES' })}>Clear All</Link>
      </div>
    </div>
  )
}

const SeriesAndModels = () => {
  const { state, dispatch } = useContext(Context);
  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>Make</Accordion.Header>
      <Accordion.Body>
        <span className="acc_title">SERIES</span>
        <div className="fs_model_series_grid">
          {
            state.series?.map((item, index) => {
              return (
                <label key={index} className="fs_model_seriesselect" onClick={() => {
                  dispatch({ type: 'ADD_SERIES', payload: item })
                }}>
                  {item}
                </label>
              )
            })
          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const BodyStyles = () => {
  const { state, dispatch } = useContext(Context);
  return (
    <Accordion.Item eventKey="1">
      <Accordion.Header>Body Style</Accordion.Header>
      <Accordion.Body>
        <span className="acc_title">Select body style</span>
        <div className="fs_bodystyle">
          {
            state.bodyStyles?.map((item, index) => {
              return (
                <label key={index} className="fs_bodystyle_label" onClick={() => {
                  dispatch({ type: 'SELECT_BODY_STYLE', payload: item.label })
                }}>
                  <i className="fs_bodystyle_img">
                    <img src={bodyStyleIcon1} alt="#"></img>
                  </i>
                  <span className="label-3--bold">{item.label}</span>
                </label>
              )
            })
          }
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const Price = () => {
  const { state, dispatch } = useContext(Context);
  return (
    <Accordion.Item eventKey="2">
      <Accordion.Header>Price</Accordion.Header>
      <Accordion.Body>
        <div className="d-flex align-items-center">
          <div className="col">
            <span className="acc_title mb-1">MIN</span>
            <Form.Select value={state.minSelectedPrice} onChange={(x) => {
              if (x.target.value === 'Select') {
                dispatch({ type: 'REMOVE_MIN_PRICE' })
                return
              }
              dispatch({ type: 'SELECT_MIN_PRICE', payload: x.target.value })
            }}>
              {
                state.priceOptions?.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>{item.label}</option>
                  )
                })
              }
            </Form.Select>
          </div>
          <div className="ps-2 pe-2 pt-2">
            <span className="label-3--bold">-</span>
          </div>
          <div className="col">
            <span className="acc_title  mb-1">MAX</span>
            <Form.Select value={state.maxSelectedPrice} onChange={(x) => {
              if (x.target.value === 'Select') {
                dispatch({ type: 'REMOVE_MAX_PRICE' })
                return
              }
              dispatch({ type: 'SELECT_MAX_PRICE', payload: x.target.value })
            }}>
              {
                state.priceOptions?.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>{item.label}</option>
                  )
                })
              }
            </Form.Select>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const Year = () => {
  const { state, dispatch } = useContext(Context);
  return (
    <Accordion.Item eventKey="3">
      <Accordion.Header>Year</Accordion.Header>
      <Accordion.Body>
        <div className="d-flex align-items-center">
          <div className="col">
            <span className="acc_title mb-1">MIN</span>
            <Form.Select value={state.minSelectedYear} onChange={(x) => {
              if (x.target.value === 'Select') {
                dispatch({ type: 'REMOVE_MIN_YEAR' })
                return
              }
              dispatch({ type: 'SELECT_MIN_YEAR', payload: x.target.value })
            }}>
              {
                state.yearOptions?.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>{item.label}</option>
                  )
                })
              }
            </Form.Select>
          </div>
          <div className="ps-2 pe-2 pt-2">
            <span className="label-3--bold">-</span>
          </div>
          <div className="col">
            <span className="acc_title  mb-1">MAX</span>
            <Form.Select value={state.maxSelectedYear} onChange={(x) => {
              if (x.target.value === 'Select') {
                dispatch({ type: 'REMOVE_MAX_YEAR' })
                return
              }
              dispatch({ type: 'SELECT_MAX_YEAR', payload: x.target.value })
            }}>
              {
                state.yearOptions?.map((item, index) => {
                  return (
                    <option key={index} value={item.value}>{item.label}</option>
                  )
                })
              }
            </Form.Select>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const ExteriorColor = () => {
  const { state, dispatch } = useContext(Context);
  return (
    <Accordion.Item eventKey="4">
      <Accordion.Header>  Exterior Color  </Accordion.Header>
      <Accordion.Body>
        {
          state.exteriorColorOptions?.map((item, index) => {
            return (
              <div key={index} className="pb-3">
                <label className="has_checkbox_row">
                  <Form.Check type="checkbox" checked={state.selectedExteriorColor.includes(item.label)}
                    onChange={(x) => {
                      if (x.target.checked) {
                        dispatch({ type: 'SELECT_EXTERIOR_COLOR', payload: item.label })
                        return
                      }
                      dispatch({ type: 'REMOVE_EXTERIOR_COLOR', payload: item.label })
                    }}
                  ></Form.Check>
                  <span className={`has_coloricon`}>
                    <i className={`coloricon ${item.cssClass}`}></i>
                    {item.label}
                  </span>
                </label>
              </div>
            )
          })
        }
      </Accordion.Body>
    </Accordion.Item>
  )
}

export const SearchFilters = (props) => {
  const { state, dispatch } = useContext(Context);

  return (
    <div className="filter_drawer">
      <button className='filter_close_mob' onClick={props.handleClick}><CloseOutlined /></button>
      <div className="filter_drawer_inner">
        {
          state.selectedSeries.length > 0 ||
            state.selectedModel.length > 0 ||
            state.selectedBodyStyle ||
            state.minSelectedPrice ||
            state.maxSelectedPrice ||
            state.minSelectedYear ||
            state.maxSelectedYear ||
            state.selectedExteriorColor.length > 0 ||
            state.selectedPackages.length > 0 ||
            state.selectedOptions.length > 0 ||
            state.selectedfuelTypes.length > 0 ||
            state.selectedDrivetrains.length > 0
            ? <CroseLabel /> : ''
        }
        <FiltersSwitch />
        <Accordion defaultActiveKey={['']} alwaysOpen>
          <SeriesAndModels />
          <Price />
          <Year />
          <ExteriorColor />
        </Accordion>

        <div className="search_vin_section">
          <div className="row pb-3">
            <div className="col-12">
              <span className="acc_title bold mb-2">Search by VIN</span>
              <div className="search_input_container">
                <div className="search_vin_icon"><Search /></div>
                <Form.Control
                  type="text"
                  placeholder="7FXVB9XXXXXXXX..."
                  value={state.vin}
                  onChange={(x) => { dispatch({ type: 'SET_VIN', payload: x.target.value }) }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mob_filter_overlay' onClick={props.handleClick}></div>
    </div>
  )
}
