import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Price } from '../../../utilities'
import { context } from '../PricingDetail/PricingDetail'

import { Accordion, Form } from "react-bootstrap";
import styles from "./MoreWaysAccordian.module.css";

import { useMultipleQueriesForMoreWays } from '../../../hooks'

function MoreWaysAccordian({ recalculate }) {
	const { state, dispatch } = useContext(context);
	const [activeButton, setActiveButton] = useState();

	const result = useMultipleQueriesForMoreWays()
	const { data: OPTION_TERMS, isError: OPTION_TERMS_IS_ERROR, isFetched: OPTION_TERMS_IS_FETCHED } = result[0];
	const { data: DEALER_FEE, isError: DEALER_FEE_IS_ERROR, isFetched: DEALER_FEE_IS_FETCHED } = result[1];
	const { data: VEHICLE_DETAIL, isError: VEHICLE_DETAIL_IS_ERROR, isFetched: VEHICLE_DETAIL_IS_FETCHED } = result[2];

	const CONSOLE_LOG = () => {
		console.log('OPTION_TERMS: ', OPTION_TERMS)
		console.log('DEALER_FEE: ', DEALER_FEE)
		console.log('VEHICLE_DETAIL: ', VEHICLE_DETAIL)
	};

	if (
		OPTION_TERMS_IS_ERROR ||
		DEALER_FEE_IS_ERROR ||
		VEHICLE_DETAIL_IS_ERROR
	) {
		return <div className='text-center'>Error in fetching data</div>
	}

	if (
		!OPTION_TERMS_IS_FETCHED ||
		!DEALER_FEE_IS_FETCHED ||
		!VEHICLE_DETAIL_IS_FETCHED
	) {
		return <div className='text-center'>Loading...</div>
	}

	const handleButtonClick = (button) => {
		setActiveButton(button);
		console.log('state: ', state)
	};

	return (
		<Accordion className="transparent_acc accordian_pad0" defaultActiveKey={['']} alwaysOpen>
			<Accordion.Item eventKey="0">
				<Accordion.Header>Estimated Trade-In Value</Accordion.Header>
				<Accordion.Body>
					<span className="acc_title bold">Are you trading in a vehicle? </span>
					<div className={`${styles.tradein_yesno} fs_model_series_grid`}>
						<label className={`fs_model_seriesselect ${activeButton === 'button1' ? 'active' : ''}`} onClick={() => handleButtonClick('button1')}>
							Yes
						</label>
						<label className={`fs_model_seriesselect ${activeButton === 'button2' ? 'active' : ''}`} onClick={() => {
							dispatch({ type: 'RESET_TRADE_IN', payload: 0 })
							handleButtonClick('button2')
						}}>
							No
						</label>
					</div>

					{
						activeButton === 'button1' ?
							<div className={styles.tradein_yes}>
								<p className="label-2 bold pb-2">Enter your value below</p>
								<div className="d-flex flex-wrap align-items-center pb-3">
									<div className="col-lg-6">
										<span className="acc_title mb-0 pe-2 d-flex align-items-center">TRADE-IN VALUE
											<span className='msrp_tip'>

											</span>
										</span>
									</div>
									<div className="col-lg-6">
										<Form.Control type="text" placeholder="" value={state.TRADE_IN_AMOUNT}
											onChange={(e) => {
												dispatch({ type: 'SET_TRADE_IN_AMOUNT', payload: parseFloat(e.target.value ? e.target.value : 0) })
											}}
											onBlur={(e) => { recalculate() }}
										/>
									</div>
								</div>
								<div className="d-flex flex-wrap align-items-center pb-3">
									<div className="col-lg-6">
										<span className="acc_title mb-0 pe-2 d-flex align-items-center">REMAINING BALANCE
											<span className='msrp_tip'>

											</span>
										</span>
									</div>
									<div className="col-lg-6">
										<Form.Control type="text" placeholder="" value={state.REMAINING_VALUE}
											onChange={(e) => {
												dispatch({ type: 'SET_REMAINING_VALUE', payload: parseFloat((e.target.value ? e.target.value : 0)) })
											}}
											onBlur={(e) => { recalculate() }}
										/>
									</div>
								</div>
								<div className="d-flex flex-wrap align-items-center pb-3">
									<div className="col-lg-6">
										<span className="acc_title mb-0 pe-2 d-flex align-items-center">TRADE-IN EQUITY
											<span className='msrp_tip'>

											</span>
										</span>
									</div>
									<div className="col-lg-6">
										<p className='label-2 bold pb-0'><Price value={state.TRADE_IN_EQUITY} /></p>
									</div>
								</div>

							</div> :
							null
					}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1">
				<Accordion.Header>Dealer Fees</Accordion.Header>
				<Accordion.Body>
					{
						// filter based on DEALER_FEE[].applicable_finance_type contain the state.PROGRAM then show the DEALER_FEE
						DEALER_FEE.filter((x) => x.applicable_finance_type.includes(state.PROGRAM)).map((item, index) => {
							return (
								<div className="d-flex flex-wrap align-items-center pb-3" key={index}>
									<div className="col-lg-8">
										<label className="has_checkbox_row">
											<span className="has_coloricon">
												{item.fee_name}
											</span>
										</label>
									</div>
									<div className="col-lg-4">
										<p className='label-2 bold pb-0'><Price value={item.default_amount} /></p>
									</div>
								</div>
							)
						})
					}
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header>Accessories</Accordion.Header>
				<Accordion.Body>
					<span className="acc_title bold">You may qualify for offers and incentives. Select all that apply. </span>
					{
						// filer OPTION_TERMS[].id contain the VEHICLE_DETAIL.dealer_options[].id then show the OPTION_TERMS
						OPTION_TERMS.filter((x) => VEHICLE_DETAIL.dealer_options.map((y) => y.id).includes(x.id)).map((item, index) => {
							// dispatch({ type: 'SET_ACCESSORIES_WITH_ZERO_VALUE', payload: item })
							return (
								<div className="d-flex flex-wrap align-items-center pb-3" key={index}>
									<div className="col-lg-8">
										<label className="has_checkbox_row">
											<span className="has_coloricon">
												{item.product_name}
											</span>
										</label>
									</div>
									<div className="col-lg-4">
										<p className='label-2 bold pb-0'>
											<Price value={0} />
										</p>
									</div>
								</div>
							)
						})
					}

					{
						// filer OPTION_TERMS[].id not contain the VEHICLE_DETAIL.dealer_options[].id then show the OPTION_TERMS
						OPTION_TERMS.filter((x) => !VEHICLE_DETAIL.dealer_options.map((y) => y.id).includes(x.id)).map((item, index) => {
							return (
								<div className="d-flex flex-wrap align-items-center pb-3" key={index}>
									<div className="col-lg-8">
										<label className="has_checkbox_row">
											<Form.Check type="checkbox" onChange={(x) => {
												if (x.target.checked) {
													dispatch({ type: 'SET_ACCESSORIES_WITH_ACTUAL_VALUE', payload: item })
												} else {
													dispatch({ type: 'REMOVE_ACCESSORIES_WITH_ACTUAL_VALUE', payload: item })
												}
											}}>
											</Form.Check>
											<span className="has_coloricon">
												{item.product_name}
											</span>
										</label>
									</div>
									<div className="col-lg-4">
										<p className='label-2 bold pb-0'>
											<Price value={item.price} />
										</p>
									</div>
								</div>
							)
						})
					}
				</Accordion.Body>
			</Accordion.Item>

			<Accordion.Item eventKey="3">
				<Accordion.Header>F & I Products</Accordion.Header>
				<Accordion.Body>
					{
						(state.PROGRAM !== 'Lease' ? state.FNI_PRODUCTS_FINANCE : state.FNI_PRODUCTS_LEASE).map((item, index) => {
							return (
								<div className="d-flex flex-wrap align-items-center pb-3" key={index}>
									<div className="col-lg-8">
										<label className="has_checkbox_row">
											<Form.Check type="checkbox" onChange={(x) => {
												if (x.target.checked) {
													dispatch({ type: state.PROGRAM === 'Lease' ? 'ADD_FNI_PRODUCTS_LEASE_SELECTED' : 'ADD_FNI_PRODUCTS_FINANCE_SELECTED', payload: item })
												} else {
													dispatch({ type: state.PROGRAM === 'Lease' ? 'REMOVE_FNI_PRODUCTS_LEASE_SELECTED' : 'REMOVE_FNI_PRODUCTS_FINANCE_SELECTED', payload: item })
												}
											}}
											checked={
												state.PROGRAM === 'Lease' ? 
												state.FNI_PRODUCTS_LEASE_SELECTED.map((x) => x.product_id).includes(item.product_id) :
												state.FNI_PRODUCTS_FINANCE_SELECTED.map((x) => x.product_id).includes(item.product_id) 
											}	
											>
											</Form.Check>
											<span className="has_coloricon">
												{item.product_name}
											</span>
										</label>
									</div>
									<div className="col-lg-4">
										<p className='label-2 bold pb-0'>
											<Price value={item.applied_price} />
										</p>
									</div>
								</div>
							)
						})
					}
				</Accordion.Body>
			</Accordion.Item>

		</Accordion>
	)
}

export default MoreWaysAccordian;
