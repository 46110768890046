import React from "react";
import { useParams } from 'react-router-dom'
import styles from "./SummaryDetails.module.css";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { KeyboardArrowRightOutlined } from "@mui/icons-material";
import GridItemCard from "../../../Components/GridItemCard/GridItemCard";

import { useAsset, useAssets } from '../../../hooks'

function SummaryDetails() {

  const { vin } = useParams();

  const { data: asset, isLoading, isFetching, isError, error } = useAsset(vin)
  const {
    data: assets,
    isLoading: isAssetsLoading,
    isFetching: isAssetsFetching,
    isError: isAssetsError,
    error: AssetsError
  } = useAssets()

  if (isLoading || isFetching)
    return <div>Loading...</div>

  if (isError)
    return <div>Error: {error.message}</div>

  return (
    <div className={styles.summary_detail}>
      <div className={`${styles.seriesheader} d-flex justify-content-between`}>
        <div className={styles.seriesheader_left}>
          <h3 className="headline-5 bold">{asset.year} {asset.make} {asset.model}</h3>
          <small className="label-1">{asset.trim_description} {asset.body_type}</small>
        </div>
        <div className={styles.seriesheader_right}>
          <Link className={`${styles.print_link} d-flex align-items-center label-2 bold blue_hover mt-1`}>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" svg-inline="svg-inline" svg-sprite="svg-sprite" role="presentation" focusable="false" tabIndex="-1" className="detail-summary_printer_1Xgki"><g fill="#1C69D3" fillRule="evenodd"><path d="M18.999 4H1.001C.451 4 0 4.45 0 5.001v5.998C0 11.55.451 12 1.001 12h17.998c.55 0 1.001-.45 1.001-1.001V5.001C20 4.45 19.549 4 18.999 4zM1.5 10.5h17v-5h-17v5z"></path><path d="M14.999 10.5H5.001c-.55 0-1.001.45-1.001 1.001v7.498C4 19.55 4.451 20 5.001 20h9.998c.55 0 1.001-.45 1.001-1.001v-7.498c0-.551-.451-1.001-1.001-1.001zm-9.499 8h9V12h-9v6.5zM14 0H5.001C4.451 0 4 .413 4 .918v3.664c0 .505.451.918 1.001.918h9.998c.55 0 1.001-.413 1.001-.918V1.833L14 0zm-.583 1.5l1.083.993V4h-9V1.5h7.917z"></path><path d="M13.25 17h-6.5a.752.752 0 01-.75-.75c0-.412.337-.75.75-.75h6.5c.413 0 .75.338.75.75s-.337.75-.75.75M9.25 14.5h-2.5a.752.752 0 01-.75-.75c0-.412.337-.75.75-.75h2.5c.413 0 .75.338.75.75s-.337.75-.75.75M14.25 8.5h-2.5a.752.752 0 01-.75-.75c0-.412.337-.75.75-.75h2.5c.413 0 .75.338.75.75s-.337.75-.75.75"></path></g></svg>
            <span>Print Features</span>
          </Link>
        </div>
      </div>
      <div className="cpo-section-header"><div className="headline-5 regular">Summary</div></div>

      <div className={styles.summary_items}>
        <ul>
          <li>
            <h4>Interior</h4>
            <p>{asset.interior_color_description}</p>
          </li>
          <li>
            <h4>EXTERIOR</h4>
            <p>{asset.exterior_color_description}</p>
          </li>
          <li>
            <h4>ENGINE</h4>
            <p>{asset.engine_description}</p>
          </li>
          <li>
            <h4>FUEL TYPE </h4>
            <p>{asset.fuel_type}</p>
          </li>
          <li>
            <h4>DRIVETRAIN</h4>
            <p>{asset.drive_train}</p>
          </li>
          <li>
            <h4>TRIM</h4>
            <p>{asset.trim_description}</p>
          </li>
          <li>
            <h4>VIN # </h4>
            <p>{asset.vin}</p>
          </li>
        </ul>
      </div>

      <div className={styles.options_detail}>
        <div className="headline-5 pb-1 mb-3">Options &amp; Packages</div>

        <Accordion className='summary_accordian' defaultActiveKey={['']} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header className="label-1"> All Options </Accordion.Header>
            <Accordion.Body>
             
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header className="label-1"> All Packages </Accordion.Header>
            <Accordion.Body>
              
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className={styles.textBtnRow}>
          <Link className="text_arrow_btn">
            Convenience Package
            <KeyboardArrowRightOutlined />
          </Link>
        </div>

      </div>

      <div className="cpo-section-header pt-5">
        <div className="headline-5 regular pt-2">Similar Vehicles </div>
      </div>

      <div className="vehicles_list_items">
        <ul className="vehicles_list_ul">
          {/* display maximum 3 items */}
          {assets && assets.filter(x => x.vin !== vin).map((asset, index) => (
            index < 3 && <li key={index}>
              <GridItemCard key={asset.vin} item={asset} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )

}
export default SummaryDetails;