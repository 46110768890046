import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { KeyboardArrowDownOutlined, EventNoteOutlined, CompareArrows, MoreVert, FiberManualRecord, KeyboardArrowRight } from '@mui/icons-material';

import Toast from 'react-bootstrap/Toast';
import { useGetVehicleDetailsFromVin } from '../../hooks'

import { formatCurrency } from '../../utilities'
import { useParams, useLocation } from 'react-router-dom';
import cubeicon from '../../assets/images/cube.svg';

const InventoryItem = ({ quotation }) => {
    const { customerRefId } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get('orderId');

    const { data: vehicleDetail } = useGetVehicleDetailsFromVin(quotation?.vin)

    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);
    const [isSigned, setIsSigned] = useState(false);
    const toggleShowA = () => {
        setShowA(!showA);
        setShowB(false);
    };

    const toggleShowB = () => {
        setShowB(!showB);
        setShowA(false);
    };

    useEffect(() => {
        const isDocSigned = quotation?.Order?.documents?.find(document => ((document?.document_name ?? '')?.includes('BMW_Financial_Service') && (document?.document_signed_url ?? '')?.length > 0))
        if (isDocSigned) {
            setIsSigned(true)
        }

    }, [quotation])

    const quotationVehicleName = `${quotation?.year} ${quotation?.make} ${quotation?.model} ${quotation?.trim_description}`
    const vehicleDescription = vehicleDetail?.dealer_options?.[0]?.description?.length > 0
        ? vehicleDetail?.dealer_options?.[0]?.description
            .split(/\n|\.\n/) // Split the input string by newline
            .map((item) => item.trim()) // Trim leading/trailing white spaces
            .filter((item) => item !== '') // Filter out empty lines
            .join(', ') // Join the non-empty lines with a comma and space
        : ''

    const orderFeesSum = quotation?.Order?.order_fees?.length > 0
        ? quotation?.Order?.order_fees?.reduce((sum, fee) => sum + fee?.applied_price, 0)
        : 0

    return (
        <div className="col-12 mg_shopinghub-ctr mg_shopinghub-hover p-0" >
            <div className="d-flex flex-row mg_hs-flex">
                <div className="mg_img-ctr flex-shrink-0">
                    <div className="mg_shopinghubleft">
                        <div className="mg_ep-imgcontent">
                            <img className="mg_img" src={quotation?.photo_urls?.[0]?.location} alt='#'></img>
                        </div>
                    </div>
                </div>
                <div className="flex-grow-1 mg_shopinghubright-content mg_content-hover">
                    <div className="d-flex justify-content-between border-bottom mg_hs-content">
                        <div className="mg_shopinghubmiddle">
                            <div className="col-12">

                                <Link className="mg_shoppinghub-text py-2">
                                    {quotationVehicleName}
                                </Link>
                                <p className="content-3 py-2">{vehicleDescription}</p>
                                <ul className="d-flex flex-row py-2">

                                    <li className="me-4">
                                        <Link className="d-flex align-items-center header_location filtersH_link">
                                            <FiberManualRecord className="text-success" />
                                            <span className="label-2 bold">At Dealership</span>
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="mg_shopinghubright">
                            <div className="d-flex flex-column py-4">
                                <ul className="d-flex justify-content-end align-items-center pb-4">

                                    <li className="me-3 border">
                                        <Link className="d-flex align-items-center header_location filtersH_link">
                                            <EventNoteOutlined />

                                            <span>Trade-in</span>

                                        </Link>
                                    </li>
                                    <li className="me-3 border">
                                        <Link className="d-flex align-items-center header_location filtersH_link">
                                            <CompareArrows />
                                            <span>Compare</span>

                                        </Link>
                                    </li>
                                    <li className="me-3 border">
                                        <Link className="d-flex align-items-center header_location filtersH_link">

                                            <MoreVert className="d-block" />

                                        </Link>
                                    </li>

                                </ul>
                                <div className="d-flex justify-content-between mb-3 position-relative mg_toast-width">

                                    <div >
                                        <small className="d-flex align-items-center eyebrow-2">Total Price  <span className='msrp_tip'>

                                        </span></small>
                                        <div>
                                            <Link onClick={toggleShowA} className="d-flex align-items-center header_location filtersH_link">
                                                <h2 className="headline-6 bold">{formatCurrency(quotation?.Order?.selling_price)}</h2>

                                                <KeyboardArrowDownOutlined className="d-block" />

                                            </Link>
                                            <Toast show={showA} onClose={toggleShowA} className="mg_homeshoping-toast">
                                                <Toast.Header>

                                                    <strong className="me-auto"></strong>

                                                </Toast.Header>
                                                <Toast.Body>
                                                    <div className='d-flex flex-column p-3'>
                                                        <div className='d-flex flex-column align-items-start'>
                                                            <Link className='filtersH_link mb-2'>

                                                                <span className="label-1 bold">Your Price breakdown:</span>
                                                            </Link>
                                                        </div>
                                                        <div className="">
                                                            <div className='d-flex justify-content-between content-3'>
                                                                <div>Selling Price</div>
                                                                <div>{formatCurrency(quotation?.Order?.selling_price)}</div>
                                                            </div>

                                                            <div className='d-flex justify-content-between content-3 py-1'>
                                                                <div>Cash Down</div>
                                                                <div>{quotation?.Order?.down_payment}%</div>                                                            </div>

                                                            <div className='d-flex justify-content-between content-3 py-1'>
                                                                <div>Dealer Adjustment</div>
                                                                <div>{formatCurrency(0)}</div>
                                                            </div>
                                                            <div className='d-flex justify-content-between content-3 py-1'>
                                                                <div>Dealer-Installed Accessories</div>
                                                                <div>{formatCurrency(0)}</div>
                                                            </div>
                                                            <div className='d-flex justify-content-between content-3 py-1 border-bottom pb-2'>
                                                                <div>Estimated Taxes & Fees</div>
                                                                <div>{formatCurrency(orderFeesSum)}</div>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <div className="m-auto b py-3">
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className="label-2 bold">Monthly Payment</div>
                                                                    <div className="label-2"><b>{formatCurrency(quotation?.Order?.estimated_monthly_payment)}</b>/month</div>
                                                                </div>

                                                                <div className='d-flex justify-content-between'>
                                                                    <div className="pe-2 small">Estimated Total</div>
                                                                    <div className=' small'>{formatCurrency(quotation?.Order?.selling_price)}</div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div>
                                                            <button className="product_list_btn ps-3 pe-3 w-100 btn btn-secondary">See Details</button>
                                                        </div>
                                                    </div>

                                                </Toast.Body>
                                            </Toast>
                                        </div>

                                    </div>

                                    <div>
                                        <small className="d-flex align-items-center eyebrow-2">Estimated Payment <span className='msrp_tip'>

                                        </span></small>
                                        <div>
                                            <Link onClick={toggleShowB} className="d-flex align-items-center header_location filtersH_link">

                                                <h2 className="headline-6 bold">{formatCurrency(quotation?.Order?.estimated_monthly_payment)}<small>/month</small></h2>
                                                <KeyboardArrowDownOutlined className="d-block" />
                                            </Link>
                                            <Toast show={showB} onClose={toggleShowB} className="mg_homeshoping-toast2">
                                                <Toast.Header>

                                                    <strong className="me-auto"></strong>

                                                </Toast.Header>
                                                <Toast.Body>
                                                    <div className=''>
                                                        <div className='d-flex flex-column p-3'>
                                                            <div className=''>
                                                                <div className='d-flex flex-column align-items-start'>
                                                                    <Link className='filtersH_link mb-2'>

                                                                        <span className="label-1 bold">Your Payment Terms</span>
                                                                    </Link>
                                                                </div>
                                                                <div className="" style={{ width: '80%' }}>
                                                                    <div className='d-flex justify-content-between content-2 border-bottom pb-2'>
                                                                        <ul className="content-3 py-2">
                                                                            <li>{quotation?.Order?.contract_term} Month(s)</li>
                                                                            <li>{quotation?.Order?.down_payment}% down</li>
                                                                            {/* <li>{quotation?.allowed_usage_value} annual Miles</li> */}
                                                                            <li>Credit Score: {quotation?.credit_rating_description}</li>
                                                                        </ul>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div>
                                                                <button className="product_list_btn ps-3 pe-3 w-100 btn btn-secondary">Update Deals</button>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Toast.Body>
                                            </Toast>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="shopinghub-footer d-flex justify-content-between p-3">
                        <div className="d-flex align-items-center header_location filtersH_link">
                            <Link className="label-1 bold header_location filtersH_link">Schedule Test Drive
                                <KeyboardArrowRight className="d-block" /></Link>
                        </div>
                        <div className="d-flex align-items-center">
                            {quotation?.Order?.status === 'Draft'
                                ? (
                                    // <Link to={`/FinancingPage?orderId=${quotation?.Order?.reference_number}&customerRefId=${customerRefId}&vin=${quotation?.vin}`}>
                                    <Link to={`/DigitalCheckout?orderId=${quotation?.Order?.reference_number}&customerRefId=${customerRefId}&vin=${quotation?.vin}`}>
                                        <button type="button" className="product_list_btn ps-3 pe-3 w-100 btn btn-primary">Start Checkout</button>
                                    </Link>
                                )
                                : <>
                                    <h5 className="bold mx-3">{quotation?.Order?.status}</h5>
                                    {
                                        (quotation?.Order?.status === 'Approved' && !isSigned)
                                            ? <Link to={`/Insurance?orderId=${quotation?.Order?.reference_number}&customerRefId=${customerRefId}`}>
                                                <button type="button" className="product_list_btn ps-3 pe-3 w-100 btn btn-primary">Complete Your Purchase</button>
                                            </Link>
                                            : null
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InventoryItem