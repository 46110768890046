import { useParams } from 'react-router-dom'
import styles from "./DetailsGallery.module.css";
import { useAsset } from '../../../hooks'

function DetailsGallery() {

  const { vin } = useParams();

  const { data: asset, isLoading, isFetching, isError, error } = useAsset(vin)

  if (isLoading || isFetching)
    return <div>Loading...</div>

  if (isError)
    return <div>Error: {error.message}</div>

  return (
    <div className={styles.detail_gallery_main}>

      {
        asset.photo_urls && <div className={styles.varient_list}>

          {
            asset.photo_urls[0] &&
            <div className={`${styles.varient_box} ${styles.varient_bg1}`}>
              <div className={styles.box_img}>
                <img src={asset.photo_urls[0]?.location || ''} alt="#"></img>
              </div>
            </div>
          }
          {
            asset.photo_urls[1] &&
            <div className={`${styles.varient_box} ${styles.varient_bg2}`}>
              <div className={styles.box_img}>
                <img src={asset.photo_urls[1]?.location || ''} alt="#"></img>
              </div>
            </div>
          }
          {
            asset.photo_urls[2] &&
            <div className={`${styles.varient_box} ${styles.varient_interior}`}>
              <div className={styles.box_img}>
                <img src={asset.photo_urls[2]?.location || ''} alt="#"></img>
              </div>
            </div>
          }
        </div>
      }

    </div >
  )
}
export default DetailsGallery;