import { Button } from 'react-bootstrap';
import { LightbulbOutlined } from '@mui/icons-material';
import { Formik, Form, ErrorMessage, Field, useFormikContext } from 'formik'
import { useAddfinance, useUpdatePersonalFinance } from "../../hooks";
import * as yup from 'yup'
import { formatCurrency, isEmpty } from '../../utilities'
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
const INITIAL_FINANCE_VALUES = {
    residence: "",
    monthly_payment: "",
    bankruptcy: "false",
}

const VALIDATION_SCHEMA = yup.object({
    residence: yup
        .string()
        .nullable()
        .required('First Name is required'),
    monthly_payment: yup
        .string()
        .nullable()
        .required('Monthly Payment is required')
})

const PersonalFinanceDetails = ({ activeState5, setActiveState6, activeState6, customerRefId, getPersonalFinance }) => {
    const { mutate: financeDetails } = useAddfinance()
    const { mutate: updateFinanceDetails } = useUpdatePersonalFinance()
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        register
    } = useForm({
        defaultValues: INITIAL_FINANCE_VALUES,
        resolver: yupResolver(VALIDATION_SCHEMA),
        mode: 'all'
    })

    useEffect(() => {
        if (getPersonalFinance) {
            setActiveState6('state6Saved')
            const { residence, monthly_payment, bankruptcy } = getPersonalFinance
            const monthlyPayment = monthly_payment
            reset({
                residence,
                monthly_payment: monthlyPayment > 0 ? monthlyPayment : '',
                bankruptcy: bankruptcy?.toString()
            })
        }
    }, [getPersonalFinance])

    const SUBMIT_HANDLER = (formData) => {
        setActiveState6("state6Saved")
        const payload = {
            ...formData,
            reference_id: customerRefId,
        }
        if (isEmpty(getPersonalFinance)) {
            financeDetails(payload)
        }
        else if (!isEmpty(getPersonalFinance)) {
            updateFinanceDetails(payload)
        }
    }
    return (
        <div className={activeState5.id === 'state5Saved' ? `rd_section mb-5 section-06` : 'd-none'}>
            <div className='row'>
                <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                    <h3 className='headline-6 light'><span className="text-secondary pe-3">06</span>Do you have any Debts or Financial Obligations?</h3>
                    <Button className="px-3 py-2" variant="outline-secondary"
                        onClick={(e) => {
                            setActiveState6("state6Notsaved")
                        }}>Edit</Button>
                </div>


                <form onSubmit={handleSubmit(SUBMIT_HANDLER)} >
                    <div className="col-md-9">

                        <div className={activeState6.id === 'state6Notsaved' ? `bd_box border input-Form-main mb-4` : ' d-none'} id="state6Notsaved">
                            <p className='content-3--regular-grey mb-4'>Provide some financial information.</p>

                            <div className='row mb-3'>
                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> Residence Status: </span>

                                    <Controller
                                        name={"residence"}
                                        control={control}
                                        render={({ field }) => (

                                            <><select {...field} class="form-select" aria-label="Default select example">
                                                <option selected>Select Residence Type</option>
                                                <option value="Mortgage">Mortgage</option>
                                                <option value="Renting">Renting</option>
                                                <option value="Personal Ownership">Personal Ownership</option>
                                                <option value="With Relatives">With Relatives</option>

                                                {/* <option value="Lidfscense">fd</option> */}
                                            </select>
                                                {/* <div className='error'>{errors?.residence?.message}</div> */}
                                            </>
                                        )}
                                    />

                                </div>

                                <div className='col-md-6 pb-3'>

                                    <span className='acc_title mb-2 bold'> Monthly Payments: </span>
                                    <Controller
                                        name={"monthly_payment"}
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <input {...field}
                                                    className={`form-control ${errors?.monthly_payment?.message ? 'error-field' : ''}`}
                                                    id='monthly_payment'
                                                    masking
                                                    maskNumeric
                                                    maskDecimalScale={2}
                                                    name='monthly_payment'
                                                    type="number" />

                                                <div className='error'>{errors?.monthly_payment?.message}</div></>
                                        )}
                                    />

                                </div>
                            </div>

                            <div className='tradeOpt_topRadios pb-4'>
                                <h3 className='headline-6 light pb-3'>Have you ever applied for bankruptcy?</h3>
                                <div className='d-flex flex-wrap' role="group">

                                    <>
                                        <div className='pe-5'>
                                            <label className='d-flex align-items-center content-3 bold black'>

                                                <input
                                                    {...register("bankruptcy")}
                                                    type="radio" id='bankruptcy-yes' name='bankruptcy'
                                                    value={"true"}
                                                />

                                                <span className='ps-2'>Yes</span></label>
                                        </div>
                                        <div>
                                            <label className='d-flex align-items-center content-3 bold black'>
                                                <input
                                                    {...register("bankruptcy")}
                                                    type="radio" id='bankruptcy-no' name='bankruptcy'
                                                    value={"false"} />

                                                <span className='ps-2'>No</span></label>
                                        </div>
                                    </>

                                </div>
                            </div>

                            <div className='d-flex align-items-end justify-content-start'>
                                <Button variant='dark' type='submit'>Save & Continue</Button>
                            </div>
                        </div>

                        <div className={activeState6.id === 'state6Saved' ? `bd_box border` : 'd-none'} id="state6Saved">
                            <div className='bd_your_lease_sec d-flex align-items-start'>

                                <div className='bd_yl_text pe-5 w-50'>
                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>Residence Status</div>
                                            <div className='content-3--regular-grey'>{getPersonalFinance?.residence ?? ''}</div>
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>Have you ever applied for Bankruptcy?</div>
                                            <div className='content-3--regular-grey'>{getPersonalFinance?.bankruptcy ? "Yes" : "No" ?? ''}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bd_yl_text ps-5 w-50'>
                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>Monthly Payments</div>
                                            <div className='content-3--regular-grey'>{formatCurrency(getPersonalFinance?.monthly_payment ?? 0)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}
export default PersonalFinanceDetails;