import {
  useQuery,
  useQueries,
  useMutation, useQueryClient
} from 'react-query'
import {
  check_customer_by_email,
  config_quotation,
  convert_quotation_to_application,
  get_asset,
  get_assets_by_trim_code,
  get_assets,
  get_contract_allowed_annum_mileage,
  get_contract_term,
  get_credit_ratings,
  get_customer_quotations,
  get_dealer_fee,
  get_dealer_preference,
  get_dealer_profile,
  get_employment_details,
  get_fni_products,
  get_integration,
  get_jwt_token,
  get_option_trim_code,
  get_order_details,
  get_personal_finance,
  get_programs,
  get_quote_calculation,
  get_rates,
  get_vehicle_detail,
  get_vehicle_details,
  save_customer_contact,
  save_employment_details,
  save_finance_details,
  save_license_details,
  save_quotation,
  update_finance_details,
  update_employment_details,
  getLicenseInfo,
  get_contact_details,
  useCreditResponse,
  validate_license_data,
  save_customer_signature,
  sign_customer_document,
  get_customer_signatures,
  get_order_information
} from '../services/index.js'
import { DeblurOutlined } from '@mui/icons-material'
// let URL = `https://config-api-dev.netsolapp.io/config/inventory/filter?dealer_code=${dealer_code}&page_number=${page_number}&page_size=${page_size}&vehicle_status=${vehicle_status}&listing_status=${listing_status}&internet_price_from=${internet_price_from}&internet_price_to=${internet_price_to}&year_from=${year_from}&year_to=${year_to}`
export const useAssets = () => {
  return useQuery('ASSETS_LIST', get_assets, {
    refetchOnWindowFocus: false
  })
}
// https://config-api-dev.netsolapp.io/config/vehicle/5N1DR2MN6LC586124
export const useAsset = (vin) => {
  return useQuery(['ASSET_DETAIL', vin], () => get_asset(vin), {
    refetchOnWindowFocus: false
  })
}
// https://config-api-dev.netsolapp.io/config/dealer/fee/dealer-code/00001
export const useDealerFee = (dealer_code) => {
  return useQuery(['DEALER_FEE', dealer_code], () => get_dealer_fee(dealer_code), {
    refetchOnWindowFocus: false
  })
}
// https://config-api-dev.netsolapp.io/config/dealer/preference-by-dealer-code/00001
export const useDealerPreference = (dealer_code) => {
  return useQuery(['DEALER_PREFERENCE', dealer_code], () => get_dealer_preference(dealer_code), {
    refetchOnWindowFocus: false
  })
}
// https://config-api-dev.netsolapp.io/config/contract-term
export const useContractTerm = () => {
  return useQuery('CONTRACT_TERM', get_contract_term, {
    refetchOnWindowFocus: false
  })
}
// https://config-api-dev.netsolapp.io/config/credit-ratings
export const useCreditRatings = () => {
  return useQuery('CREDIT_RATINGS', get_credit_ratings, {
    refetchOnWindowFocus: false
  })
}
// https://config-api-dev.netsolapp.io/config/contract-allowed-annum-mileage
export const useContractAllowedAnnumMileage = () => {
  return useQuery('CONTRACT_ALLOWED_ANNUM_MILEAGE', get_contract_allowed_annum_mileage, {
    refetchOnWindowFocus: false
  })
}
// https://dms-api-dev.netsolapp.io/dms/configure/assets-by-trim-code/B3002
export const useAssetsByTrimCode = () => {
  return useQuery(['ASSETS_BY_TRIM_CODE'], () => get_assets_by_trim_code(), {
    refetchOnWindowFocus: false
  })
}
// https://config-api-dev.netsolapp.io/config/programs/all
export const usePrograms = (asset) => {
  return useQuery('PROGRAMS', get_programs(), {
    refetchOnWindowFocus: false,
    enabled: !!asset
  })
}
// https://dms-api-dev.netsolapp.io/dms/quote/calculation/V2
export const useQuoteCalculation = (onSuccess) => {
  return useMutation(get_quote_calculation, {
    onSuccess
  })
}
// Incentives and discounts
// Pending two more APIs that need to be implemented in it. F&I
// https://config-api-dev.netsolapp.io/config/dealer/option-trim-code?dealer_code=00001&trim_desc=B3001
export const useOptionTrimCode = () => {
  return useQuery(['OPTION_TERMS'], () => get_option_trim_code(), {
    refetchOnWindowFocus: false
  })
}
let asset = null
let vehicle_detail = null
let dealer_preference = null
let contract_term = null
let credit_ratings = null
let contract_allowed_annum_mileage = null
let assets_by_trim_code = null
let programs = null
let option_terms = null
let dealer_fee = null
let fni_products = null
let dealer_profile = null
let product_rates = null
let integration = null

export let UPDATED_CREDIT_RATING = ''
export const updateCreditRating = (updated_credit_rating) => {
  UPDATED_CREDIT_RATING = updated_credit_rating
}

export let UPDATED_ANNUM_MILEAGE = 0
export const updateAnnualMileage = (updated_anual_mileage) => {
  UPDATED_ANNUM_MILEAGE = updated_anual_mileage
}

export let UPDATED_TERMS = 0
export const updateTerms = (updated_terms) => {
  UPDATED_TERMS = updated_terms
}

export const useMultipleQueriesForPriceDetail = (VIN, onSuccessProgram) => {

  return useQueries([
    {
      queryKey: 'ASSET_DETAIL',
      queryFn: () => get_asset(VIN),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        asset = data
      }
    },
    {
      queryKey: 'VEHICLE_DETAIL',
      queryFn: () => get_vehicle_detail(VIN),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        vehicle_detail = data
      }
    },
    {
      queryKey: 'DEALER_PREFERENCE',
      queryFn: () => get_dealer_preference(asset.dealer_code),
      refetchOnWindowFocus: false,
      enabled: !!asset,
      onSuccess: (data) => {
        dealer_preference = data
      }
    },
    {
      queryKey: 'CONTRACT_TERM',
      queryFn: () => get_contract_term(),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        contract_term = data
      }
    },
    {
      queryKey: 'CREDIT_RATINGS',
      queryFn: () => get_credit_ratings(),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        credit_ratings = data
      }
    },
    {
      queryKey: 'CONTRACT_ALLOWED_ANNUM_MILEAGE',
      queryFn: () => get_contract_allowed_annum_mileage(),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        contract_allowed_annum_mileage = data
      }
    },
    {
      queryKey: 'ASSETS_BY_TRIM_CODE',
      queryFn: () => get_assets_by_trim_code(asset.model_code),
      refetchOnWindowFocus: false,
      enabled: !!asset,
      onSuccess: (data) => {
        assets_by_trim_code = data
      }
    },
    {
      queryKey: 'PROGRAMS',
      queryFn: () => get_programs(
        asset,
        assets_by_trim_code,
        credit_ratings,
        UPDATED_CREDIT_RATING,
        contract_allowed_annum_mileage,
        dealer_preference,
        UPDATED_ANNUM_MILEAGE,
        contract_term,
        UPDATED_TERMS
      ),
      refetchOnWindowFocus: false,
      enabled: !!asset && !!assets_by_trim_code && !!credit_ratings && !!contract_allowed_annum_mileage && !!dealer_preference && !!UPDATED_ANNUM_MILEAGE && !!contract_term && !!UPDATED_TERMS,
      onSuccess: (data) => {
        programs = data
        onSuccessProgram(data)
      }
    },
    {
      queryKey: 'DEALER_FEE',
      queryFn: () => get_dealer_fee(asset.dealer_code),
      refetchOnWindowFocus: false,
      enabled: !!asset,
      onSuccess: (data) => {
        dealer_fee = data
      }
    },
    {
      queryKey: 'OPTION_TERMS',
      queryFn: () => get_option_trim_code(asset.dealer_code, asset.model_code),
      refetchOnWindowFocus: false,
      enabled: !!asset && !!assets_by_trim_code,
      onSuccess: (data) => {
        option_terms = data
      }
    },
    {
      queryKey: 'FNI_PRODUCTS',
      queryFn: () => get_fni_products(asset),
      refetchOnWindowFocus: false,
      enabled: !!asset && !!assets_by_trim_code,
      onSuccess: (data) => {
        fni_products = data
      }
    },
    {
      queryKey: 'DEALER_PROFILE',
      queryFn: () => get_dealer_profile(asset.dealer_code),
      refetchOnWindowFocus: false,
      enabled: !!asset,
      onSuccess: (data) => {
        dealer_profile = data
      }
    },
    {
      queryKey: 'INTEGRATION',
      queryFn: () => get_integration('Provider Exchange Network'),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        integration = data
      }
    }
  ])
}

export const useProductRates = (onSuccessParent) => {
  return useMutation(get_rates, {
    onSuccess: (data) => {
      product_rates = data
      onSuccessParent(data)
    }
  })
}

export const useMultipleQueriesForMoreWays = () => {
  return useQueries([
    {
      queryKey: 'OPTION_TERMS',
      queryFn: () => get_option_trim_code(asset.dealer_code, asset.model_code),
      refetchOnWindowFocus: false,
      enabled: !!asset && !!assets_by_trim_code,
      onSuccess: (data) => {
        option_terms = data
      }
    },
    {
      queryKey: 'DEALER_FEE',
      queryFn: () => get_dealer_fee(asset.dealer_code),
      refetchOnWindowFocus: false,
      enabled: !!asset,
      onSuccess: (data) => {
        dealer_fee = data
      }
    },
    {
      queryKey: 'VEHICLE_DETAIL',
      queryFn: () => get_vehicle_detail(asset.vin),
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        vehicle_detail = data
      }
    }
  ])
}
// ${DMS_BASE_URL}/user/register-login
export const useGetJWTToken = (onSuccess) => {
  return useMutation(get_jwt_token, {
    onSuccess
  })
}
// https://dms-api-dev.netsolapp.io/dms/check-customer-by-email
export const useCustomerCheckByEmail = (onSuccess) => {
  return useMutation(check_customer_by_email, {
    onSuccess
  })
}
// https://dms-api-dev.netsolapp.io/dms/configure/save-quotation
export const useSaveQuotation = (onSuccess) => {
  return useMutation(save_quotation, {
    onSuccess
  })
}
// https://dms-api-dev.netsolapp.io/dms/configure/workqueue/search?{columnFilters}
export const useGetQuotations = (columnFilters) => {
  return useQuery(['GET_CUSTOMER_QUOTATIONS', columnFilters], () => get_customer_quotations(columnFilters), {
    refetchOnWindowFocus: false
  })
}
export const useGetVehicleDetailsFromVin = (vin) => {
  return useQuery(['Get_Vehicle_Details', vin], () => get_vehicle_details(vin), {
    refetchOnWindowFocus: false,
    enabled: !!vin
  })
}
export const useUpdateContact = () => {
  const queryClient = useQueryClient()
  return useMutation(save_customer_contact, {
    onSuccess() {
      queryClient.invalidateQueries('Get_Conatct_Details')
    }
  })
}
export const useAddEmploymentDetails = () => {
  const queryClient = useQueryClient()
  return useMutation(save_employment_details, {
    onSuccess() {
      queryClient.invalidateQueries('Get_Employment_Details')
    }
  })
}
export const useUpdateEmploymentDetails = () => {
  const queryClient = useQueryClient()
  return useMutation(update_employment_details, {
    onSuccess() {
      queryClient.invalidateQueries('Get_Employment_Details')
    }
  })
}
export const useAddfinance = () => {
  const queryClient = useQueryClient()
  return useMutation(save_finance_details, {
    onSuccess() {
      queryClient.invalidateQueries('Get_PersonalFinance_Details')
    }
  })
}
export const useUpdatePersonalFinance = () => {
  const queryClient = useQueryClient()
  return useMutation(update_finance_details, {
    onSuccess() {
      queryClient.invalidateQueries('Get_PersonalFinance_Details')
    }
  })
}
export const useVerifyMitekLicense = (onSuccess) => {
  return useMutation(validate_license_data, {
    onSuccess
  })
}
export const useAddLicense = () => {
  const queryClient = useQueryClient()
  return useMutation(save_license_details, {
    onSuccess() {
      queryClient.invalidateQueries('Get_License_Details')
    }
  })
}
export const useGetEmploymentDetails = (reference_id) => {
  return useQuery(['Get_Employment_Details', reference_id], () => get_employment_details(reference_id), {
    refetchOnWindowFocus: false,
    enabled: !!reference_id
  })
}
export const useGetLicenseDetails = (reference_id) => {
  return useQuery(["Get_License_Details", reference_id], () => {
    return getLicenseInfo(reference_id)
  }, {
    enabled: !!reference_id,
    refetchOnWindowFocus: false
  })
}
export const useGetPersonalFinanceDetails = (reference_id) => {
  return useQuery(['Get_PersonalFinance_Details', reference_id], () => get_personal_finance(reference_id), {
    refetchOnWindowFocus: false
  })
}
export const useGetContactDetails = (reference_id) => {
  return useQuery(['Get_Conatct_Details', reference_id], () => get_contact_details(reference_id), {
    refetchOnWindowFocus: false,
  })
}
export const useSaveCreditRequest = (onSuccess) => {
  return useMutation(useCreditResponse, {
    onSuccess
  })
}
export const useGetOrderDetails = (reference_id) => {
  return useQuery(['Get_Order_Details', reference_id], () => get_order_details(reference_id), {
    refetchOnWindowFocus: false,
  })
}
export const useConvertQuotationToApplication = (onSuccess) => {
  return useMutation(convert_quotation_to_application, {
    onSuccess
  })
}
export const useUpdateQuotation = (onSuccess) => {
  return useMutation(config_quotation, {
    onSuccess
  })
}

export const useSaveCustomerSignatures = () => {
  const queryClient = useQueryClient()
  return useMutation(save_customer_signature,
    {
      onSuccess() {
      queryClient.invalidateQueries('Get_CUSTOMER_SIGNATURES')
      }
    }
  )
}

export const useSaveCustomerDocumentSignature = () => {
  const queryClient = useQueryClient()
  return useMutation(sign_customer_document, {
    onSuccess() {
      queryClient.invalidateQueries('Get_Order_Information')
    }
  })
}

export const useGetCustomerSignatures = (reference_id) => {
  return useQuery(
    ['Get_CUSTOMER_SIGNATURES', reference_id],
    () => get_customer_signatures(reference_id),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const useGetOrderInformation = (reference_id) => {
    return useQuery(
    ['Get_Order_Information', reference_id],
    () => get_order_information(reference_id),
    {
      refetchOnWindowFocus: false,
    }
  )
}