import { Button } from 'react-bootstrap';
import { LightbulbOutlined, Description } from '@mui/icons-material';
import closeIcon from '../../assets/images/close-black.svg'
import { formatPayloadDate, isValidUrl } from '../../utilities'
import FileDragandDrop from "../../Components/FileDragDrop/FileDragDrop";
import { useVerifyMitekLicense, useAddLicense } from "../../hooks";
import { useForm, Controller } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { CustomImageUploader } from '../../Components/CustomUploader'
import { useEffect, useState } from 'react';

const VerifyDocuments = ({ activeState6, setActiveState7, activeState7, customerRefId, licenseInfo }) => {
    const { data: licenseData, mutate: verifyLicenseOnMitek, isLoading: mitekDataLoading } = useVerifyMitekLicense()
    const { mutate: AddLicense } = useAddLicense()
    const theme = useTheme()

    const {
        control,
        formState: { errors, isDirty, dirtyFields },
        getValues,
        setValue,
        watch,
        trigger,
        reset,
        handleSubmit
    } = useForm({
        mode: 'onChange'
    })
    useEffect(() => {
        if (licenseInfo) {
            setActiveState7('state7Saved')
            reset({
                licernse_back_image: licenseInfo?.back_image,
                licernse_front_image: licenseInfo?.front_image,
            })
        }
    }, [licenseInfo])

    const generateRandomString = () => {
        let length = 10
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        let result = ''
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        return result
    }

    const onFileChange = (event, type) => {
        setValue(type, (event?.dataTransfer ?? event?.target).files?.[0], {
            shouldDirty: true
        })
    }
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (file) {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function () {
                    const result = reader.result
                    const base64Data = result.split(',')[1] // Remove the data URL scheme
                    resolve(base64Data)
                }
                reader.onerror = (error) => reject(error)
            } else {
                resolve(null)
            }
        })
    }

    const verifyLicenseByMitek = async () => {

        if (dirtyFields?.licernse_back_image && dirtyFields?.licernse_front_image) {
            const backImage = await getBase64(watch('licernse_back_image'))
            const frontIimage = await getBase64(watch('licernse_front_image'))
            const payload = {
                front_image: frontIimage,
                back_image: backImage,
                customer_id: generateRandomString(),
                api_version: 1.2
            }
            verifyLicenseOnMitek(payload)
        }
    }
    const licenseImage = (image) => {
        // let image = getValues("front_image");
        let image_url
        const validUrl = isValidUrl(image)
        if (!validUrl && image !== undefined && (image instanceof Blob || image instanceof File)) {

            image_url = URL.createObjectURL(image)
        }
        const url = validUrl ? image : image_url ?? ''
        return url
    }


    const onSubmit = async () => {
        if (licenseData) {
            let front_img_string = ''
            let back_img_string = ''
            if (dirtyFields?.licernse_front_image) {
                front_img_string = (await getBase64(getValues("licernse_front_image")))
            }
            if (dirtyFields?.licernse_back_image) {
                back_img_string = (await getBase64(getValues("licernse_back_image")))
            }
            const payload = {
                first_name: licenseData?.first_name,
                last_name: licenseData?.last_name,
                front_image: front_img_string,
                back_image: back_img_string,
                expiry_date: formatPayloadDate(licenseData?.expiry_date),
                date_of_birth: formatPayloadDate(licenseData?.date_of_birth),
                license_number: licenseData?.license_number,
                issue_date:
                    licenseData && licenseData?.mitek_response?.evidence[0]?.dateOfIssue !== null
                        ? formatPayloadDate(licenseData?.mitek_response?.evidence[0]?.dateOfIssue)
                        : null,
                status:
                    licenseData && licenseData?.mitek_verification_status === 'Approved' ? 'Approved' : 'Rejected',
                call_type: licenseData ? "auto" : "manual",
                reference_id: customerRefId,
                mitek_response: null,
                address: {
                    address_line_1:
                        licenseData && licenseData?.mitek_response?.evidence[0]?.extractedData?.addressLine1 !== null
                            ? licenseData?.mitek_response?.evidence[0]?.extractedData?.address?.addressLine1
                            : null,
                    address_line_2:
                        licenseData && licenseData?.mitek_response?.evidence[0]?.extractedData?.addressLine2 !== null
                            ? licenseData?.mitek_response?.evidence[0]?.extractedData?.address?.addressLine2
                            : null,
                    state_name: licenseData && licenseData?.mitek_response?.evidence[0]?.extractedData?.stateProvince !== null
                        ? licenseData?.mitek_response?.evidence[0]?.extractedData?.address?.stateProvince
                        : null,
                    zip_code:
                        licenseData && licenseData?.mitek_response?.evidence[0]?.extractedData?.postalCode !== null
                            ? licenseData?.mitek_response?.evidence[0]?.extractedData?.address?.postalCode
                            : null,
                    city:
                        licenseData && licenseData?.mitek_response?.evidence[0]?.extractedData?.city !== null
                            ? licenseData?.mitek_response?.evidence[0]?.extractedData?.address?.city
                            : null,
                    verified: licenseData && licenseData?.mitek_verification_status === 'Approved' ? true : false,
                    identifier: licenseData && licenseData?.identifier !== null ? licenseData?.identifier : null
                }
            }
            //if (isEmpty(licenseoData)) {
            AddLicense(payload,
                {
                    onSuccess: () => {
                        setActiveState7("state7Saved")
                    }
                })

            // }
            // else if (isDirty) {
            //     updateLicenseByInfoReferenceId(payload, {
            //         onSuccess: () => {
            //             setRoute(2)
            //             setIsLicense?.(true)
            //         }
            //     })
            // }
        }
    }

    const hasFrontImage = watch('licernse_front_image')
    const hasBackImage = watch('licernse_back_image')
    return (
        <div className={activeState6.id === 'state6Saved' ? `rd_section mb-5 document_section section-07` : 'd-none'}>
            <div className='row'>
                <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                    <h3 className='headline-6 light'><span className="text-secondary pe-3">07</span>Verify your Identity</h3>
                    <Button className="px-3 py-2" variant="outline-secondary" onClick={(e) => { setActiveState7("state7Notsaved") }}>Edit</Button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-md-9">

                        <div className={activeState7.id === 'state7Notsaved' ? `bd_box border input-Form-main mb-4` : ' d-none'} id="state7Notsaved">
                            <p className='content-3--regular-grey mb-4'>Upload a goverment-issued photo identification document(ID).</p>

                            <div className='row mb-3'>
                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'>Select Document Type</span>
                                    <Controller
                                        name={"document_type"}
                                        // rules={validation(messages.label.residence, true)}
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <select {...field} class="form-select" aria-label="Default select example">
                                                <option selected>Select Document Type</option>
                                                <option value="License">License</option>
                                                <option value="License">Passport</option>
                                                <option value="License">SSN</option>
                                                {/* <option value="SSN Card">SSN Card</option> */}
                                                {/* <option value="Lidfscense">fd</option> */}
                                            </select>
                                            // <BasicSelect
                                            //     theme={theme}
                                            //     {...field}
                                            //     // label={messages.label.residence}
                                            //     items={documentType}
                                            //     // selectError={errors?.residence?.message}
                                            //     sxProps={''}

                                            // />
                                        )}
                                    />
                                </div>
                            </div>
                            {getValues("document_type") ?
                                <>
                                    <div className='row mb-3'>
                                        <div className='col-md-6 pb-3'>
                                            <span className='acc_title mb-2 bold'> Upload File or Take a Photo with your Phone: </span>
                                            {hasFrontImage ? (
                                                <CustomImageUploader>
                                                    <div className="img-wrap">
                                                        <Button
                                                            onClick={() =>
                                                                setValue("licernse_front_image", '', {
                                                                    shouldDirty: true
                                                                })
                                                            }
                                                            className="btn-close"
                                                        ><img src={closeIcon} /></Button>
                                                        <img src={licenseImage(getValues('licernse_front_image'))} />
                                                    </div>
                                                </CustomImageUploader>
                                            ) : (
                                                <>
                                                    <Controller
                                                        name={"licernse_front_image"}
                                                        control={control}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <FileDragandDrop
                                                                {...field}
                                                                theme={theme}
                                                                id="front_id"
                                                                hoverLabel="Drag and drop image file, or browse"
                                                                onChange={async (e) => {
                                                                    onFileChange(e, 'licernse_front_image')
                                                                    verifyLicenseByMitek()
                                                                }}
                                                                onDrop={async (e) => {
                                                                    onFileChange(e, 'licernse_front_image')
                                                                    verifyLicenseByMitek()
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </>)}

                                        </div>
                                        <div className='col-md-6 pb-3'>
                                            <span className='acc_title mb-2 bold'> Upload File or Take a Photo with your Phone: </span>
                                            {hasBackImage ? (
                                                <CustomImageUploader>
                                                    <div className="img-wrap">
                                                        <Button
                                                            onClick={() =>
                                                                setValue('licernse_back_image', '', {
                                                                    shouldDirty: true
                                                                })
                                                            }
                                                            className="btn-close"
                                                        ><img src={closeIcon} /></Button>
                                                        <img src={licenseImage(getValues('licernse_back_image'))} />
                                                    </div>
                                                </CustomImageUploader>
                                            ) : (
                                                <>

                                                    <Controller
                                                        name={"licernse_back_image"}
                                                        control={control}
                                                        defaultValue={''}
                                                        render={({ field }) => (
                                                            <FileDragandDrop
                                                                {...field}
                                                                theme={theme}
                                                                id="back_id"
                                                                hoverLabel="Drag and drop image file, or browse"
                                                                onChange={async (e) => {
                                                                    onFileChange(e, 'licernse_back_image')
                                                                    verifyLicenseByMitek()
                                                                }}
                                                                onDrop={async (e) => {
                                                                    onFileChange(e, 'licernse_back_image')
                                                                    verifyLicenseByMitek()
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </>)}

                                        </div>
                                    </div>
                                </> : ''}

                            <div className='d-flex align-items-end justify-content-start'>
                                <Button disabled={mitekDataLoading} variant='dark' type='submit ' >Save & Continue</Button>
                            </div>
                        </div>

                        <div className={activeState7.id === 'state7Saved' ? `bd_box border` : 'd-none'} id="state7Saved">
                            <div className='bd_your_lease_sec d-flex align-items-start'>
                                <div className='bd_yl_text pe-2 w-50'>
                                    <CustomImageUploader>
                                        <div className="img-wrap">
                                            <img src={licenseImage(getValues('licernse_front_image'))} />
                                        </div>
                                    </CustomImageUploader>
                                </div>
                                <div className='bd_yl_text ps-2 w-50'>
                                    <CustomImageUploader>
                                        <div className="img-wrap">
                                            <img src={licenseImage(getValues('licernse_back_image'))} />
                                        </div>
                                    </CustomImageUploader>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default VerifyDocuments