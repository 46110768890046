import { Button } from 'react-bootstrap';
import { LightbulbOutlined } from '@mui/icons-material';
import { useUpdateContact } from "../../hooks";
import { useLocation } from 'react-router-dom';
// import { useForm, Controller } from 'react-hook-form'
// import IFormation from './FinancialPageInterface'
import * as yup from 'yup'
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import UseQuery_Get_CustomerContact from './FinancialPageServices'
import { isEmpty } from '../../utilities';
const INITIAL_FORM_VALUES = {
    email: '',
    first_name: '',
    last_name: '',
    date_of_birth: '',
    ssn: '',
    mobile_number: ''
}

const VALIDATION_SCHEMA = yup.object({
    first_name: yup
        .string()
        .nullable()
        .required('First Name is required'),
    ssn: yup
        .string()
        .nullable()
        .required('SSN is required'),
    mobile_number: yup
        .string()
        .nullable()
        .required('Phone Number is required'),
    last_name: yup
        .string()
        .nullable()
        .required('Last Name is required')
})
const ShareYourInformation = ({
    setActiveState2,
    activeState1,
    activeState2,
    customerRefId,
    contactData
}) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        reset,
    } = useForm({
        defaultValues: INITIAL_FORM_VALUES,
        resolver: yupResolver(VALIDATION_SCHEMA),
        mode: 'all'
    })
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);


    // const customerRefId = searchParams.get('customerRefId');
    const { mutate: CustomerContact } = useUpdateContact()
    useEffect(() => {

        if (contactData?.email) {
            setValue("email", contactData?.email)
        }
        if (contactData?.email && contactData?.first_name && contactData?.last_name) {
            setActiveState2("state2Saved")

            reset({
                email: contactData?.email,
                first_name: contactData?.first_name,
                last_name: contactData?.last_name,
                date_of_birth: contactData?.date_of_birth,
                ssn: contactData?.ssn,
                mobile_number: contactData?.mobile_number
            })
        }
    }, [contactData])



    // const { data: contactData } = UseQuery_Get_CustomerContact("LP-DA-2023-118" ?? '')
    const SUBMIT_HANDLER = (formData) => {
        // setActiveState2("state2Saved")
        const payload = {
            customer_profile: {
                ...formData,
                updated_by: 'Netsol'
            },
            reference_id: customerRefId,
            // reference_id: customerRefId,
            // customer_addresses: []
        }
        if (isEmpty(contactData)) {
            CustomerContact(payload,
                {
                    onSuccess: () => {
                        setActiveState2("state2Saved")
                    }
                })
        }
        else if (!isEmpty(contactData)) {
            CustomerContact(payload,
                {
                    onSuccess: () => {
                        setActiveState2("state2Saved")
                    }
                })
        }
    }

    return (
        <div className={activeState1.id === 'state1Saved' ? `rd_section section-02 mb-5` : 'd-none'}>
            <div className='row'>
                <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                    <h3 className='headline-6 light'><span className="text-secondary pe-3">02</span>Share Your Information</h3>
                    <Button className="px-3 py-2" variant="outline-secondary" onClick={(e) => { setActiveState2("state2Notsaved") }}>Edit</Button>
                </div>

                <form onSubmit={handleSubmit(SUBMIT_HANDLER)}>
                    <div className="col-md-9">

                        <div className={activeState2.id === 'state2Notsaved' ? `bd_box border input-Form-main mb-4` : ' d-none'} id="state2Notsaved" >
                            <p className='content-3--regular-grey mb-4'>Provide some personal details to confirm your identity.</p>

                            <div className='row mb-3'>
                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> First Name: </span>
                                    <Controller
                                        name={"first_name"}
                                        control={control}
                                        render={({ field }) => (
                                            <><input
                                                {...field}
                                                className={`form-control ${errors?.first_name?.message ? 'error-field' : ''}`}
                                                id='first_name' name='first_name' type="text" placeholder='' /><div className='error'>{errors?.first_name?.message}</div></>
                                        )}
                                    />
                                </div>

                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> Last Name: </span>
                                    <Controller
                                        name={"last_name"}
                                        control={control}
                                        render={({ field }) => (
                                            <><input
                                                {...field}
                                                className={`form-control ${errors?.last_name?.message ? 'error-field' : ''}`}

                                                id='last_name' name='last_name' type="text" placeholder='' />
                                                <div className='error'>{errors?.last_name?.message}</div>
                                                {/* <Form.Control type="text" /> */}
                                            </>
                                        )}
                                    />
                                </div>

                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> Date of Birth: </span>
                                    <Controller
                                        name={"date_of_birth"}
                                        control={control}
                                        render={({ field }) => (
                                            <><input
                                                {...field}
                                                className="form-control" id='date_of_birth' name='date_of_birth' type="date" placeholder='' />

                                            </>
                                        )}
                                    />
                                </div>

                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> SSN: </span>
                                    <Controller
                                        name={"ssn"}
                                        control={control}
                                        render={({ field }) => (
                                            <><input
                                                {...field}
                                                className={`form-control ${errors?.ssn?.message ? 'error-field' : ''}`}

                                                id='ssn' name='ssn' type="number"
                                                masking
                                                format="### - ## - ####"
                                                placeholder={'000 - 00 - 0000'}
                                                onChange={(e) => {
                                                    field.onChange(e.target.value.slice(0, 9));
                                                }}
                                            />
                                                <div className='error'>{errors?.ssn?.message}</div>

                                            </>


                                        )}
                                    />


                                    {/* <Form.Control type="text" /> */}
                                </div>

                                <div className='col-md-4 pb-3'>
                                    <span className='acc_title mb-2 bold'> Phone: </span>
                                    <Controller
                                        name={"mobile_number"}
                                        control={control}
                                        render={({ field }) => (
                                            <><input
                                                {...field}
                                                className={`form-control ${errors?.mobile_number?.message ? 'error-field' : ''}`}
                                                id='mobile_number' name='mobile_number' type="text" placeholder=''
                                                onChange={(e) => {
                                                    field.onChange(e.target.value.slice(0, 10));
                                                }}
                                            />
                                                <div className='error'>{errors?.mobile_number?.message}</div>
                                            </>)}
                                    />
                                </div>

                                <div className='col-md-8 pb-3'>
                                    <span className='acc_title mb-2 bold'> Email Address: </span>
                                    <Controller
                                        name={"email"}
                                        control={control}
                                        disabled={true}
                                        render={({ field }) => (
                                            <input
                                                {...field}
                                                className="form-control" id='email' name='email' type="email" placeholder='' />
                                        )}
                                    />
                                </div>

                            </div>

                            <div className='d-flex align-items-end justify-content-start'>
                                <Button type='submit' variant='dark'>Save & Continue</Button>
                            </div>
                        </div>

                        <div className={activeState2.id === 'state2Saved' ? `bd_box border` : 'd-none'} id="state2Saved" >
                            <div className='bd_your_lease_sec d-flex align-items-center'>

                                <div className='bd_yl_text pe-5 w-50'>
                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>First Name</div>
                                            <div className='content-3--regular-grey'>{contactData?.first_name}</div>
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>Date of Birth</div>
                                            <div className='content-3--regular-grey'>{contactData?.date_of_birth}</div>
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>Phone</div>
                                            <div className='content-3--regular-grey'>{contactData?.mobile_number}</div>
                                        </div>
                                    </div>


                                </div>
                                <div className='bd_yl_text ps-5 w-50'>
                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>Last Name</div>
                                            <div className='content-3--regular-grey'>{contactData?.last_name}</div>
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>SSN</div>
                                            <div className='content-3--regular-grey'>1{contactData?.ssn}</div>
                                        </div>
                                    </div>

                                    <div className='mb-3'>
                                        <div className='row'>
                                            <div className='content-3--regular-grey bold'>Email Address</div>
                                            <div className='content-3--regular-grey'>{contactData?.email}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default ShareYourInformation