import { Button, Form } from 'react-bootstrap';
import { Description, LightbulbOutlined, Logout } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AreYouSureToSubmit from '../../Components/Models/AreYouSureToSubmit';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import { Link } from 'react-router-dom';
// import { ShareYourInformation } from './shareYourInformation'
import ShareYourInformation from './shareYourInformation';
import EmploymentDetails from './EmploymentDetails'
import PersonalFinanceDetails from './PersonalFinanceDetails'
import VerifyDocuments from './VerifyDocuments'
import { useGetPersonalFinanceDetails, useConvertQuotationToApplication, useGetContactDetails, useGetOrderDetails, useGetEmploymentDetails, useGetLicenseDetails } from "../../hooks";
import { Clear, Menu } from '@mui/icons-material';
import ReviewTermsAndOrder from './ReviewTerms'
import CurrentAddress from './CurrentAddress'
import VehiclePurpose from './VehiclePurpose'
import PreviousAddress from './PreviousAddress'
import Farabi from '../../Components/Farabi/index'
const FinancingPage = () => {

    const [showModal, setShowModal] = useState(false);
    const [openChat, setOpenChat] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')

    const { data: getPersonalFinance } = useGetPersonalFinanceDetails(customerRefId)
    const { data: employmentData } = useGetEmploymentDetails(customerRefId)
    const { data: orderInformation } = useGetOrderDetails(orderID)
    const { mutate: orderStage } = useConvertQuotationToApplication()

    const { data: licenseInfo } = useGetLicenseDetails(customerRefId)
    const { data: contactData } = useGetContactDetails(customerRefId)

    const handleShow = () => {
        setShowModal(true)
    };

    const handleClose = () => {
        setShowModal(false);
    }

    const orderRequest = () => {

        return orderInformation?.result
            .map((data) => {

                return { ...data?.order_info, applicable_rate: 0 }

            })

    }

    useEffect(() => {
        if (orderInformation) {
            orderStage(orderRequest()?.[0])
        }
    }, [orderInformation])

    const [activeState1, setState1Active] = useState({
        id: 'state1Notsaved',
    })

    const [activeState2, setState2Active] = useState({
        id: 'state2Notsaved',
    })

    const [activeState3, setState3Active] = useState({
        id: 'state3Notsaved',
    })

    const [activeState4, setState4Active] = useState({
        id: 'state4Notsaved',
    })

    const [activeState5, setState5Active] = useState({
        id: 'state5Notsaved',
    })

    const [activeState6, setState6Active] = useState({
        id: 'state6Notsaved',
    })

    const [activeState7, setState7Active] = useState({
        id: 'state7Notsaved',
    })

    const [activeState8, setState8Active] = useState({
        id: 'state8Notsaved',
    })

    useEffect(() => {

    }, [activeState1, activeState2, activeState3, activeState4, activeState5, activeState6, activeState7, activeState8])

    const setActiveState1 = (e) => {
        setState1Active({
            id: e,
        })
    }

    const setActiveState2 = (e) => {
        setState2Active({
            id: e,
        })
    }

    const setActiveState3 = (e) => {
        setState3Active({
            id: e,
        })
    }

    const setActiveState4 = (e) => {
        setState4Active({
            id: e,
        })
    }

    const setActiveState5 = (e) => {
        setState5Active({
            id: e,
        })
    }

    const setActiveState6 = (e) => {
        setState6Active({
            id: e,
        })
    }

    const setActiveState7 = (e) => {
        setState7Active({
            id: e,
        })
    }

    const setActiveState8 = (e) => {
        setState8Active({
            id: e,
        })
    }

    const [isClassAdded, setIsClassAdded] = useState(false);
    const toggleClass = () => {
        setIsClassAdded(!isClassAdded);
    };

    return (
        <div>
            <div className="digital_checkout_wrapper financing_main_wrapper">

                <div className="d-flex">
                    <div className={`checkoutSideNavMain ${isClassAdded ? 'open' : ''}`}>
                        <CheckoutSideNav />
                        <Button className="dcoCloseBtn" variant="outline-secondary" onClick={toggleClass}><Clear className="m-0" /></Button>
                    </div>
                    <div className="checkout_rightContent pb-0">

                        <div className="po_header pb-3">
                            <div className="po_header_inner">
                                <Button className="dcoMenuBtn" variant="outline-secondary px-2" onClick={toggleClass}><Menu className="m-0" /></Button>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <Link to={'/DigitalCheckout'}>
                                        <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='po_content_height'>
                            <div className='po_headlines'>
                                <h3 className='headline-5 light pb-1'>APPLY FOR FINANCING FROM Dealer One MOTORS <br />
                                    & OTHER LENDERS</h3>
                                <p className='label-2 light pb-3 mb-5'>Enjoy clear pricing, tailored lease and finance options, and personal service.</p>
                            </div>

                            <VehiclePurpose activeState1={activeState1} setActiveState1={setActiveState1} orderInformation={orderInformation} />
                            <ShareYourInformation customerRefId={customerRefId} setActiveState2={setActiveState2} activeState1={activeState1} activeState2={activeState2} contactData={contactData} />

                            <CurrentAddress setOpenChat={setOpenChat} contactData={contactData} customerRefId={customerRefId} activeState2={activeState2} setActiveState3={setActiveState3} activeState3={activeState3} />
                            <PreviousAddress contactData={contactData} customerRefId={customerRefId} activeState3={activeState3} setActiveState4={setActiveState4} activeState4={activeState4} />
                            <EmploymentDetails customerRefId={customerRefId} activeState4={activeState4} activeState5={activeState5} setActiveState5={setActiveState5} employmentData={employmentData} />
                            <PersonalFinanceDetails customerRefId={customerRefId} activeState5={activeState5} setActiveState6={setActiveState6} activeState6={activeState6} getPersonalFinance={getPersonalFinance} />

                            <VerifyDocuments activeState6={activeState6} setActiveState7={setActiveState7} activeState7={activeState7} customerRefId={customerRefId} licenseInfo={licenseInfo} />
                            <ReviewTermsAndOrder activeState7={activeState7} setActiveState8={setActiveState8} activeState8={activeState8} handleShow={handleShow} />

                        </div>

                        <Farabi orderInformation={orderInformation} contactData={contactData} openChat={openChat} setOpenChat={setOpenChat} orderID={orderID} />

                        {/* <DigitalCheckoutFooter pageUrl='/Insurance' /> */}
                    </div>
                </div>
            </div>

            <AreYouSureToSubmit show={showModal} handleClose={handleClose} orderInformation={orderInformation} />

        </div>
    );
};

export default FinancingPage;