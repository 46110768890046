import { Button } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { Logout } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/brusherImg1.png';
import img2 from '../../assets/images/brusherImg2.png';
 

import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';

const ViewBrochure = () => {
    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);

    // const handleStep = (e) => {
    //     setIsStep(e);
    //     console.log(isStep);
    //     if (isStep === 'step2_finalQuestion') {
    //         setIsDisabledNext(false);
    //     }
    // };



    return (
        <div>
            <div className="digital_checkout_wrapper">
                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">
                        <div className="po_header pb-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <span className='content-3 bold pe-3'>Saving</span>
                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>
                        <div className='iad_content po_content_height'>
                            <div className='d-flex justify-content-between'>
                                <div className='po_headlines'>
                                    <h3 className='headline-5 regular pb-1 d-flex align-items-center'>Warranty & Protection Plans</h3>
                                    <h3 className='content-3 bold pb-1 uppercase'>Tire & Wheel Protection</h3>
                                </div>
                            </div>
                            <div className='viewBrochure_list'>
                                <div className='row pb-4 pt-4'>
                                    <div className='col-lg-6 col-md-6 col-12 pb-3'>
                                       <div className='viewBrochure_img'>
                                       <img className='w-100 d-block' src={img1} alt='#'></img>
                                       </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 pb-3'>
                                    <div className='viewBrochure_img'>
                                          <img className='w-100 d-block' src={img2} alt='#'></img>
                                       </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DigitalCheckoutFooter pageUrl='/DeliveryAndPickup' />
                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default ViewBrochure;