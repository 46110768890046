import 'react-horizontal-scrolling-menu/dist/styles.css';

import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import React from 'react';
import slide1img from '../../assets/images/lucid_slider-car.png';
import gtr from '../../assets/images/e-350-red-01.png';
// import slide2img from '../../assets/images/homepagesection2.png';

// const getItems = () =>
//   Array(4)
//     .fill(0)
//     .map((_, ind) => ({ id: `element-${ind}` }));

function ReviewDealSlider() {
  // const [items, setItems] = React.useState(getItems);
  // const [selected, setSelected] = React.useState([]);
  // const [position, setPosition] = React.useState(0);

  // const isItemSelected = (id) => !!selected.find((el) => el === id);

  // const handleClick =
  //   (id) =>
  //     ({ getItemById, scrollToItem }) => {
  //       const itemSelected = isItemSelected(id);

  //       setSelected((currentSelected) =>
  //         itemSelected
  //           ? currentSelected.filter((el) => el !== id)
  //           : currentSelected.concat(id)
  //       );
  //     };

  return (
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
      {/* {items.map(({ id }) => (
        <Card
          itemId={id} // NOTE: itemId is required for track items
          title={id}
          key={id}
          onClick={handleClick(id)}
          selected={isItemSelected(id)}
        />
      ))} */}
      <div className="rd_slide rd_slide1">
        <div className="rd_slide1_sec">
          <div className="rd_slide1_head position-relative">
            <h2 className="headline-5 bold mb-1">2023 Nissan Pathfinder Platinum</h2>
            <p className='label-2 d-flex'><span>£ 965.81/month</span> <span>MRSP £ 51,680</span> <span>Total £ 37,083</span></p>
          </div>
          <div className="rd_slide1_img mb-3">
            <img src={gtr} alt="#" />
          </div>
          <ul className="d-flex flex-wrap">
            <li className="label-2 me-4">CVT</li>
            <li className="label-2 me-4">Automatic</li>
            <li className="label-2 me-4">Red</li>
            {/* <li className="label-2 me-4">2 Packages & Add-ons</li> */}
          </ul>
        </div>
      </div>



    </ScrollMenu>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <ArrowBackIosOutlined />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div disabled={isLastItemVisible} onClick={() => scrollNext()}>
      <ArrowForwardIosOutlined />
    </div>

  );
}

export default ReviewDealSlider;