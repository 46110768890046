import { Button } from 'react-bootstrap';
import { LightbulbOutlined } from '@mui/icons-material';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdateQuotation, useConvertQuotationToApplication } from "../../hooks";
import { isEmpty } from '../../utilities'
import React, { useEffect } from 'react';

const INITIAL_FORM_VALUES = {
    asset_usage: "",
}
const VehiclePurpose = ({ activeState1, setActiveState1, orderInformation }) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        watch,
        register
    } = useForm({
        defaultValues: INITIAL_FORM_VALUES,
        // resolver: yupResolver(asset_usage),
        mode: 'all'
    })
    const { mutate: orderStage } = useConvertQuotationToApplication()

    const { mutate: update_asset_usage } = useUpdateQuotation()
    const orderRequest = () => {

        return orderInformation?.result
            .map((data) => {
                return { ...data?.order_info, applicable_rate: 0 }
            })
    }

    useEffect(() => {
        if (orderInformation) {
            setActiveState1("state1Saved")
            reset({
                asset_usage: orderInformation?.result[0]?.order_info?.asset_usage
            })
        }
    }, [orderInformation])

    const SUBMIT_HANDLER = (formData) => {
        setActiveState1("state1Saved")


        const payload = {

            ...orderInformation?.result[0]?.order_info,
            ...(formData?.asset_usage === 'non-commercial1' || formData?.asset_usage === 'non-commercial2' ? { asset_usage: "Non Commercial" } : { asset_usage: " Commercial" }),
        }
        update_asset_usage(payload, {
            onSuccess() {
                //orderStage(payload)
            }
        })

    }
    return (
        <div className="rd_section section-01 mb-5">
            <div className='row'>
                <div className='col-lg-9 d-flex align-items-center pb-3 justify-content-between'>
                    <h3 className='headline-6 light'><span className="text-secondary pe-3">01</span>Who is this Vehicle For?</h3>
                    <Button className="px-3 py-2" variant="outline-secondary" onClick={(e) => { setActiveState1("state1Notsaved") }}>Edit</Button>
                </div>
                <form onSubmit={handleSubmit(SUBMIT_HANDLER)} >
                    <div className="col-lg-9">

                        <div className={activeState1.id === 'state1Notsaved' ? `bd_box border mb-4` : ' d-none'} id="state1Notsaved">
                            <p className='content-3--regular-grey mb-4'>Select the option that applies to your purchase.</p>
                            <div className='bd_your_lease_sec d-flex align-items-center pb-4'>
                                <div className='bd_box border margin-right-3'>
                                    <div className='pe-5 border-bottom pb-4 mb-4'>
                                        <label className='d-flex align-items-center content-3 bold black'>
                                            <input
                                                {...register("asset_usage")}
                                                type="radio" id='asset-yes-no-commercial' name='asset_usage'
                                                value={"non-commercial1"}
                                            />
                                            {/* <Form.Check type="radio" name='TradeInTop' aria-label="radio 1" value="option1" /> */}
                                            <span className='ps-2'>For Myself</span></label>
                                    </div>
                                    <p className='content-3--regular-grey ps-2 pe-2'>You are the primary driver.</p>
                                </div>

                                <div className='bd_box border'>
                                    <div className='pe-5 border-bottom pb-4 mb-4'>
                                        <label className='d-flex align-items-center content-3 bold black'>
                                            {/* <Form.Check type="radio" name='TradeInTop' aria-label="radio 1" value="option1" /> */}
                                            <input
                                                {...register("asset_usage")}
                                                type="radio" id='asset-yes-no-commercial2' name='asset_usage'
                                                value={"non-commercial2"}
                                            />
                                            <span className='ps-2'>For Myself & Another Driver</span></label>
                                    </div>
                                    <p className='content-3--regular-grey ps-2 pe-2'>You are the primary driver. Other drivers may use the vehicle occasionally</p>
                                </div>

                            </div>

                            <div className='bd_your_lease_sec d-flex align-items-center pb-2 mb-4'>
                                <div className='bd_box border margin-right-3'>
                                    <div className='pe-5 border-bottom pb-4 mb-4'>
                                        <label className='d-flex align-items-center content-3 bold black'>
                                            <input
                                                {...register("asset_usage")}
                                                type="radio" id='asset-yes-commercial1' name='asset_usage'
                                                value={"commercial1"}
                                            />                                            <span className='ps-2'>For Business Use (By an Individual Driver)</span></label>
                                    </div>
                                    <p className='content-3--regular-grey ps-2 pe-2'>You are the primary driver.</p>
                                </div>

                                <div className='bd_box border'>
                                    <div className='pe-5 border-bottom pb-4 mb-4'>
                                        <label className='d-flex align-items-center content-3 bold black'>
                                            {/* <Form.Check type="radio" name='TradeInTop' aria-label="radio 1" value="option1" /> */}
                                            <input
                                                {...register("asset_usage")}
                                                type="radio" id='asset-yes-commercial2' name='asset_usage'
                                                value={"commercial2"} />
                                            <span className='ps-2'>For Business Use (By Multiple Drivers)</span></label>
                                    </div>
                                    <p className='content-3--regular-grey ps-2 pe-2'>You are the primary driver. Other drivers may use the vehicle occasionally</p>
                                </div>

                            </div>

                            <div className='d-flex align-items-end justify-content-start'>
                                <Button variant='dark' type='submit'
                                // onClick={(e) => { setActiveState1("state1Saved") }}
                                >Save & Continue</Button>
                            </div>
                        </div>

                        <div className={activeState1.id === 'state1Saved' ? `bd_box border` : 'd-none'} id="state1Saved">
                            <div className='bd_your_lease_sec d-flex align-items-center border-bottom pb-4 mb-4'>
                                <> {orderInformation?.asset_usage === "Non Commercial " ? <div className='bd_yl_text pe-5'>
                                    <div className='content-3--regular-grey bold'>This Vehicle is for you.</div>
                                    <p className='content-3--regular-grey'>You are the primary driver. Other drivers may use the vehicle occasionally</p>
                                </div> : <div className='bd_yl_text pe-5'>
                                    <div className='content-3--regular-grey bold'>This Vehicle is for you.</div>
                                    <p className='content-3--regular-grey'>You are the primary driver. Other drivers may use the vehicle occasionally</p>
                                </div>}</>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    )
}
export default VehiclePurpose