import { Route, Routes } from "react-router-dom";
import DigitalCheckout from "../Pages/DigitalCheckout/DigitalCheckout";
import EndingPage from "../Pages/EndingPage/EndingPage";
import FinancingPage from "../Pages/FinancingPage/FinancingPage";
import HomePage from "../Pages/HomePage/HomePage";
import HomeShopingHub from "../Pages/HomeShopingHub/HomeShopingHub";
import IncentivesAndDiscounts from "../Pages/DigitalCheckout/IncentivesAndDiscounts";
import MainHomePage from "../Pages/MainHomePage/MainHomePage";
import PaymentOptions from "../Pages/DigitalCheckout/PaymentOptions";
import ProductDetails from "../Pages/ProductDetails/ProductDetails";

import React from "react";
import TradeInOptions from "../Pages/DigitalCheckout/TradeInOptions";
import VehicleAddOns from "../Pages/DigitalCheckout/VehicleAddOns";
import WarrantyAndProtectionPlans from "../Pages/DigitalCheckout/WarrantyAndProtectionPlans";
import WarrantyAndProtectionPlansVehicleCoverage from "../Pages/DigitalCheckout/WarrantyAndProtectionPlansVehicleCoverage";
import ViewBrochure from "../Pages/DigitalCheckout/ViewBrochure";
import MaintenanceAndServicePlans from "../Pages/DigitalCheckout/MaintenanceAndServicePlans";
import ReviewYourDeal from "../Pages/DigitalCheckout/ReviewYourDeal";
import Contracting from "../Pages/Contracting/Contracting";
import DeliveryAndPickup from "../Pages/DigitalCheckout/DeliveryAndPickup";
import Insurance from "../Pages/Insurance/Insurance";
import ReviewAndSign from "../Pages/ReviewAndSign/ReviewAndSign";
import DocumentView from "../Pages/DocumentView/DocumentView";
function PagesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="view-document" element={<DocumentView />} />
      <Route path="asset-details/:vin/:terms?" element={<ProductDetails />} />
      <Route path="DigitalCheckout" element={<DigitalCheckout />} />
      <Route path="PaymentOptions" element={<PaymentOptions />} />
      <Route path="/home-shoping-hub/:customerRefId" element={<HomeShopingHub />} />
      <Route path="MainHomePage" element={<MainHomePage />} />
      <Route path="TradeInOptions" element={<TradeInOptions />} />
      <Route path="VehicleAddOns" element={<VehicleAddOns />} />
      {/* <Route path="EndingPage" element={<EndingPage />} /> */}
      <Route path="FinancingPage" element={<FinancingPage />} />
      <Route path="IncentivesAndDiscounts" element={<IncentivesAndDiscounts />} />
      <Route path="WarrantyAndProtectionPlansVehicleCoverage" element={<WarrantyAndProtectionPlansVehicleCoverage />} />
      <Route path="WarrantyAndProtectionPlans" element={<WarrantyAndProtectionPlans />} />
      <Route path="ViewBrochure" element={<ViewBrochure />} />
      <Route path="MaintenanceAndServicePlans" element={<MaintenanceAndServicePlans />} />
      <Route path="Insurance" element={<Insurance />} />
      <Route path="ReviewYourDeal" element={<ReviewYourDeal />} />
      <Route path="Contracting" element={<Contracting />} />
      <Route path="DeliveryAndPickup" element={<DeliveryAndPickup />} />
      <Route path="ReviewAndSign" element={<ReviewAndSign />} />
    </Routes>
  )
}
export default PagesRoutes;