import { Button, Tab, Tabs } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import { Logout } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/lucid-platinum.jfif';
import img2 from '../../assets/images/wpp_img2.png';
import img3 from '../../assets/images/wpp_img3.png';
import itemlogo from '../../assets/images/wpp_bmwLogo.png';
import platinumcoverage from '../../assets/images/platinum.jpg';

import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import PreQualifySecondsModel from '../../Components/Models/PreQualifySecondsModel';
import PlusToggleBtn from '../../ScreensUi/DigitalCheckout/PlusToggleBtn';
import { useLocation } from 'react-router-dom';
import FarabiNew from '../../Components/Farabi/indexNew'

const WarrantyAndProtectionPlans = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')
    const link = `${location.pathname}${location.search}`

    const [showModal1, setShowModal1] = useState(false);
    const handleCloseModal1 = () => setShowModal1(false);

    // const handleStep = (e) => {
    //     setIsStep(e);
    //     console.log(isStep);
    //     if (isStep === 'step2_finalQuestion') {
    //         setIsDisabledNext(false);
    //     }
    // };



    return (
        <div>
            <div className="digital_checkout_wrapper">

                <div className="d-flex">
                    <CheckoutSideNav />
                    <div className="checkout_rightContent pb-0">

                        <div className="po_header pb-3">
                            <div className="d-flex justify-content-end align-items-center">
                                <Link to={'/DigitalCheckout'}>
                                    <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                </Link>
                            </div>
                        </div>
                        <div className='iad_content po_content_height'>
                            <div className='d-flex justify-content-between'>
                                <div className='po_headlines'>
                                    <h3 className='headline-5 regular pb-1 d-flex align-items-center'>Warranty & Protection Plans</h3>
                                    <p className='label-2 regular pb-3'>View and add accessories. These can be applied to your total price or paid separately at checkout</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 pb-4'>
                                    <Tabs defaultActiveKey="ExteriorAccesories" className='pfi_filled_tabs'>
                                        <Tab eventKey="ExteriorAccesories" title="Comprehensive">
                                        </Tab>
                                        <Tab eventKey="InteriorAccesories" title="Individual">
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>

                            <h3 className='content-3 bold pb-1 uppercase'>LEVELS OF COVERAGE</h3>

                            <div className='iad_cards_list'>
                                <div className='row pb-4 pt-4'>
                                    <div className='col-lg-4 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={platinumcoverage} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1 d-flex justify-content-between pe-4'>Platinum
                                                    <small>£ 210 / Month</small>
                                                </h3>
                                                <p className='label-2 pb-2'><small>Available for New, Used</small></p>
                                                <p className='label-2 pb-1'>The highest level of protection, with comprehensive coverage for most major components and exclusive protection offerings.</p>


                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img2} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1 d-flex justify-content-between pe-4'>Gold
                                                    <small>£ 150 / Month</small>
                                                </h3>
                                                <p className='label-2 pb-2'><small>Available for New and Used </small></p>
                                                <p className='label-2 pb-1'>Comprehensive coverage of many major components on your vehicle.</p>


                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-12 pb-3'>
                                        <div className='iad_cards_box w-100'>
                                            <div className='iad_cards_img'>
                                                <Link to={link} className='iad_cards_img_link'>
                                                    <img src={img3} alt='#'></img>
                                                </Link>
                                                <PlusToggleBtn />
                                            </div>
                                            <div className='iad_cards_info'>
                                                <h3 className='content-2--bold pb-1 d-flex justify-content-between pe-4'>Powertrain Plus
                                                    <small>£ 110 / Month</small>
                                                </h3>
                                                <p className='label-2 pb-2'><small>Available for New and Used </small></p>
                                                <p className='label-2 pb-1'>Extensive coverage of many drivetrain components and limited coverage for many other parts.</p>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <FarabiNew />
                        <DigitalCheckoutFooter pageUrl={`/MaintenanceAndServicePlans?orderId=${orderID}&customerRefId=${customerRefId}`} />

                    </div>
                </div>
            </div>
            {/* <PreQualifySecondsModel showModal={showModal1} showStep='step1' handleCloseModal={handleCloseModal1} /> */}
        </div>
    );
};

export default WarrantyAndProtectionPlans;