import { CloseOutlined, TuneOutlined } from '@mui/icons-material';
import React  from 'react';
function ShowHideFilterButton(props) {
    return (
        <button className='showHideFilterButton' onClick={props.handleClick}>
            {props.isVisible ? <CloseOutlined /> : <TuneOutlined />}
            {props.isVisible ? 'Hide Filters' : 'Show Filters'}
        </button>
    )
}
export default ShowHideFilterButton;