import './App.css';
import './Styles/style.css'
import './Styles/style1.css'
import './Styles/style2.css'
import './Styles/style3.css'
import './Styles/style4.css'
import './Styles/responsive.css'

import PagesRoutes from './Routes/PagesRoutes';
import { useEffect } from 'react';
import { loadJsFile } from './utilities/methods'

function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify({"email":"mohenog222@mainmile.com","product_id":14,"role":{"name":"Index Dealer Admin User","is_admin":false},"user_name":"Dealer One","tenant_id":103992,"company_name":"Nissan Financial Services","is_logged_in":true,"refresh_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMDU4MDIsImVtYWlsIjoibW9oZW5vZzIyMkBtYWlubWlsZS5jb20iLCJ0ZW5hbnRfaWQiOjEwMzk5MiwidXNlcl9yb2xlcyI6W3sicm9sZV9pZCI6MjUsIm5hbWUiOiJEZWFsZXIgQWRtaW4iLCJwcm9kdWN0X2lkIjoxNCwiaXNfYWRtaW4iOmZhbHNlLCJleHRlcm5hbF9jb2RlIjpudWxsfSx7InJvbGVfaWQiOjE2NiwibmFtZSI6IkluZGV4IERlYWxlciBBZG1pbiBVc2VyIiwicHJvZHVjdF9pZCI6MTEsImlzX2FkbWluIjpmYWxzZSwiZXh0ZXJuYWxfY29kZSI6bnVsbH1dLCJleHAiOjE3NTYyMTE2MDl9._lO7TPp8LR9xAIf577t5Xxf8DeCNiRWhsSrO8eKh3Ng","access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMDU4MDIsImVtYWlsIjoibW9oZW5vZzIyMkBtYWlubWlsZS5jb20iLCJ0ZW5hbnRfaWQiOjEwMzk5MiwidXNlcl9yb2xlcyI6W3sicm9sZV9pZCI6MjUsIm5hbWUiOiJEZWFsZXIgQWRtaW4iLCJwcm9kdWN0X2lkIjoxNCwiaXNfYWRtaW4iOmZhbHNlLCJleHRlcm5hbF9jb2RlIjpudWxsfSx7InJvbGVfaWQiOjE2NiwibmFtZSI6IkluZGV4IERlYWxlciBBZG1pbiBVc2VyIiwicHJvZHVjdF9pZCI6MTEsImlzX2FkbWluIjpmYWxzZSwiZXh0ZXJuYWxfY29kZSI6bnVsbH1dLCJleHAiOjE3NTYyMTE2MDl9._lO7TPp8LR9xAIf577t5Xxf8DeCNiRWhsSrO8eKh3Ng","stipe_user_created":true,"isDealex":true}))
    loadJsFile('https://plugin-demo.netsolapp.io/bundle.js')

  }, [])

  return (
    <div className='main_wrapper'>
      <PagesRoutes />
    </div>
  );
}

export default App;
