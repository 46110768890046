import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { LightbulbOutlined } from '@mui/icons-material';
import * as yup from 'yup'
import { useUpdateContact } from "../../hooks";
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

const INITIAL_FORM_VALUES = {
    address_line_1: "",
    address_line_2: "",
    city: "",
    state_name: "",
    zip_code: "",
    move_in_date: ""
}

const VALIDATION_SCHEMA = yup.object({
    address_line_1: yup
        .string()
        .nullable(),
    // .required('Address Line 1 is required'),
    city: yup
        .string()
        .nullable(),
    // .required('City is required'),
    state_name: yup
        .string()
        .nullable(),
    // .required('State Name is required'),
    zip_code: yup
        .string()
        .nullable(),
    // .required('Zip Code is required'),
    move_in_date: yup
        .string()
        .nullable(),
    // .required('Move In Date is required')
})

const PreviousAddress = ({
    activeState3, setActiveState4, activeState4,
    customerRefId,
    contactData
}) => {

    const [years, setYears] = useState(null)
    const [address, setAddress] = useState(null)
    const { mutate: CustomerContact } = useUpdateContact()

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        watch
    } = useForm({
        defaultValues: INITIAL_FORM_VALUES,
        resolver: yupResolver(VALIDATION_SCHEMA),
        mode: 'all'
    })

    function calculateDateDifference(inputMonth, inputYear) {
        const currentDate = new Date()
        const months = currentDate.getMonth() + 1
        const yearDiff = currentDate.getFullYear() - inputYear
        const monthDiff = months - inputMonth

        if (monthDiff < 0) {
            setYears(yearDiff - 1)
        } else {
            setYears(yearDiff)
        }
    }

    useEffect(() => {
        const mailAddress = contactData?.customer_addresses?.find((x) => x?.address_type == "Garaging")
        if (mailAddress?.address_line_1) {
            setActiveState4("state4Saved")
            reset({
                address_line_1: mailAddress?.address_line_1,
                address_line_2: mailAddress?.address_line_2,
                city: mailAddress?.city,
                state_name: mailAddress?.state_name,
                zip_code: mailAddress?.zip_code,
                move_in_date: mailAddress?.move_in_date
            })

            if (mailAddress?.move_in_date != "") {
                calculateDateDifference(mailAddress?.move_in_date?.toString()?.slice(5, 7), mailAddress?.move_in_date?.toString()?.slice(0, 4))
            }
        }
        setAddress(mailAddress)
    }, [contactData])

    const SUBMIT_HANDLER = (formData) => {

        let address = []
        address.push({ ...formData, address_type: "Garaging" })
        const payload = {

            reference_id: customerRefId,
            customer_addresses: address
        }
        if (formData?.address_line_1) {
            CustomerContact(payload,
                {
                    onSuccess: () => {
                        setActiveState4("state4Saved")
                    }
                })
        }
        else {
            setActiveState4("state4Saved")
        }
    }

    return (
        <div className={activeState3.id === 'state3Saved' ? `rd_section mb-5 section-04` : 'd-none'}>
            <div className='row'>
                <div className='col-md-9 d-flex align-items-center pb-3 justify-content-between'>
                    <h3 className='headline-6 light'><span className="text-secondary pe-3">04</span>Where do you Live Previously?</h3>
                    <Button className="px-3 py-2" variant="outline-secondary" onClick={(e) => { setActiveState4("state4Notsaved") }}>Edit</Button>
                </div>

                <form onSubmit={handleSubmit(SUBMIT_HANDLER)}>
                    <div className="col-md-9">
                        <div className={activeState4.id === 'state4Notsaved' ? `bd_box border input-Form-main mb-4` : ' d-none'} id="state4Notsaved" >
                            <p className='content-3--regular-grey mb-4'>Provide your current address.</p>

                            <div className='row mb-3'>
                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> Address Line 1: </span>
                                    <Controller
                                        name={'address_line_1'}
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    {...field}
                                                    className={`form-control ${errors?.address_line_1?.message?.toString() ? 'error-field' : ''}`}
                                                    id='address_line_1' name='address_line_1' type="text" placeholder=''
                                                />
                                                <div className='error'>{errors?.address_line_1?.message?.toString()}</div>
                                            </>

                                        )}
                                    />
                                </div>

                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> Address Line 2: </span>
                                    <Controller
                                        name={'address_line_2'}
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    {...field}
                                                    className={`form-control ${errors?.address_line_2?.message?.toString() ? 'error-field' : ''}`}
                                                    id='address_line_2' name='address_line_2' type="text" placeholder=''
                                                />
                                                <div className='error'>{errors?.address_line_2?.message?.toString()}</div>
                                            </>

                                        )}
                                    />
                                </div>

                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> City: </span>
                                    <Controller
                                        name={'city'}
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    {...field}
                                                    className={`form-control ${errors?.city?.message?.toString() ? 'error-field' : ''}`}
                                                    id='city' name='city' type="text" placeholder=''
                                                />
                                                <div className='error'>{errors?.city?.message?.toString()}</div>
                                            </>

                                        )}
                                    />
                                </div>

                                <div className='col-md-3 pb-3'>
                                    <span className='acc_title mb-2 bold'> Region: </span>
                                    <Controller
                                        name={'state_name'}
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    {...field}
                                                    className={`form-control ${errors?.state_name?.message?.toString() ? 'error-field' : ''}`}
                                                    id='state_name' name='state_name' type="text" placeholder=''
                                                />
                                                <div className='error'>{errors?.state_name?.message?.toString()}</div>
                                            </>

                                        )}
                                    />
                                </div>

                                <div className='col-md-3 pb-3'>
                                    <span className='acc_title mb-2 bold'> Post Code: </span>
                                    <Controller
                                        name={'zip_code'}
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    {...field}
                                                    className={`form-control ${errors?.zip_code?.message?.toString() ? 'error-field' : ''}`}
                                                    id='zip_code' name='zip_code' type="text" placeholder=''
                                                />
                                                <div className='error'>{errors?.zip_code?.message?.toString()}</div>
                                            </>

                                        )}
                                    />
                                </div>

                                <div className='col-md-6 pb-3'>
                                    <span className='acc_title mb-2 bold'> Move In Date: </span>
                                    <Controller
                                        name={'move_in_date'}
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    {...field}
                                                    className="form-control" id='move_in_date' name='move_in_date' type="date" placeholder='' />
                                                <div className='error'>{errors?.move_in_date?.message?.toString()}</div>
                                            </>

                                        )}
                                    />
                                </div>

                            </div>

                            <div className='d-flex align-items-end justify-content-start'>
                                <Button variant='dark' type='submit'>Save & Continue</Button>
                            </div>
                        </div>
                        <div className={activeState4.id === 'state4Saved' ? `bd_box border` : 'd-none'} id="state4Saved">
                            <div className='bd_your_lease_sec d-flex align-items-center'>

                                <div className='bd_yl_text pe-5 w-50'>
                                    <div className='row'>
                                        <div className='content-3--regular-grey bold'>Previous Current Address</div>
                                        <div className='content-3--regular-grey'>  {address ? (address?.address_line_1 + ", " + address?.address_line_2 + ", " +
                                            address?.city + address?.state_name + ", " + address?.zip_code) : null}</div>
                                    </div>
                                </div>
                                <div className='bd_yl_text ps-5 w-50'>
                                    <div className='row'>
                                        <div className='content-3--regular-grey bold'>Move In Date: {address?.move_in_date}</div>
                                        <div className='content-3--regular-grey'>{years ? (years + " " + "Years") : null}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default PreviousAddress