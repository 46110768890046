
import { useState, useEffect, Fragment } from 'react'
import { styled  } from '@mui/material/styles'
import { Button } from 'react-bootstrap';
import chartIcon from '../../assets/images/chat-robot-icon.svg';

const FarabiWrap = styled('div')(() => ({
  "&.farabi-wrap": {
    position: 'fixed',
    bottom: 20,
    right: 40,
    width: 600,
    zIndex: 1100,
    '.iframe-wrap': {
      display: 'block',
      width: '100%',
      height: 550,
      clear: 'both',
      borderRadius: 13,
      overflow: 'hidden',
      marginBottom: 10,

      iframe: {
        width: '100%',
        height: '115%',
        border: 0
      }
    },
    '.btn': {
      padding: 12,
      float: 'right',
      borderRadius: '100% !important',
      boxShadow: '-5px 5px 5px rgba(0,0,0, 0.2)',
      svg: {
        height: '35px !important',
        width: '35px !important',
      }
    },
    '.counter': {
      minWidth: 24,
      height: 24,
      borderRadius: 24,
      lineHeight: '24px',
      padding: '0 5px',
      display: 'inline-block',
      textAlign: 'center',
      fontWeight: 500,
      fontSize: 12,
      position: 'absolute',
      right: 0,
      bottom: 55
    },
    '.alert-msg': {
      fontWeight: 500,
      textAlign: 'left',
      padding: '15px 20px',
      borderRadius: 10,
      maxWidth: 320,
      position: 'absolute',
      right: 0,
      bottom: 95,
      '&::after': {
        content: '""',
        border: '12px solid transparent',
        borderRight: 0,
        right: 30,
        bottom: -17,
        position: 'absolute',
        rotate: '90deg'
      }
    }
  },
  "&.bottom-high": {
    bottom: 100,
  }
  }))

function FarabiNew() {


    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

  return (
    <>
    <FarabiWrap className='farabi-wrap bottom-high'>
      {open && (
        <div  className="iframe-wrap border bg-body">
          <iframe title=" "
          src={`https://specialist4u-streamlit-example-streamlit-app-369wkx.streamlit.app/?embed=true`}
           />
        </div>
      )}
      <Button variant="default" className='border bg-body' onClick={handleClick}>
      <img src={chartIcon} alt='#' />
      </Button>
    </FarabiWrap>
  </>
  )
}

export default FarabiNew