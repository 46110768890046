import { Button, Modal } from 'react-bootstrap';
import CheckoutSideNav from '../../ScreensUi/CheckoutSideNav/CheckoutSideNav';
import React, {useState, useEffect} from 'react';
import { styled } from "@mui/material";
import { Link } from 'react-router-dom';
import DocumentView from '../DocumentView/DocumentView'
import DigitalCheckoutFooter from '../../ScreensUi/DigitalCheckout/DigitalCheckoutFooter';
import { AccessTime, Clear, ErrorOutline, FileCopy, FileDownload, LightbulbOutlined, Logout, Menu, Visibility, CheckCircleOutline  } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import {useGetOrderInformation, useSaveCustomerDocumentSignature, useGetCustomerSignatures} from '../../hooks'

const DocumentViewModel = styled("div")(() => ({
    ".signature-canvas-ui": {
    display: "none"
  },
  ".doc-viewer-ui": {
    display: "block",

    ".btn": {
        display: "none"
    }
  }
  }));

const ReviewAndSign = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerRefId = searchParams.get('customerRefId');
    const orderID = searchParams.get('orderId')

    const [show, setShow] = useState(false);
    const [isClassAdded, setIsClassAdded] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState();
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const [isFniSigned, setIsFniSigned] = useState(false);

    const { data: orderInformation } = useGetOrderInformation(orderID)
    const { data: customerSignatures } = useGetCustomerSignatures(customerRefId)
    const { mutate: signDocument, isLoading: signingDocument } = useSaveCustomerDocumentSignature()

    useEffect(() => {
        if (orderInformation?.documents && orderInformation?.documents?.length > 0 && selectedDocument?.identifier) {
            const _selectedDocument = orderInformation?.documents?.filter(document => document?.identifier === selectedDocument?.identifier)[0]
            setSelectedDocument(_selectedDocument)
        }
        const isSigned = orderInformation?.documents?.find(document=> ( (document?.document_name ?? '')?.includes('BMW_Financial_Service') && (document?.document_signed_url ?? '')?.length > 0))
        if(isSigned){
            setIsFniSigned(true)
        }

    }, [orderInformation])

    const toggleClass = () => {
        setIsClassAdded(!isClassAdded);
    };

    const handleClose = () => {
        setShow(false)
        setIsScrolledToBottom(false)
        // setSelectedDocument(null)
    };
    const handleShow = (document) => {
        setShow(true)
        setSelectedDocument(document)
    };
    const handleSign = (document) => {
        signDocument(
            {
                identifier: document?.identifier,
                document_url: document?.document_raw_url,
                document_name: document?.document_name,
                customer_id: customerRefId
            }
        )
    }
    

    return (
        <>
            <div className="digital_checkout_wrapper review_sign_main">

                <div className="d-flex">
                    <div className={`checkoutSideNavMain ${isClassAdded ? 'open' : ''}`}>
                        <CheckoutSideNav />
                        <Button className="dcoCloseBtn" variant="outline-secondary" onClick={toggleClass}><Clear className="m-0" /></Button>
                    </div>
                    <div className="checkout_rightContent pb-0">

                        <div className="po_header pb-3">
                        <div className="po_header_inner">
                                <Button className="dcoMenuBtn" variant="outline-secondary px-2" onClick={toggleClass}><Menu className="m-0" /></Button>
                                <div className="d-flex justify-content-end align-items-center">
                                    {/* <span className='content-3 border v_time_btn me-2 d-flex align-items-center pe-3'>
                                        <AccessTime className='me-2' />
                                        <small className='text-center'>
                                            Vehicle Reserved
                                            <b className='bold d-block'>00:36:05</b>
                                        </small>
                                    </span> */}
                                    <Link className='leave_btn' to={'/DigitalCheckout'}>
                                        <Button variant="outline-secondary"><Logout className='me-2' />Leave</Button>
                                    </Link>
                                </div>
                            </div>
                            
                        </div>

                        <div className='rd_content po_content_height'>
                            <div className='po_headlines'>
                                <h3 className='headline-5 regular pb-1'>Review & Sign</h3>
                                <p className='label-2 light pb-4'>Review the documents that make up your agreement.</p>
                            </div>

                            <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-lg-9 d-flex flex-wrap align-items-center pb-3 justify-content-between'>
                                        <h3 className='headline-6 regular my-2 my-lg-0 me-3'>
                                            <span className="text-secondary pe-3">01</span> 
                                            Sign These Documents Digitally
                                        </h3>
                                        {/* <Button variant="outline" className='border my-2 my-lg-0'>Download All Documents</Button> */}
                                    </div>
                                    <div className="col-lg-9 mb-3 mb-lg-0">
                                        <div className='bd_box border contracting_box'>
                                            <p className='label-1 pb-4'>Open each of these documents and sign them digitally</p>
                                            <div className='row'>
                                                {orderInformation?.documents && orderInformation?.documents?.length > 0
                                                    ?
                                                    <>
                                                    {
                                                            orderInformation?.documents
                                                                ?.filter(document => !(document?.document_name ?? '').includes('.docx'))
                                                                ?.map(document => (
                                                            <div key={document?.document_name} className='col-12 mb-3'>
                                                                <div className='c_doc_box border d-flex flex-wrap align-items-center justify-content-between'>
                                                                    <div className='pe-3 d-flex'>
                                                                        <FileCopy className='me-3' />
                                                                        <span className='label-2 bold'>
                                                                            {document?.document_name}
                                                                            <small className='label-2 regular d-block'>4 min read</small>
                                                                        </span>
                                                                    </div>
                                                                    <div className='signature_sec'>
                                                                        <span className='label-2 bold'>
                                                                            <Button variant='light border' className='p-1 px-3 ms-2' onClick={() => handleShow(document)}><Visibility /></Button>
                                                                            {
                                                                                (((document?.document_signed_url ?? '')?.length > 0) || (document?.is_fni_doc && isFniSigned))
                                                                                ? <CheckCircleOutline className='ms-2 text-success' />
                                                                                : null
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </>
                                                    : null}
                                            </div>
                                            <div className='row pt-3 contracting_download'>
                                                <div className='col-12'>
                                                    <div className='row'>
                                                        <div className='col-xl-9 pb-3 pb-lg-0 content-3--regular-grey d-flex align-items-center'>
                                                            <ErrorOutline className='me-2'/>
                                                            <p className='content-3--regular-grey'>
                                                                There are 0 additional documents that will require an in-person signature at the time of delivery
                                                            </p>
                                                        </div>
                                                        {/* <div className='col-xl-3 d-flex justify-content-end contracting_download_btn'>
                                                            <Button variant='secondary' className='px-3'><FileDownload className='me-3'/>Download</Button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {/* <div className='col-12 pt-3'>
                                                    <ul>
                                                        <li className='content-3--regular-grey'>Donec sed odio dui</li>
                                                        <li className='content-3--regular-grey'>Fasce dopibus llus ac cursus commodo</li>
                                                        <li className='content-3--regular-grey'>Vivamus sagittis lacus vel</li>
                                                        <li className='content-3--regular-grey'>Tellus ac cursus commodo</li>
                                                        <li className='content-3--regular-grey'>Duis mollis, est non commodo</li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                   <div className="col-lg-3">
                                        <DocumentView />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="rd_section mb-5">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <p className='content-3--regular-grey'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing eit, sed do eiusmod tempor incididunt ut labore et dolore magna aligua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ulomco laboris nisi ut aliquip ex ea commodo consequat. Dus aut irure dolor in reprehenderit in voluptate velit esse cilum dolore eu fugiat
                                        nulla pariatur. Excepteur sint occoecat cupidatat non proident, sunt in culpa qui officio deserunt mit anim id est laborum Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore mogna aliquo. Ut enim ad minim veniom, quis nostrud exercitaion ulomco laboris nisi ut
                                        aliquip ex ea commodo consequat. Dus aute irure dolor in reprehenderit i voluptate velit esse cilum dolore eu fugiot nulla poriatur. Excepteur sint occoecat
                                        cupidatat non proident, sunt in culpa qui ofica deserunt molit anim ic est laborum.
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <DigitalCheckoutFooter pageUrl={`/home-shoping-hub/${customerRefId}?orderId=${orderID}`} />
                    </div>
                </div>
            </div>
            <Modal show={show} size="xl" onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>{selectedDocument?.document_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DocumentViewModel>
                        <DocumentView document={selectedDocument} setIsScrolledToBottom={setIsScrolledToBottom} />
                    </DocumentViewModel>
                </Modal.Body>
                {(customerSignatures?.signature_image && customerSignatures?.initial_image) ? <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSign(selectedDocument)} disabled={signingDocument || !isScrolledToBottom}>
                        Sign Document
                    </Button>
                </Modal.Footer> : null}
            </Modal>
            
        </>
    );
};

export default ReviewAndSign;