import axios from "axios";

export const LENDER_ID = 3111;
const DEALER_CODE = 'DM001';
const BMW_API_KEY = 'pk_unity_187e1046-7d3c-11ee-838b-72e675e5df01'
const ENVIRONMENT = window.location.hostname.includes('localhost') ? 'demo' : 'demo'

const INTEGRATION_PUBLIC_KEY = 'tk_unity_c16cc18f-77e3-11ed-ae6b-182649783ee3'
const CONFIG_BASE_URL = `https://config-api-${ENVIRONMENT}.netsolapp.io`
const DMS_BASE_URL = `https://dms-api-${ENVIRONMENT}.netsolapp.io`
const UMS_BASE_URL = `https://${ENVIRONMENT}-api.netsolapp.io`
const INTEGRATION_BASE_URL = `https://integration-api-${ENVIRONMENT}.netsolapp.io`

export const get_assets = async () => {
  let URL = `${CONFIG_BASE_URL}/config/inventory/filter?dealer_code=${DEALER_CODE}&page_number=0&page_size=100&vehicle_status=Available&listing_status=listed`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
    },
  });
  return response.data.result;
}

export const get_dealer_fee = async (dealer_code) => {
  let URL = `${CONFIG_BASE_URL}/config/dealer/fee/dealer-code/${dealer_code}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY,
    },
  });
  return response.data;
}

export const get_asset = async (vin) => {
  let URL = `${CONFIG_BASE_URL}/config/vehicle/${vin}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY,
    },
  });
  return response.data;
}

export const get_vehicle_detail = async (vin) => {
  let URL = `${CONFIG_BASE_URL}/config/vehicle-detail/${vin}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY,
    },
  });
  return response.data;
}

export const get_dealer_preference = async (dealer_code) => {
  let URL = `${CONFIG_BASE_URL}/config/dealer/preference-by-dealer-code/${dealer_code}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY
    }
  });
  return response.data;
}

export const get_contract_term = async () => {
  let URL = `${CONFIG_BASE_URL}/config/contract-term`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY
    }
  });
  return response.data;
}

export const get_credit_ratings = async () => {
  let URL = `${CONFIG_BASE_URL}/config/credit-ratings`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY
    }
  });
  return response.data;
}

export const get_contract_allowed_annum_mileage = async () => {
  let URL = `${CONFIG_BASE_URL}/config/contract-allowed-annum-mileage`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY
    }
  });
  return response.data;
}

export const get_assets_by_trim_code = async (trim_code) => {
  let URL = `${DMS_BASE_URL}/dms/configure/assets-by-trim-code/${trim_code}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY
    }
  });
  return response.data;
}

export const get_programs = async (
  asset,
  asset_by_trim_code,
  credit_ratings,
  UPDATED_CREDIT_RATING,
  contract_allowed_annum_mileage,
  dealer_preference,
  UPDATED_ANNUM_MILEAGE,
  contract_term,
  UPDATED_TERMS
) => {
  let URL = `${CONFIG_BASE_URL}/config/programs/all/`
  const response = await axios.post(URL, {
    annual_usage: parseInt(UPDATED_ANNUM_MILEAGE) || contract_allowed_annum_mileage.filter((x) => x.id === dealer_preference.default_mileage_id)[0].value,
    asset_make_id: asset_by_trim_code.asset_make_id,
    asset_model_detail_id: asset_by_trim_code.asset_trim_id,
    asset_model_id: asset_by_trim_code.asset_model_id,
    credit_rating: UPDATED_CREDIT_RATING || credit_ratings.filter((x) => x.is_active === true).sort((a, b) => b.score_to - a.score_to)[0].description,
    dealer_id: asset.dealer_id,
    make_name: asset.make,
    rental_frequency: "Monthly",
    rental_mode: null,
    retrieve_all_programs: false,
    model_name: asset.model,
    terms: parseInt(UPDATED_TERMS) || contract_term.filter((x) => x.id === dealer_preference.default_term_id)[0].term
  }, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY
    }
  });
  return response.data;
}

export const get_quote_calculation = async (payload) => {
  let URL = `${DMS_BASE_URL}/dms/quote/calculation/V2`
  const response = await axios.post(URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY
    }
  });
  return response.data;
}

export const get_option_trim_code = async (DEALER_CODE, trim_desc) => {
  let URL = `${CONFIG_BASE_URL}/config/dealer/option-trim-code?dealer_code=${DEALER_CODE}&trim_desc=${trim_desc}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "Lender_id": LENDER_ID,
      "x-api-key": BMW_API_KEY
    }
  });
  return response.data;
}

export const get_jwt_token = async (payload) => {
  let URL = `${UMS_BASE_URL}/user/register-login`
  const response = await axios.post(URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
    }
  });
  return response.data;
}

export const get_customer_quotations = async (columnFilters) => {
  let URL = `${DMS_BASE_URL}/dms/configure/quotation-by-customers?${columnFilters}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data;
}

export const save_customer_contact = async (body) => {
  let URL = `${DMS_BASE_URL}/dms/customer`
  const { reference_id, ...restPayload } = body
  const response = await axios.patch(
    `${URL}?reference_id=${reference_id}`,
    restPayload, {
    headers: {
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
      "x-api-key": BMW_API_KEY,
    }
  })
  return response.data
}

export const save_finance_details = async (body) => {
  let URL = `${DMS_BASE_URL}/dms/customer/personal-finance`
  const { ...restPayload } = body
  const response = await axios.post(
    `${URL}`,
    restPayload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data
}

export const update_finance_details = async (body) => {
  let URL = `${DMS_BASE_URL}/dms/customer/personal-finance?reference_id=${body.reference_id}`
  const { ...restPayload } = body
  const response = await axios.patch(
    `${URL}`,
    restPayload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data
}

export const validate_license_data = async (body) => {
  let URL = `${DMS_BASE_URL}/dms/customer/license-verification`
  const response = await axios.post(
    `${URL}`,
    body, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data
}

export const save_employment_details = async (body) => {
  let URL = `${DMS_BASE_URL}/dms/customer/employment-info`
  const { ...restPayload } = body
  const response = await axios.post(
    `${URL}`,
    restPayload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data
}

export const update_employment_details = async (body) => {
  let URL = `${DMS_BASE_URL}/dms/customer/employment-info?reference_id=${body?.reference_id}`
  const { ...restPayload } = body
  const response = await axios.patch(
    `${URL}`,
    restPayload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data
}

export const get_employment_details = async (reference_id) => {
  let URL = `${DMS_BASE_URL}/dms/customer/employment-info?reference_id=${reference_id}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data;
}

export const getLicenseInfo = async (reference_id) => {
  let URL = `${DMS_BASE_URL}/dms/customer/license-info?reference_id=${reference_id}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data;
}

export const get_personal_finance = async (reference_id) => {
  let URL = `${DMS_BASE_URL}/dms/customer/personal-finance?reference_id=${reference_id}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data;
}

export const check_customer_by_email = async (payload) => {
  let URL = `${DMS_BASE_URL}/dms/check-customer-by-email`
  const response = await axios.patch(URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
    }
  });
  return response.data;
}

export const get_vehicle_details = async (vin) => {
  let URL = `${CONFIG_BASE_URL}/config/vehicle-detail/${vin}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem('token')}`,
    }
  })
  return response.data;
}

export const save_quotation = async (payload) => {
  let URL = `${DMS_BASE_URL}/dms/configure/save-quotation`
  const response = await axios.post(URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
    }
  });
  return response.data;
}

export const save_license_details = async (payload) => {
  let URL = `${DMS_BASE_URL}/dms/customer/license-info`
  const response = await axios.post(URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem('token')}`,
    }
  });
  return response.data;
}

export const get_contact_details = async (reference_id) => {
  let URL = `${DMS_BASE_URL}/dms/customer?reference_id=${reference_id}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`,
    }
  })
  return response.data;
}

export const useCreditResponse = async (payload) => {
  let URL = `${DMS_BASE_URL}/dms/credit-request`
  const response = await axios.post(URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem('token')}`,
    }
  });
  return response.data;
}

export const get_order_details = async (reference_id) => {
  let URL = `${DMS_BASE_URL}/dms/order/order_information_for_submitted_order?page_number=${0}&page_size=${10}&reference_number=${reference_id}&order_status=${"Draft"}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`,
    }
  })
  return response.data;
}

export const get_fni_products = async (asset) => {
  let URL = `${CONFIG_BASE_URL}/config/financial-insurances/filter?dealer_code=${asset.dealer_code}&page_number=0&page_size=1000`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY
    }
  })
  return response.data.result;
}

// /config/integration/mitek-integration/{provider_name}
export const get_integration = async (provider_name) => {
  let URL = `${CONFIG_BASE_URL}/config/integration/mitek-integration/${provider_name}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY
    }
  })
  return response.data;
}

export const convert_quotation_to_application = async (body) => {
  let URL = `${DMS_BASE_URL}/dms/configure/quotation-to-order/${body?.identifier}`
  // const { ...restPayload } = body
  const response = await axios.patch(
    `${URL}`,
    body, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  }
  );
  return response.data
}

export const config_quotation = async (body) => {
  let URL = `${DMS_BASE_URL}/dms/configure/quotation/${body.identifier}`
  const response = await axios.patch(
    `${URL}`,
    body, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`
    }
  })
  return response.data.result;
}

export const get_dealer_profile = async (dealer_code) => {
  let URL = `${CONFIG_BASE_URL}/config/dealer/profile/${dealer_code}`
  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY
    }
  })
  return response.data;
}

export const get_rates = async (payload) => {
  let URL = `${INTEGRATION_BASE_URL}/get-rates`
  const response = await axios.post(URL, payload.data, {
    headers: {
      "Content-Type": "application/json",
      "X-Api-key": INTEGRATION_PUBLIC_KEY,
      ...payload.headers
    }
  })
  return response.data.rates.rate;
}

export const get_customer_signatures = async (reference_id) => {
  let URL = `${DMS_BASE_URL}/dms/customer/signature?reference_id=${reference_id}`

  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`,
    }
  })
  return response.data;
  }

export const save_customer_signature = async (payload) => {
    let URL = `${DMS_BASE_URL}/dms/customer/signature`

    const response = await axios.post(URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-Api-key": BMW_API_KEY,
      ...payload.headers
    }
  })
  return response.data
  }

export const sign_customer_document = async (payload) => {
  let URL = `${DMS_BASE_URL}/dms/customer/document/signature-document`
  const response = await axios.post(URL, payload, {
    headers: {
      "Content-Type": "application/json",
      "X-Api-key": BMW_API_KEY,
      ...payload.headers
    }
  })
  return response.data
}

export const get_order_information = async (reference_id) => {
  let URL = `${DMS_BASE_URL}/dms/order/order_information/${reference_id}`

  const response = await axios.get(URL, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": BMW_API_KEY,
      // "Authorization": `Bearer ${localStorage.getItem("token")}`,
    }
  })
  return response.data;
  }