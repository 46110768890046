import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Bookmark, BookmarkBorder } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { Button, OverlayTrigger } from 'react-bootstrap';
import styles from './GridItemCard.module.css';

import VehicleDetailsModal from './Modals/VehicleDetailsModal';
import { Price, NAVIGATION } from '../../utilities';

import imgInner from '../../assets/images/img_inner.png';

function GridItemCard({ item }) {
  // convert the following three lines into useReducer
  const [isfvrt, setfvrt] = useState(false);
  const [isSwitch, setisSwitch] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fvrtToggle = () => setfvrt(!isfvrt);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div className={`${styles?.product_item_box}`}>
      <div className={`${styles?.product_item_inner}`}>
        <div className={` ${styles?.gridItem_imgBox}`}>
          {(styles) ? <div className={` ${styles.gridItem_img}`}>
            {
              isSwitch ? <img className={styles?.gridItem_imgInner} src={imgInner} alt='#'></img> :  item.vehicle?.photo_urls ?  <img className={styles?.gridItem_imgOuter} src={item?.vehicle?.photo_urls[0]?.location} alt='#'></img> : null
            }
          </div> : null}
        </div>

        <div className='gridItem_name_row d-flex justify-content-between align-items-start pb-3'>
          <div className='gridItem_name'>
            <h2 className="content-2--bold text_elipsis">{item.vehicle.year} {item.vehicle.make} {item.vehicle.model}</h2>
            <p className="content-3--regular-grey text_elipsis">{item.vehicle.trim_description} {item.vehicle.body_type}</p>
          </div>
          <div className='gridItem_fvrt ps-2'>
            <i className={`${styles.fvrt_btn}  ${isfvrt ? styles.active : ''}`} onClick={fvrtToggle}>
              {
                isfvrt ? <Bookmark /> : <BookmarkBorder />
              }
            </i>
          </div>
        </div>
        <div className='gridItem_price_row d-flex justify-content-between align-items-start pb-3'>
          <div className={styles.msrp}>
            <small>
              MSRP
              <span className='msrp_tip'>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className='tooltip_box tooltip_box_top'>
                      <>Manufacturer's Suggested Retail Price (MSRP) for vehicle as shown does not necessarily represent the dealer's actual sale price and does not include tax, title, license, registration and adjusted market value. Dealer sets actual price. Please consult your selected dealer.</>
                    </Tooltip>
                  }>
                  <i className='help_icon'></i>
                </OverlayTrigger>
              </span>
            </small>
            <b><Price value={item.vehicle.internet_price} /></b>
          </div>
          <div className={styles.dealership}>
            <i className='status_dot status_green'></i>
            At Dealership
            <span className='msrp_tip ms-1'>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip className='tooltip_box tooltip_box_top'>
                    <>Vehicle will be arriving soon to this BMW Center. Please contact this BMW Center for additional details.</>
                  </Tooltip>
                }>
                <i className='help_icon'></i>
              </OverlayTrigger>
            </span>
          </div>
        </div>
        <div className={styles.area_loc}>
          <h3> {item.vehicle.doing_business_as}</h3>
        </div>
        <div className={`d-flex ${styles.btns_row}`}>
          <div className='col pe-1'>
            <Link>
              <Button className='product_list_btn ps-2 pe-2 w-100' variant="primary" onClick={handleShow}>Vehicle Details</Button>
            </Link>
          </div>
          <div className='col ps-1'>
            <Link to={`${NAVIGATION.ASSTE_DETAILS}/${item.vin}`}>
              <Button className='product_list_btn ps-2 pe-2 w-100' variant="secondary">Build My Deal</Button>
            </Link>
          </div>
        </div>
      </div>
      <VehicleDetailsModal show={showModal} handleClose={handleClose} item={item} />
    </div>

  )
}

export default GridItemCard;