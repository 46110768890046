import React, { useState } from 'react';
import styles from './FiltersSwitch.module.css';

function FiltersSwitch() {
  const [isDealershipCheck, setisDealershipCheck] = useState(false);
  const toggleVisibility = () => {
    setisDealershipCheck(!isDealershipCheck);
  };
  return (
    <div className="dealerShip_head">
      <div className={`${styles.dealerShip_switch} ${isDealershipCheck ? styles.active : ''}`} onClick={toggleVisibility}>
        <i className={styles.dealerShip_switch_shap}></i>
        <span className={styles.dealerShip_switch_label}>Only Show Vehicles At Dealership</span>
      </div>
    </div>
  )
}

export default FiltersSwitch;