import React from "react";
import Logo from "./Logo";
import HeaderInfo from "./HeaderInfo";
import BackToResut from "./BackToResut/BackToResut";
import 'bootstrap/dist/js/bootstrap.bundle';

function ProductDetailsHeader() {
    return (
        <header className="header product_details_header">
            <div className="header_inner navbar navbar-expand-md d-flex justify-content-between align-items-center">
                <Logo />
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="HeaderRight collapse navbar-collapse" id="navbarNav">
                    <div className="HeaderRightInner d-flex justify-content-between align-items-center w-100">
                      <BackToResut/>
                      <HeaderInfo onlineSupport='true'/>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default ProductDetailsHeader;